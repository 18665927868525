import React from 'react'
import { Button, Tooltip } from 'antd'
import { Div, SmallText } from 'konsys-ui'
import { LogoutOutlined } from '@ant-design/icons'
import {
  removeAccessToken, removeRefreshToken, getLocalStorage,
} from '../../utils/local-data-service'
import URL from '../../constants/environment'

export default () => {
  const logout = () => {
    removeAccessToken()
    removeRefreshToken()
    window.location = `${URL.WEB_SERVER}/login`
  }
  return (
    <Div style={{ float: 'right' }} margin='0 24px 0 0'>
      <Div display='flex' flexDirection='row' alignItems='center'>
        <SmallText bold style={{
          width: '38vw', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'right', color: '#FFF',
        }}>{getLocalStorage('fullname')}</SmallText>
        <Tooltip placement="bottomRight" title='ออกจากระบบ'>
          <Button icon={<LogoutOutlined />} shape="circle" style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 0 0 16px', paddingTop: 1,
          }} onClick={() => logout()} />
        </Tooltip>
      </Div>
    </Div>
  )
}
