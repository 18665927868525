import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import {
  Div, Button, Icon, Input, SmallText,
} from '@konsys-ui-custom'
import { Helmet } from 'react-helmet'
import { CUSTOMERS, PRICING_TYPES_LISTS } from '../../api/query'
import { ListContainer, Select } from '../../components'
import QuickView from './quick-view'
import { DeliveryDetail, TaxDetail } from '../suppliers/quick-view'
import { PAYMENT_TYPE } from '../../constants/enum'
import History from '../../components/common/history'
import { permissionRedirect } from '../../utils/util-services'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput } from '../../utils'

const KEY = 'customerList'

export default (props) => {
  const [searchText, setSearchText] = useState('')
  const [filterLoading, setFilterLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [selectedPricingType, setSelectedPricingType] = useState()
  const [selectedPaymentType] = useState()
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const isInitialMount = useRef(true)
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(CUSTOMERS, { variables: { limit: 10, offset: 0 } })
  const { data: pricingTypeData } = useQuery(PRICING_TYPES_LISTS)
  const authorization = useContext(AuthorizationContext)
  const clientAutho = authorization.authorization?.client || {}

  const option = pricingTypeData?.pricingTypeList?.data?.list?.map((v) => (
    { text: v.name, value: v.id }
  )) || []

  const mapped = (!loading || !_.isEmpty(data)) && data[KEY].data.list.map((v) => {
    const primaryContactAddress = _.find(v.contactAddress, (o) => o.isPrimary)
    return (
      {
        id: v.id,
        title: v.name,
        status: {
          text: v.number,
        },
        content: {
          one: {
            title: {
              text: <Div display='flex' justifyContent='space-between'>
                {
                  !_.isEmpty(primaryContactAddress?.contactLineId) && !_.isEmpty(primaryContactAddress?.contactFacebook)
                    ? <Div>
                      <SmallText bold color='#00B900'>{primaryContactAddress?.contactLineId}</SmallText>
                      {
                        !_.isEmpty(primaryContactAddress?.contactLineId) && !_.isEmpty(primaryContactAddress?.contactFacebook)
                        && <SmallText margin='0 4px'>/</SmallText>
                      }
                      <SmallText bold color='#4267B2'>{primaryContactAddress?.contactFacebook}</SmallText>
                    </Div>
                    : <Div>
                      <SmallText>-</SmallText>
                    </Div>
                }
                <SmallText textAlign='right'>{_.find(PAYMENT_TYPE, (o) => o.value === v.paymentType)?.text}</SmallText>
              </Div>,
            },
            content: {
              text: primaryContactAddress && <Div>
                <SmallText display='block'>{primaryContactAddress?.contactOfficeTel}</SmallText>
              </Div>,
            },
            margin: '0',
            style: { width: '100%' },
          },
        },
        detail: {
          title: [
            {
              text: 'รายละเอียด',
              icon: 'fal fa-info-square',
            },
            {
              text: 'ข้อมูลการจัดส่งสินค้า',
              icon: 'fal fa-truck',
            },
            {
              text: 'ข้อมูลใบกำกับภาษี',
              icon: 'fal fa-file-alt',
            },
            clientAutho.viewHistoryLog && {
              text: 'ประวัติการใช้งาน',
              icon: 'fal fa-history',
            },
          ],
          buttonList: [
            clientAutho.editBasic && <Link key='create' to={`${props.match.url}/edit/${v.id}`}>
              <Button small ghost margin='4px 8px 4px 0' text='แก้ไข' icon='far fa-edit' />
            </Link>,
          ],
          content: [
            <QuickView key={v.id} data={v}/>,
            <DeliveryDetail key={0} data={v.shippingAddress} />,
            <TaxDetail key={1} data={v.billingAddress} />,
            <History
              key={2}
              data={v}
              moduleName='CUSTOMER'
            />,
          ],
        },
      }
    )
  })

  useEffect(() => {
    if (!clientAutho.view) {
      permissionRedirect()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      refetch({
        name: searchText,
        pricingTypeId: selectedPricingType,
        paymentType: selectedPaymentType,
        orderBy,
        orderField,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, selectedPricingType, selectedPaymentType, orderField, orderBy])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [...prev[KEY].data.list, ...fetchMoreResult[KEY].data.list],
                  },
                } }
            },
          })
        }}
        title={[
          {
            text: 'รายชื่อลูกค้า',
            icon: 'fad fa-user-tie',
          },
        ]}
        filterHeight={100}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                width='66%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='ค้นหา'
                // value={searchText}
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
                margin='0 16px 0 0'
                suffix={(!loading && !_.isEmpty(data?.customerList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.customerList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
              <Select
                style={{ width: '34%', margin: '0 16px 0 0' }}
                placeholder='เลือกตัวกรองระดับลูกค้า'
                options={option}
                onChange={(value) => setSelectedPricingType(value)}
                value={selectedPricingType}
              />
            </Div>
            <Div display='flex' margin='8px 0 0'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={(value) => setOrderField(value)}
                  value={orderField}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  value={orderBy}
                  onChange={(value) => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                  setSearchText('')
                  setSelectedPricingType()
                  setFilterLoading(true)
                  setOrderField('createdAt')
                  setOrderby('DESC')
                  refetch({
                    search: '',
                    pricingTypeId: undefined,
                    orderBy: 'DESC',
                    orderField: 'createdAt',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
          </Div>,
        ]}
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        list={mapped}
        buttonList={[
          clientAutho.create && <Link key='create' to={`${props.match.url}/create`}>
            <Button small ghost inverse color={theme.color.success} margin='4px 8px 4px 0' text='สร้างลูกค้าใหม่' icon='far fa-plus' />
          </Link>,
        ]}
      >
      </ListContainer>
    </Div>
  )
}
