import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Button,
  Div,
  Icon,
  Input,
  Label,
  P,
  SmallText,
} from '@konsys-ui-custom'
import { Checkbox, Col, InputNumber, Popover, Row, message, notification } from 'antd'
import _, { isNil, random, toNumber } from 'lodash'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { GET_ITEMS, VARIANT_INFO } from '../../api/query'
import { InputWithLabel, Select } from '../../components'
import { theme } from '../../styles/_variables'
import { delayInput, roundNumber } from '../../utils'
import { getLocalStorage } from '../../utils/local-data-service'
import { ORDER_TYPE } from './constants'

const ItemRenderer = ({
  updateItems,
  isCreateMode,
  item,
  items,
  authorization,
  mutationVariables,
  isUpsellMode,
  soStatus,
}) => {
  const [search, setSearch] = useState('')
  const {
    loading: productLoading,
    data: productData,
  } = useQuery(GET_ITEMS, { variables: { search, limit: 10 }, skip: !search })
  const [getItemInfo, { loading: itemInfoLoading, data: itemInfoData }] = useLazyQuery(VARIANT_INFO)

  useEffect(() => {
    if (!isNil(item.productObject)) {
      getItemInfo({
        variables: {
          id: item?.productObject?.id,
          sellerId: mutationVariables?.seller?.id,
        },
      })
    }
  }, [mutationVariables?.seller?.id])

  useEffect(() => {
    if (!itemInfoData?.variantInfo?.success) {
      if (!_.isEmpty(itemInfoData?.variantInfo?.message)) { message.error(itemInfoData?.variantInfo?.message) }
    } else if (
      !itemInfoLoading
      && !_.isEmpty(itemInfoData?.variantInfo?.data)
    ) {
      const product = itemInfoData?.variantInfo?.data
      const selectedSupplier = product.type === ORDER_TYPE.SET
        ? product.suppliers
        : product.inventoryOption.inventory.supplier
      const findSup = product.type === ORDER_TYPE.SET
        ? selectedSupplier[0]
        : _.isArray(selectedSupplier)
          ? selectedSupplier[0]
          : selectedSupplier
      updateItems(
        items.map((val) => {
          if (val.id === item.id) {
            return {
              ...val,
              quantity: item.quantity || 1,
              noStock: itemInfoData?.variantInfo?.data.inventoryOption.totalStock < 1,
              packId: product.id,
              supplierId: findSup?.id,
              avgCost: product?.avgCost,
              commission: product?.commission,
              salesPrice: item.isFree ? 0 : product?.salesPrice || item.salesPrice,
              productObject: product,
              itemSource: 'STOCK',
              items: product.productSetItems || [],
              discount: item.discount || 0,
              isFree: item.isFree || false,
            }
          }
          return val
        }),
      )
    }
  }, [itemInfoData?.variantInfo])

  return (
    <Div>
      <Row
        type="flex"
        align="middle"
        gutter={16}
        style={{
          marginTop: 16,
          background: item.isUpsellItem ? '#e8fff1' : 'none',
          borderRadius: 6,
          padding: 4,
        }}
      >
        <Col span={6}>
          <Select
            tooltip
            width="100%"
            margin="0 16px 0 0"
            showSearch
            loading={productLoading || itemInfoLoading}
            disabled={
              (
                !isCreateMode
                || !isUpsellMode
                || itemInfoLoading
                || !_.isUndefined(item.quotationPrice)
              ) && toNumber(item.id)
            }
            style={{ width: '100%' }}
            placeholder="เลือกสินค้า"
            notFoundContent={null}
            filterOption={false}
            value={item?.productObject?.variantName || item.name}
            onSelect={(e) => {
              getItemInfo({
                variables: {
                  id: e,
                  sellerId: mutationVariables?.seller?.id,
                },
              })
            }}
            onSearch={(value) => {
              const searchValue = value
              delayInput(() => {
                setSearch(searchValue)
              })
            }}
            options={
              productData && productData.variantList
                ? _.concat(
                  productData?.variantList?.data?.map((val) => ({
                    value: val.id,
                    text: val.variantName,
                  })),
                )
                : []
            }
            className={
              ((_.isEmpty(item.packId) && !_.isNumber(item.packId))
                || _.isNaN(item.packId))
              && 'state-error'
            }
          />
        </Col>
        <Col span={2}>
          <InputNumber
            controls={false}
            style={{
              border: parseInt(item.quantity) > itemInfoData?.variantInfo?.data.inventoryOption.totalStock && `1px solid ${theme.color.error}`,
              width: '100%',
            }}
            value={item.quantity}
            defaultValue={item.quantity}
            disabled={(isUpsellMode && !item.isUpsellItem) || (!isUpsellMode && item.isUpsellItem) || soStatus === 'COMPLETED' || item.isFree}
            onChange={(e) => {
              updateItems(
                items.map((val) => {
                  if (val.id === item.id) {
                    return {
                      ...val,
                      quantity: e,
                      itemSource: 'STOCK',
                      noStock: parseInt(e) > itemInfoData?.variantInfo?.data.inventoryOption.totalStock,
                    }
                  }
                  return val
                }),
              )
            }}
          />
        </Col>
        <Col span={2}>
          <InputNumber
            controls={false}
            style={{
              border: parseInt(item.quantity) > itemInfoData?.variantInfo?.data.inventoryOption.totalStock && `1px solid ${theme.color.error}`,
              width: '100%',
            }}
            value={item.salesPrice}
            disabled={(isUpsellMode && !item.isUpsellItem) || (!isUpsellMode && item.isUpsellItem) || soStatus === 'COMPLETED' || item.isFree}
            onChange={(e) => {
              updateItems(
                items.map((val) => {
                  if (val.id === item.id) {
                    return { ...val, salesPrice: e }
                  }
                  return val
                }),
              )
            }}
          />
        </Col>
        {
          authorization.salesOrder.showCost && <Col span={2}>
            <P textAlign="center" display="block">
              {numeral(
                (item?.avgCost) * (parseInt(item.quantity) || 0),
              ).format('0,0.00')}
            </P>
          </Col>
        }
        {
          authorization.salesOrder.showCost && <Col span={2}>
            <P textAlign="center" display="block">
              {numeral(
                item.isFree
                  ? (item?.avgCost * parseInt(item.quantity) || 0) * -1
                  : roundNumber(
                    ((parseFloat(item.salesPrice) - (item?.avgCost)) * (parseInt(item.quantity) || 0))
                    - (((item.salesPrice - (item?.discount || 0)) * item.commission * 0.01) * item.quantity)
                    - (parseFloat(item.discount) * item.quantity),
                  ),
              ).format('0,0.00')}
            </P>
          </Col>
        }
        {
          authorization.salesOrder.showCost && <Col span={2}>
            <P textAlign="center" display="block">
              {numeral((item.isFree ? 0 : ((item.salesPrice - (item?.discount || 0)) * item.commission * 0.01)) * item.quantity).format('0,0.00')}
            </P>
          </Col>
        }
        <Col span={2}>
          <P textAlign="center" display="block">
            {numeral((item.salesPrice - (item.discount || 0)) * (parseInt(item.quantity) || 0)).format(
              '0,0.00',
            )}
          </P>
        </Col>
        <Col span={2}>
          <P textAlign="center" display="block">
            {
              item.seller
                ? `${item?.seller?.firstName} ${item?.seller?.lastName}`
                : mutationVariables?.seller && !item.isUpsellItem
                  ? `${mutationVariables?.seller?.firstName} ${mutationVariables?.seller?.lastName}`
                  : getLocalStorage('fullname') || '-'
            }
          </P>
        </Col>
        <Col span={2}>
          <InputNumber
            controls={false}
            style={{
              border: parseInt(item.quantity) > itemInfoData?.variantInfo?.data.inventoryOption.totalStock && `1px solid ${theme.color.error}`,
              width: '100%',
              fontSize: theme.fonts.size.normal,
            }}
            disabled={
              (isUpsellMode && !item.isUpsellItem) || (!isUpsellMode && item.isUpsellItem) || item.isFree || soStatus === 'COMPLETED'
            }
            value={item.discount}
            onChange={(e) => updateItems(
              items.map((val) => {
                if (val.id === item.id) {
                  return { ...val, discount: e }
                }
                return val
              }),
            )}
          />
        </Col>
        <Col span={1}>
          <Checkbox
            disabled={
              (isUpsellMode && !item.isUpsellItem) || (!isUpsellMode && item.isUpsellItem) || soStatus === 'COMPLETED'
            }
            checked={item.isFree}
            onChange={(e) => updateItems(
              items.map((val) => {
                if (val.id === item.id) {
                  return { ...val, isFree: e.target.checked, salesPrice: 0, discount: 0 }
                }
                return val
              }),
            )
            }
          />
        </Col>
        <Col span={1}>
          {((isUpsellMode && item.isUpsellItem) || (!isUpsellMode && !item.isUpsellItem)) && soStatus !== 'COMPLETED' && (
            <Icon
              icon="fal fa-trash-alt"
              style={{ cursor: 'pointer' }}
              onClick={() => updateItems(
                _.remove(_.cloneDeep(items), (o) => o.id !== item.id),
              )
              }
            />
          )}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={16}>
        <Col span={6}>
          {item.items
            && item.items.map((itemInSet) => (
              <Div
                key={itemInSet.id}
                display="flex"
                width="100%"
                justifyContent="space-between"
              >
                <SmallText color={theme.color.gray50}>
                  {itemInSet.Pack.variantName}
                </SmallText>
                <SmallText color={theme.color.gray50}>
                  {itemInSet.quantity} ชิ้น
                </SmallText>
              </Div>
            ))}
        </Col>
      </Row>
    </Div>
  )
}

const QuantityInput = ({ v, setItems, items }) => (
  <Input
    value={v?.quantity}
    width="100%"
    onChange={(e) => {
      setItems(
        items?.map((o) => {
          if (o?.id === v?.id) {
            return { ...o, quantity: e.target.value }
          }
          if (o?.itemId === v?.id && !_.isEmpty(e.target.value)) {
            return { ...o, quantity: v?.allItem - parseInt(e.target.value) }
          }
          return o
        }),
      )
    }}
  />
)

export const ItemsInputRenderer = ({
  items,
  updateItems,
  isCreateMode,
  lotId,
  customer,
  authorization,
  lots,
  mutationVariables,
  lot,
  isUpsellMode,
  soStatus,
}) => items.map((v) => (
  <ItemRenderer
    authorization={authorization}
    supplierId={v?.supplierId}
    pricingTypeId={customer?.pricingType?.id}
    customer={customer}
    key={v.id}
    item={v}
    soStatus={soStatus}
    updateItems={updateItems}
    isCreateMode={isCreateMode}
    isUpsellMode={isUpsellMode}
    items={items}
    lotId={lotId}
    lots={lots}
    mutationVariables={mutationVariables}
    lot={lot}
  />
))

export const ItemsLabelRenderer = (props) => {
  const [items, setItems] = useState(props.items || [])
  const [quantity, setQuantity] = useState(
    props.items.map((v) => ({ id: v.id, quantity: v.quantity })),
  )
  const [visiblesMove, setVisiblesMove] = useState(
    props.items.map((v, index) => ({ id: v.id, index, isVisibleMove: false })),
  )

  useEffect(() => {
    setVisiblesMove(
      props.items.map((v, index) => ({ id: v.id, index, isVisibleMove: false })),
    )
    setQuantity(props.items.map((v) => ({ id: v.id, quantity: v.quantity })))
  }, [props])

  const splitItem = (splitItemId) => {
    const findSelectedItem = _.find(items, (o) => o.id === splitItemId)
    const newList = _.flatten(
      items?.map((o) => {
        if (o?.id === splitItemId) {
          const itemQuantity = o?.quantity
          const checkIsNewSplitItem = o?.isDeleteable
          const splitQuantity = checkIsNewSplitItem || o?.receivedQuantity === 0
            ? itemQuantity / 2
            : o?.receivedQuantity
          const quantityFixDecimal = splitQuantity % 1 === 0 ? splitQuantity : splitQuantity.toFixed(3)
          const newQuantity = itemQuantity - quantityFixDecimal
          return [
            {
              ...o,
              quantity: quantityFixDecimal,
              allOrderItem: o?.allOrderItem || itemQuantity,
              isSplitable: true,
              isDisabledChangeSupplier:
                !checkIsNewSplitItem && o?.receivedQuantity > 0,
              minQuantity: !checkIsNewSplitItem ? o?.receivedQuantity : 0,
            },
            {
              ...findSelectedItem,
              id: Math.floor(1000 + Math.random() * 9000),
              itemId: o?.itemId || o?.id,
              allOrderItem: o?.allOrderItem || itemQuantity,
              quantity:
                newQuantity % 1 === 0 ? newQuantity : newQuantity?.toFixed(3),
              supplierName: '',
              isSplitable: true,
              isDeleteable: true,
              isDisabledChangeSupplier: false,
            },
          ]
        }
        return o
      }),
    )
    setItems(newList)
  }

  const deleteSplieItem = (splitItemId) => {
    const findSplitItemObj = _.find(items, (v) => v?.id === splitItemId)
    const findLastSplitItem = _.find(
      _.reverse([...items]),
      (v) => v?.itemId === findSplitItemObj?.itemId && v?.id !== splitItemId,
    )
    setItems(
      _.compact(
        items?.map((o) => {
          if (o?.id === splitItemId) return null
          if (
            o?.id === findLastSplitItem?.id
            || (_.isEmpty(findLastSplitItem) && o?.id === findSplitItemObj?.itemId)
          ) {
            return {
              ...o,
              quantity:
                parseFloat(findSplitItemObj?.quantity)
                + parseFloat(o?.quantity),
            }
          }
          return o
        }),
      ),
    )
  }

  useEffect(() => {
    const rawItemList = _.filter(items, (obj) => _.isUndefined(obj?.itemId))
    const messageList = rawItemList?.map((obj) => {
      const filterItemIncludeSplit = _.filter(
        items,
        (o) => o?.itemId === obj?.id || o?.id === obj?.id,
      )
      const sumAllItem = _.sum(
        filterItemIncludeSplit?.map((o) => parseFloat(o?.quantity || 0)),
      )
      if (obj?.allOrderItem && sumAllItem !== obj?.allOrderItem) {
        return `${obj?.name} จำนวนรวมกันต้องเท่ากับ ${obj?.allOrderItem}`
      }
      return ''
    })
    if (!_.isEmpty(messageList)) {
      props.showRemark(
        _.reduce(
          messageList,
          (text, n) => {
            if (_.isEmpty(text)) return n
            return `${text}\n${n}`
          },
          '',
        ),
      )
    }
  }, [items])

  const checkIsDisabledSplit = (itemData) => !_.isEmpty(_.filter(items, (o) => o?.itemId === itemData?.id))
    && !itemData?.isDeleteable
    && itemData?.receivedQuantity > 0

  return items.map((v, index) => (
    <Div key={v.id}>
      <Row type="flex" align="middle" gutter={16} style={{ marginTop: 16 }}>
        <Col span={6}>
          <SmallText bold>{v.name}</SmallText>
        </Col>
        <Col span={2}>
          {v.isSplitable ? (
            v?.isDeleteable ? (
              <QuantityInput
                v={v}
                items={items}
                setItems={(value) => setItems(value)}
                showRemark={(value) => props.showRemark(value)}
              />
            ) : (
              <Popover
                content={
                  <P>{`เนื่องจากรับสินค้าไปแล้ว ${numeral(
                    v?.minQuantity,
                  ).format('0,0')} ชิ้น`}</P>
                }
                title={`จำนวนขั้นต่ำ ${numeral(v?.minQuantity).format(
                  '0,0',
                )} ชิ้น`}
              >
                <Input
                  value={v?.quantity}
                  rule={{ type: 'float' }}
                  width="100%"
                  state={
                    _.isNil(v?.quantity)
                    || parseFloat(v?.quantity) < v?.receivedQuantity
                      ? 'error'
                      : 'default'
                  }
                  onChange={(e) => {
                    setItems(
                      items?.map((o) => {
                        if (o?.id === v?.id) {
                          return { ...o, quantity: e.target.value }
                        }
                        if (o?.itemId === v?.id && !_.isEmpty(e.target.value)) {
                          const newQuantity = (v?.allOrderItem - parseFloat(e.target.value))
                            / _.filter(items, (obj) => obj?.itemId === v?.id)
                              ?.length
                          return {
                            ...o,
                            quantity:
                              newQuantity % 1 === 0
                                ? newQuantity
                                : newQuantity?.toFixed(2),
                          }
                        }
                        return o
                      }),
                    )
                  }}
                />
              </Popover>
            )
          ) : (
            <SmallText bold>{v.quantity}</SmallText>
          )}
        </Col>
        <Col span={2}>
          <SmallText bold>{numeral(v.salesPrice).format('0,0.00')}</SmallText>
        </Col>
        <Col span={2}>
          <SmallText textAlign="center" bold display="block">
            {numeral(v.buyPrice * v.quantity).format('0,0.00')}
          </SmallText>
        </Col>
        <Col span={2}>
          <SmallText textAlign="center" bold display="block">
            {numeral((v.salesPrice - v.buyPrice) * v.quantity).format('0,0.00')}
          </SmallText>
        </Col>
        <Col span={2}>
          <SmallText textAlign="center" bold display="block">
            {numeral(random(1, 100)).format('0,0.00')}
          </SmallText>
        </Col>
        <Col span={2}>
          <SmallText textAlign="center" bold display="block">
            {numeral(v.salesPrice * v.quantity).format('0,0.00')}
          </SmallText>
        </Col>
        <Col span={2}>
          <SmallText
            textAlign="center"
            bold
            display="block"
          >{`${v.seller?.firstName} ${v.seller?.lastName}`}</SmallText>
        </Col>
        <Col span={2}>
          <SmallText bold>
            {v.discount ? numeral(v.discount).format('0,0.00') : '-'}
          </SmallText>
        </Col>
        <Col span={1}>
          <Checkbox data={{ value: 'FREE' }} checked={v.isFree} />
        </Col>
        <Col span={1}>
          {v?.itemSource === 'PREORDER' && v?.isDeleteable && (
            <Icon
              icon="fas fa-trash-alt"
              style={{ cursor: 'pointer' }}
              margin="0 0 0 8px"
              fontSize="14px"
              onClick={() => deleteSplieItem(v?.id)}
            />
          )}
        </Col>
        <Col span={1}>
          {v?.itemSource === 'PREORDER'
            && (v?.isSplitable || v?.receivedQuantity < v?.quantity) && (
            <Icon
              icon="fad fa-exchange"
              color={
                (v?.quantity <= 1 || checkIsDisabledSplit(v))
                  && theme.color.gray10
              }
              style={{
                cursor:
                    v?.quantity <= 1 || checkIsDisabledSplit(v)
                      ? 'not-allowed'
                      : 'pointer',
              }}
              fontSize="14px"
              onClick={() => v?.quantity > 1
                  && !checkIsDisabledSplit(v)
                  && splitItem(v?.id)
              }
            />
          )}
        </Col>
        {_.isNil(props.thisLot.pickingTicket) && (
          <Col span={1}>
            <Popover
              key={`move_${v.id}`}
              trigger="click"
              content={
                <Div display="flex" flexDirection="column">
                  <InputWithLabel
                    width="100%"
                    input={{
                      width: '100%',
                      rule: { type: 'float' },
                      state: 'default',
                      defaultValue: v.quantity,
                      value:
                        _.find(quantity, (o) => o.id === v.id)?.quantity || 0,
                    }}
                    onChange={(e) => setQuantity(
                      quantity.map((q) => {
                        if (q.id === v.id) {
                          return {
                            ...q,
                            quantity: parseFloat(e.target.value),
                          }
                        }
                        return q
                      }),
                    )
                    }
                    title={{
                      text: (
                        <SmallText bold>
                          จำนวนที่ต้องการย้าย{' '}
                          <Label color={theme.color.error}>*</Label>
                        </SmallText>
                      ),
                    }}
                  />
                  {_.filter(
                    props.lots,
                    (obj) => obj.id !== props.id && _.isNil(obj.pickingTicket),
                  ).map((l) => (
                    <Button
                      key={l.id}
                      margin="0 0 16px 0"
                      small={true}
                      text={`ล๊อต ${
                        _.findIndex(props.lots, (o) => o.id === l.id) + 1
                      }`}
                      onClick={() => {
                        if (props.thisLot.warehouseId !== l.warehouseId) {
                          notification.error({
                            message: 'ไม่สามารถย้ายล๊อตได้',
                            description: 'ต้นทาง/ปลายทางไม่ใช่คลังเดียวกัน',
                          })
                        } else {
                          props.changeLot(
                            props.id,
                            l.id,
                            v,
                            _.size(props.thisLot.items) === 1
                              ? props.thisLot.deliveryCost
                              : 0,
                            {
                              new: _.find(quantity, (o) => o.id === v.id)
                                .quantity,
                              old:
                                v.quantity
                                - _.find(quantity, (o) => o.id === v.id).quantity,
                            },
                          )
                        }
                      }}
                      disabled={
                        l.deliveryType === 'PICKUPSUPPLIER'
                        || quantity === 0
                        || _.isNaN(quantity)
                        || quantity > v.quantity
                      }
                    />
                  ))}
                  <Button
                    margin="0 0 16px 0"
                    disabled={
                      quantity === 0
                      || _.isNaN(quantity)
                      || quantity > v.quantity
                    }
                    small={true}
                    text="เพิ่มล๊อตใหม่"
                    onClick={() => props.newLot(
                      props.id,
                      v,
                      props.thisLot.warehouseId,
                      _.size(props.thisLot.items) === 1
                        ? props.thisLot.deliveryCost
                        : 0,
                      {
                        new: _.find(quantity, (o) => o.id === v.id).quantity,
                        old:
                            v.quantity
                            - _.find(quantity, (o) => o.id === v.id).quantity,
                      },
                    )
                    }
                  />
                </Div>
              }
              title="เปลี่ยนล๊อต"
              visible={
                _.find(visiblesMove, (o) => o.id === v.id && o.index === index)
                  ?.isVisibleMove
              }
              onVisibleChange={() => setVisiblesMove(
                visiblesMove.map((vi, viIndex) => {
                  if (vi.id === v.id && index === viIndex) {
                    return { ...vi, isVisibleMove: !vi.isVisibleMove }
                  }
                  return vi
                }),
              )
              }
            >
              <Icon
                icon="fas fa-sort"
                style={{ cursor: 'pointer' }}
                fontSize="18px"
              />
            </Popover>
          </Col>
        )}
      </Row>
      <Row type="flex" align="middle" gutter={16}>
        <Col span={6}>
          {v.set
            && v.set.map((itemInSet) => (
              <Div
                key={itemInSet.id}
                display="flex"
                width="100%"
                justifyContent="space-between"
              >
                <SmallText color={theme.color.gray50}>
                  {itemInSet.Pack.variantName}
                </SmallText>
                <SmallText color={theme.color.gray50}>
                  {itemInSet.Pack.quantity} ชิ้น
                </SmallText>
              </Div>
            ))}
        </Col>
      </Row>
    </Div>
  ))
}
