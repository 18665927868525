import React, { useState, useEffect, useRef } from 'react'
import { Button, Div, H4, SmallText, P, Label, Input } from '@konsys-ui-custom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { find, first, isEmpty, isNil, isNumber } from 'lodash'
import styled from 'styled-components'
import { Select, Drawer, Skeleton, InputNumber } from 'antd'
import numeral from 'numeral'
import { nanoid } from 'nanoid'
import { Tab } from '../../components'
import { theme } from '../../styles/_variables'
import Img from '../../components/common/img'
import logo from '../../assets/logo-new.png'
import { COMMISSIONS, GET_ROLES, ORDER_BONUS_BY_ROLE, ROLE_COMMISSIONS } from '../../api/query'
import { UPDATE_COMMISSION, UPDATE_ROLE_COMMISSION, EDIT_ORDER_BONUS } from '../../api/mutation'
import { showMessageMutation } from '../../utils'

export const Image = styled(Img)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 8px;
`

const Box = styled(Div)`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  width: 200px;
  height: auto;
  min-height: 82px;
  padding: 8px 16px;
  border-right: 1px solid ${theme.color.gray20};
`

const DrawerContent = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [tiers, setTiers] = useState([{
    id: 1,
    tierPrice: undefined,
    bonus: undefined,
  }])

  const [getOrderBonus, { data: orderBonus, loading: orderBonusLoading, variables }] = useLazyQuery(ORDER_BONUS_BY_ROLE)
  const { data: roles, loading: rolesLoading } = useQuery(GET_ROLES)
  const [doEditOrderBonus, { loading: editingOrderBonus }] = useMutation(EDIT_ORDER_BONUS)

  useEffect(() => {
    if (orderBonus) {
      setTiers(orderBonus?.orderBonusByRole?.data?.map((tier) => ({
        id: tier.id,
        tierPrice: tier.tierPrice,
        bonus: tier.bonus,
      })))
    }
  }, [orderBonus])

  return (
    <Div display='flex' alignItems='center' justifyContent='space-between'>
      <H4 bold>จัดการค่าคอมมิชชั่น</H4>
      <Label bold style={{ cursor: 'pointer' }} onClick={() => setOpenDrawer(true)}>แก้ไข Tier ค่าคอมฯโบนัส</Label>
      <Drawer title="จัดการค่าคอมฯโบนัส" placement="right" onClose={() => setOpenDrawer(false)} visible={openDrawer}>
        <Div display='flex' flexDirection='column' height='100%' justifyContent='space-between'>
          <Div display='flex' flexDirection='column'>
            <P bold margin='8px 0'>จัดการค่าคอมฯ ของ Role</P>
            <Select style={{ margin: '8px 0' }} loading={rolesLoading} onChange={(e) => getOrderBonus({ variables: { roleId: e } })}>
              {
                roles?.rolePermissionList?.data?.map((role) => (
                  <Select.Option key={role.role.id} value={role.role.id}>{role.role.description}</Select.Option>
                ))
              }
            </Select>
            {
              orderBonusLoading
                ? <Skeleton active/>
                : orderBonus
              && <>
                <P bold margin='8px 0'>ลำดับค่าคอมฯโบนัส</P>
                {
                  tiers.map((tier, index) => (
                    <Div key={tier.id} display='flex' alignItems='center' justifyContent='space-between' margin='8px 0'>
                      <Label style={{ marginRight: 4 }}>Tier {index + 1}</Label>
                      <InputNumber style={{ marginRight: 4 }} controls={false} value={tier.tierPrice} onChange={(e) => setTiers(tiers.map((t) => {
                        if (t.id === tier.id) {
                          return ({
                            ...t,
                            tierPrice: e,
                          })
                        } return t
                      }))} />
                      <Label style={{ marginRight: 4 }}>บาท</Label>
                      <Label style={{ marginRight: 4 }}>โบนัส</Label>
                      <InputNumber style={{ marginRight: 4 }} controls={false} value={tier.bonus} onChange={(e) => setTiers(tiers.map((t) => {
                        if (t.id === tier.id) {
                          return ({
                            ...t,
                            bonus: e,
                          })
                        } return t
                      }))} />
                      <Label>%</Label>
                    </Div>
                  ))
                }
                <Button small style={{ margin: '8px 0', alignSelf: 'center' }} text='เพิ่มรายการ' onClick={() => setTiers([...tiers, { id: nanoid() }])} />
              </>
            }
          </Div>
          <Div width='100%' display='flex' justifyContent='center'>
            <Button small disabled={editingOrderBonus} ghost text='Cancel' onClick={() => setOpenDrawer(false)} />
            <Button small disabled={editingOrderBonus} loading={editingOrderBonus} inverse color={theme.color.success} style={{ marginLeft: 16, alignSelf: 'center' }} text='Submit'
              onClick={() => {
                doEditOrderBonus({ variables: {
                  input: {
                    roleId: variables.roleId,
                    orderBonusArr: tiers.map((tier) => ({
                      id: isNumber(tier.id) ? tier.id : undefined,
                      tierPrice: tier.tierPrice,
                      bonus: tier.bonus,
                    })),
                  },
                } }).then((resp) => {
                  showMessageMutation(resp.data.editOrderBonus, () => setOpenDrawer(false))
                })
              }}
            />
          </Div>
        </Div>
      </Drawer>
    </Div>

  )
}

const CommissionTable = ({ type }) => {
  const { data, loading } = useQuery(type === 'users' ? COMMISSIONS : ROLE_COMMISSIONS)
  const [doUpdate, { loading: updating }] = useMutation(type === 'users' ? UPDATE_COMMISSION : UPDATE_ROLE_COMMISSION)
  const vars = useRef([])

  useEffect(() => {
    vars.current = []
  }, [type])

  if (loading) {
    return <Skeleton active/>
  } return (
    <Div display='flex' flexDirection='column' height='100%'>
      <DrawerContent />
      <Div width='100%' height='100%' margin='16px 0' style={{ overflow: 'auto', position: 'relative' }}>
        <Div height='100px' display='flex' width='max-content' style={{
          borderBottom: `1px solid ${theme.color.gray20}`, overflow: 'auto', position: 'sticky', top: 0, background: theme.color.background, zIndex: 2,
        }}>
          <Box />
          {
            first((type === 'users' ? data.commissions : data?.roleCommissions)?.data)?.products.map((v) => (
              <Box
                alignItems='flex-start'
                key={v.id}
              >
                <Image src={first(v.files)?.url || logo} />
                <Div flexDirection='column'>
                  <SmallText bold>{v.name}</SmallText>
                  <Label>ราคา {numeral(v.price).format('0,0')} บาท</Label>
                </Div>
              </Box>
            ))
          }
        </Div>
        {
          (type === 'users' ? data.commissions : data?.roleCommissions)?.data.map((v) => (
            <Div key={v.id} height='100px' width='max-content' display='flex' style={{ borderBottom: `1px solid ${theme.color.gray20}` }}>
              <Box justifyContent='flex-start' style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: theme.color.background }}><SmallText bold>{type === 'users' ? v.sale : v.roleDescription}</SmallText></Box>
              {
                v?.products.map((p) => <Box key={p.id}>
                  <Input
                    className='input-comission'
                    style={{ textAlign: 'right', background: 'none', border: 'none', color: theme.color.primaryColor, fontWeight: theme.fonts.style.regular }}
                    defaultValue={p.commission > 0 ? p.commission : undefined}
                    placeholder={p.commission}
                    onChange={(e) => {
                      vars.current = find(vars.current, (o) => (
                        (type === 'users' ? o.userId : o.roleId) === v.id
                            && o.packId === (p.type === 'PACK' ? p.id : undefined)
                            && o.productSetId === (p.type === 'SET' ? p.id : undefined)
                      ))
                        ? vars.current.map((va) => {
                          if (
                            (type === 'users' ? va.userId : va.roleId) === v.id
                                && va.packId === (p.type === 'PACK' ? p.id : undefined)
                                && va.productSetId === (p.type === 'SET' ? p.id : undefined)
                          ) {
                            return ({
                              userId: type === 'users' ? v.id : undefined,
                              roleId: type !== 'users' ? v.id : undefined,
                              packId: p.type === 'PACK' ? p.id : undefined,
                              productSetId: p.type === 'SET' ? p.id : undefined,
                              commission: e.target.value,
                            })
                          } return va
                        })
                        : [...vars.current, {
                          userId: type === 'users' ? v.id : undefined,
                          roleId: type !== 'users' ? v.id : undefined,
                          packId: p.type === 'PACK' ? p.id : undefined,
                          productSetId: p.type === 'SET' ? p.id : undefined,
                          commission: e.target.value,
                        }]
                    }}
                  />
                  <SmallText style={{ marginLeft: 8 }} color={theme.color.gray70}>%</SmallText>
                </Box>)
              }
            </Div>
          ))
        }
      </Div>
      <Div display='flex' style={{ alignSelf: 'flex-end' }}>
        <Button bold color={theme.color.gray50} inverse margin='0 16px 0 0' disabled={updating} text='ยกเลิก'/>
        <Button bold color={theme.color.success} inverse disabled={updating} loading={updating} text='บันทึก' onClick={() => {
          doUpdate({
            variables: {
              commissions: vars.current.map((v) => ({ ...v, commission: isEmpty(v.commission) || isNil(v.commission) ? 0 : parseFloat(v.commission) })),
            },
          }).then((res) => showMessageMutation(type === 'users' ? res.data?.updateCommission : res.data?.updateRoleCommission))
        }}/>
      </Div>
    </Div>
  )
}

export default () => (
  <Tab
    fullHeight
    title={[
      {
        text: 'ค่าคอมมิชชั่นแอดมิน',
        icon: 'fad fa-badge-percent',
      },
      {
        text: 'ค่าคอมมิชชั่นแต่ละ Role',
        icon: 'fad fa-badge-percent',
      },
    ]}
  >
    <CommissionTable type='users' />
    <CommissionTable type='roles' />
  </Tab>
)
