import { Helmet } from 'react-helmet'
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import _, { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { Popconfirm, Popover, message, DatePicker, Modal, Row, Col } from 'antd'
import {
  P,
  Div,
  Button,
  Icon,
  Input,
  H3,
  SmallText,
  RadioGroup,
  Label,
  TextLink,
} from '@konsys-ui-custom'
import { useHistory } from 'react-router-dom'
import {
  FullPageContainer,
  SelectWithLabel,
  GroupRowSelect,
  Divider,
  SmallTextWithLabel,
  TextareaWithLabel,
  Uploader,
  Loading,
  Select,
  MessagePage,
  InputWithLabel,
} from '../../components'
import { theme } from '../../styles/_variables'
import { menuItems } from '../../constants/menu-config'
import { PATH } from '../../constants/path'
import MESSAGE from '../../constants/message'
import {
  SUPPLIERS,
  PURCHASE_ORDERS_BY_SUPPLIER,
  NEXT_GR_NUMBER,
  GOOD_RECEIVE_INFO,
  WAREHOUSES,
  USER,
} from '../../api/query'
import {
  CREATE_GOOD_RECEIVE,
  UPDATE_GOOD_RECEIVE,
  ACTIVE_GOOD_RECEIVE,
} from '../../api/mutation'
import { validateData, permissionRedirect } from '../../utils/util-services'
import { getTokenData } from '../../utils/local-data-service'
import { ShowDataWrapper } from './styled'
import { GR_KEYS } from '../../constants/status/gr'
import { AuthorizationContext } from '../../store/StoreProvider'

const pageMenuData = _.isEmpty(_.find(menuItems, (v) => v.path === PATH.gr))
  ? '-'
  : _.find(menuItems, (v) => v.path === PATH.gr)
const pageName = _.isEmpty(pageMenuData) ? '' : pageMenuData.name

const ItemRow = (props) => {
  const totalVat = props.isIncludeVat
    ? (props.data.newPrice
        * ((props?.data?.childItem?.vat || props?.data?.vat || 7) || 0))
      / (100 + ((props?.data?.childItem?.vat || props?.data?.vat || 7) || 0))
    : (props.data.newPrice
        * ((props?.data?.childItem?.vat || props?.data?.vat || 7) || 0))
      / 100
  const totalPriceWithVat = props.isIncludeVat
    ? props.data.newPrice
    : parseFloat(props.data.newPrice) + parseFloat(totalVat)
  const orderId = props.data.grOrderId || props.data.orderItemId

  return (
    <Div
      display="flex"
      alignItems="flex-start"
      margin={props.margin}
      padding={props.padding}
      moreStyle={
        props.showData && !props.isLastItem
          ? { borderBottom: `1px solid ${theme.color.gray10}` }
          : {}
      }
    >
      {!props.isEdit && !props.showData && (
        <Icon
          cursor="pointer"
          color={theme.color.lightBlue}
          margin="0 24px 0 0"
          icon="fal fa-trash-alt"
          onClick={() => props.onDelete
            && props.onDelete(props.data.purchaseOrderId, props.data.orderItemId)
          }
        />
      )}
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={!_.isUndefined(props.data?.orderItem?.childOrderItems) ? 4 : 6}>
          <SmallText>
            {!_.isEmpty(props.data.pack)
              ? `${props.data.productName} (${props.data.pack})`
              : props.data.productName}
          </SmallText>
          <Div
            margin="8px 0 0"
            padding="8px 0 0"
            moreStyle={{ borderTop: `1px solid ${theme.color.gray20}` }}
          >
            <SmallText
              bold={!_.isEmpty(props.data?.soNumber)}
              display="block"
              margin="0 0 4px"
            >
                  SO Number: {props.data?.soNumber || '-'}
            </SmallText>
            <SmallText display="block" margin="0 0 4px">
                  sku: {props.data.sku}
            </SmallText>
            <SmallText display="block">brand: {props.data.brand}</SmallText>
          </Div>
        </Col>
        {!_.isUndefined(props.data?.orderItem?.childOrderItems) && (
          <Col span={2}>
            <SmallTextWithLabel
              className="small-text-with-label"
              style={{ width: '70px', wordBreak: 'break-all' }}
              margin="0 16px 0 0"
              title={{ text: 'จำนวนต่อเชต' }}
              content={{
                text: numeral(
                  props.data.allQuantity / props.data.orderItem.quantity || 0,
                ).format('0,0.00'),
              }}
            />
          </Col>
        )}
        <Col span={2}>
          <SmallTextWithLabel
            className="small-text-with-label"
            style={{ width: '70px', wordBreak: 'break-all' }}
            margin="0 16px 0 0"
            title={{ text: 'ราคา / ชิ้น' }}
            content={{
              text: numeral(
                props?.data?.childItem?.buyPrice || props?.data?.price || 0,
              ).format('0,0.00'),
              bold: true,
              color:
                  _.isUndefined(props?.data?.childItem)
                  && numeral(totalPriceWithVat).format('0,0.00')
                    !== numeral(props.data.price - props.data?.itemDiscount).format(
                      '0,0.00',
                    )
                  && theme.color.error,
            }}
          />
        </Col>
        <Col span={2}>
          <SmallTextWithLabel
            className="small-text-with-label"
            style={{ width: '70px', wordBreak: 'break-all' }}
            title={{ text: 'ส่วนลด' }}
            content={{
              text: numeral(props.data.itemDiscount).format('0,0.00'),
            }}
          />
        </Col>
        {
          !props.isEdit && props.showData
            ? (
              <Col span={2}>
                <SmallTextWithLabel
                  className="small-text-with-label"
                  title={{ text: 'ราคา / ชิ้น' }}
                  content={{
                    text: numeral(
                      props.isIncludeVat
                        ? props.data.newPrice
                        : props.data.newPrice * (1 + props.data.vat * 0.01),
                    ).format('0,0.00'),
                    color:
                    numeral(totalPriceWithVat).format('0,0.00')
                      !== numeral(
                        props.data.price - props.data?.itemDiscount,
                      ).format('0,0.00') && theme.color.success,
                    style: { wordBreak: 'break-all' },
                  }}
                />
              </Col>
            )
            : (
              <>
                <Col span={2}>
                  <Input
                    width="100%"
                    margin="2px 16px 0 0"
                    rule={{ type: 'float' }}
                    value={props.data.newPrice}
                    disabled={
                      props.isEdit
                  && props.status !== GR_KEYS.draft
                  && props.status !== GR_KEYS.confirm
                    }
                    state={
                      (_.isEmpty(props.data.newPrice)
                    && !_.isNumber(props.data.newPrice))
                  || _.isNaN(props.data.newPrice)
                        ? 'error'
                        : 'defalut'
                    }
                    onChange={(e) => props.onNewPriceChange(
                      props.data.purchaseOrderId,
                      orderId,
                      e.target.value,
                    )
                    }
                  />
                </Col>
                <Col span={3}>
                  <SmallTextWithLabel
                    margin="0 4px 0 0"
                    className="small-text-with-label"
                    style={{ width: '70px', wordBreak: 'break-all' }}
                    title={{ text: 'ภาษีมูลค่าเพิ่ม' }}
                    content={{ text: numeral(totalVat).format('0,0.00') }}
                  />
                </Col>
                <Col span={3}>
                  <SmallTextWithLabel
                    margin="0 0 0 4px"
                    className="small-text-with-label"
                    style={{ width: '70px', wordBreak: 'break-all' }}
                    // style={{ width: '45%', wordBreak: 'break-all' }}
                    title={{ text: 'ราคารวมภาษี' }}
                    content={{
                      text: numeral(totalPriceWithVat).format('0,0.00'),
                    }}
                  />
                </Col>
              </>
            )}
        {
          !props.isEdit ? (
            props.showData ? (
              <Col span={3}>
                <SmallTextWithLabel
                  className="small-text-with-label"
                  title={{ text: 'จำนวนที่รับ' }}
                  content={{ text: props.data.quantity }}
                />
              </Col>
            ) : (
              <>
                <Col span={3}>
                  <Popover
                    title="จำนวนที่รอรับ"
                    content={
                      <Div>
                        <P>
                        จำนวนที่รอรับอีก{' '}
                          {props.data.allQuantity - props.data.receivedQuantity
                          || '-'}{' '}
                        ชิ้น
                        </P>
                      </Div>
                    }
                  >
                    <Div
                      margin="2px 16px 0 0"
                      width="30%"
                      moreStyle={{ minWidth: '75px' }}
                    >
                      <Input
                        width="100%"
                        rule={{ type: 'float' }}
                        value={props.data.quantity}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value)
                          if (props.onQuantityChange) {
                            props.onQuantityChange(
                              props.data.purchaseOrderId,
                              props.data.orderItemId,
                              !_.isNumber(e.target.value)
                              && _.isEmpty(e.target.value)
                                ? ''
                                : value
                                <= props.data.allQuantity
                                  - props.data.receivedQuantity
                                  ? e.target.value
                                  : props.data.quantity,
                            )
                          }
                        }}
                        state="default"
                      />
                    </Div>
                  </Popover>
                </Col>
                <Col span={3}>
                  <SmallTextWithLabel
                    className="small-text-with-label"
                    style={{ width: '70%', wordBreak: 'break-all' }}
                    title={{ text: 'ราคารวม' }}
                    content={{
                      text: numeral(
                        totalPriceWithVat * props.data.quantity,
                      ).format('0,0.00'),
                    }}
                  />
                </Col>
              </>
            )
          ) : (
            <Col span={3}>
              <Input
                width="100%"
                rule={{ type: 'float' }}
                value={props.data.quantity}
                disabled={props.isEdit && props.status !== GR_KEYS.draft}
                onChange={(e) => {
                  const value = parseFloat(e.target.value)
                  if (
                    (!_.isNumber(e.target.value)
                      && _.isEmpty(e.target.value))
                    || value <= 0
                  ) {
                    props.onQuantityChange(
                      props.data.purchaseOrderId,
                      orderId,
                      null,
                    )
                  } else if (
                    props.onQuantityChange
                    && value
                      <= props.data.allQuantity - props.data.receivedQuantity
                  ) {
                    props.onQuantityChange(
                      props.data.purchaseOrderId,
                      orderId,
                      value,
                    )
                  }
                }}
              />
            </Col>
          )}
      </Row>
    </Div>
  )
}

const ConcludeTable = (props) => {
  let isPriceNotEqual = false
  const groupList = _.reduce(props.list, (result, n) => [...result, n], []) || []
  return (
    <Div>
      {!props.showData && (
        <Divider margin={!props.showData ? '8px 0 24px' : '0'} />
      )}
      <Div
        moreStyle={
          props.showData ? { maxHeight: '220px', overflow: 'auto' } : {}
        }
      >
        {groupList.map((group, i) => (
          <Div
            key={i}
            moreStyle={{ borderBottom: `1px solid ${theme.color.gray20}` }}
          >
            {_.isArray(group) && !_.isUndefined(group[0]?.productSet) && (
              <Div margin={!props.showData && '16px 0 24px 36px'}>
                <SmallText style={{ fontWeight: 500 }} bold>
                  เซตสินค้า
                </SmallText>
                <Div display="flex">
                  <SmallText
                    style={{ fontWeight: 500 }}
                    bold
                    margin="0 8px 0 0"
                  >
                    {group[0]?.productSet?.sku}
                  </SmallText>
                  <SmallText
                    style={{ fontWeight: 500 }}
                    bold
                    margin="0 8px 0 0"
                  >
                    {group[0]?.productSet?.name}
                  </SmallText>
                  <SmallText
                    style={{ fontWeight: 500 }}
                    bold
                    margin="0 8px 0 0"
                  >
                    ราคาเชตละ{' '}
                    {numeral(group[0]?.orderItem?.buyPrice).format('0,0.00')}{' '}
                    บาท
                  </SmallText>
                  <SmallText
                    style={{ fontWeight: 500 }}
                    bold
                    margin="0 8px 0 0"
                  >
                    สั่งซื้อจำนวน {group[0]?.orderItem?.quantity} เซต
                  </SmallText>
                </Div>
              </Div>
            )}
            {_.isArray(group)
              && group?.map((v, index) => {
                if (_.isEmpty(v)) return null
                if (
                  Math.abs(
                    v.price
                      - v.itemDiscount
                      - (props.isIncludeVat
                        ? parseFloat(v.newPrice)
                        : parseFloat(v.newPrice * (1 + (v.vat || 0) * 0.01))),
                  ) > 1
                ) {
                  isPriceNotEqual = true
                }
                return (
                  <Div key={v.orderItemId}>
                    {!props.showData && (
                      <Row gutter={16} style={{ marginLeft: 30, marginTop: 16 }}>
                        <Col span={10}>
                          <SmallText bold>สินค้า</SmallText>
                        </Col>
                        <Col span={8}>
                          <SmallText bold>
                            ราคา / ชิ้น
                            <Label color={theme.color.error}>*</Label>
                          </SmallText>
                        </Col>
                        <Col span={6}>
                          <SmallText bold>
                            จำนวนที่รับ{' '}
                            <Label color={theme.color.error}>*</Label>
                          </SmallText>
                        </Col>
                      </Row>
                    )}
                    <ItemRow
                      status={props.status}
                      isLastItem={index === props.list.length - 1}
                      showData={props.showData}
                      isEdit={props.isEdit}
                      margin={
                        index === props.list.length - 1
                          ? '0'
                          : props.showData
                            ? '0 0 8px'
                            : '8px 0 24px'
                      }
                      padding={props.showData ? '8px 0' : '0'}
                      data={v}
                      isIncludeVat={props.isIncludeVat}
                      onDelete={(poId, orderId) => props.onDelete && props.onDelete(poId, orderId)
                      }
                      onQuantityChange={(poId, orderId, value) => props.onQuantityChange
                        && props.onQuantityChange(poId, orderId, value)
                      }
                      onNewPriceChange={(poId, orderId, value) => props.onNewPriceChange
                        && props.onNewPriceChange(poId, orderId, value)
                      }
                    />
                  </Div>
                )
              })}
          </Div>
        ))}
      </Div>
      {isPriceNotEqual && props.showData && (
        <Div
          bgColor={theme.color.remark}
          padding="16px;"
          display="flex"
          alignItems="center"
          margin="0 0 4px"
        >
          <Icon
            color={theme.color.warning}
            icon="fas fa-exclamation-triangle"
            margin="0 8px 0 0"
          />
          <P>
            ราคา / ชิ้น ที่ระบุ ไม่ตรงกับราคาที่สั่งซื้อใน PO
            หากยืนยันรับสินค้าตามนี้ GR
          </P>
          <P
            color={theme.color.error}
            margin="0 0 0 4px"
            style={{ fontWeight: theme.fonts.style.medium }}
            bold
          >
            จะต้องให้ฝ่ายจัดซื้อมาอนุมัติ
          </P>
        </Div>
      )}
    </Div>
  )
}

const ConcludeTableInventory = (props) => {
  const poList = props
    && props.poListBySupplier
    && _.reduce(
      props.poListBySupplier,
      (array, n) => [...array, { text: n.number, value: n.id }],
      [],
    )
  return (
    <Div>
      {!props.showData && (
        <Div display="flex" alignItems="center" justifyContent="space-between">
          <SmallText bold style={{ width: '36.5%' }} margin="0 40px 0 38px">
            สินค้า
          </SmallText>
          <Div
            display="flex"
            alignItems="center"
            moreStyle={{ flexBasis: '45%' }}
          >
            <SmallText style={{ width: '33%' }} bold margin="0 32px 0 0">
              ราคา / หน่วย <Label color={theme.color.error}>*</Label>
            </SmallText>
            <SmallText style={{ width: '33%' }} bold margin="0 32px 0 0">
              ส่วนลด / หน่วย
            </SmallText>
            <SmallText
              style={{ width: '34%' }}
              bold
              textAlign="right"
              margin="0 32px 0 0"
            >
              จำนวนที่รับ <Label color={theme.color.error}>*</Label>
            </SmallText>
          </Div>
        </Div>
      )}
      {!props.showData && <Divider margin="8px 0 24px" />}
      <Div
        moreStyle={
          props.showData ? { maxHeight: '220px', overflow: 'auto' } : {}
        }
      >
        {props.list
          && props.list.map((v, i) => {
            const itemList = props
              && props.poListBySupplier
              && v.purchaseOrderId
              && _.reduce(
                [
                  _.find(
                    props.poListBySupplier,
                    (o) => o.id === v.purchaseOrderId,
                  ),
                ],
                (array, n) => {
                  let newArray = [...array]
                  if (
                    !_.isEmpty(n)
                    && !_.isEmpty(n.orderItemWithOutSaleOrder)
                  ) {
                    n.orderItemWithOutSaleOrder.forEach((o) => {
                      if (o.quantity !== o.receivedQuantity) {
                        newArray = _.flatten([
                          ...newArray,
                          _.isEmpty(o.childOrderItems)
                            ? {
                              text: o.pack?.inventoryOption?.inventory?.sku,
                              value: o.id,
                              isFromSo: false,
                              } //eslint-disable-line
                            : o.childOrderItems.map((orderItem) => ({
                              text: `(สินค้าในเซต ${o?.productSet?.name}) ${orderItem.pack?.inventoryOption?.inventory?.sku}`,
                              value: orderItem.id,
                              isFromSo: false,
                            })),
                        ])
                      }
                    })
                  }
                  if (!_.isEmpty(n) && !_.isEmpty(n.orderItemWithSaleOrder)) {
                    const itemListFromSo = _.flatten(
                      n.orderItemWithSaleOrder.map((o) => o.items.map((d) => {
                        if (!_.isEmpty(d.childOrderItems)) {
                          return d?.childOrderItems?.map((childItem) => ({
                            text: `(สินค้าในเซต ${d?.productSet?.name}) ${childItem.pack?.inventoryOption?.inventory?.sku}`,
                            value: childItem.id,
                            id: childItem.id,
                              soId: o._id, //eslint-disable-line
                            isFromSo: true,
                          }))
                        }
                        return {
                          text:
                              d.pack?.inventoryOption?.inventory?.sku
                              || (!_.isEmpty(d.childOrderItems)
                                && d.childOrderItems[0]?.pack?.inventoryOption
                                  ?.inventory?.sku)
                              || '-',
                          value: d.id,
                          id: d.id,
                            soId: o._id, //eslint-disable-line
                          isFromSo: true,
                        }
                      })),
                    )
                    newArray = _.concat(newArray, _.flatten(itemListFromSo))
                  }
                  return newArray
                },
                [],
              )
            if (_.isEmpty(v)) return null
            return (
              <Div
                key={1}
                padding={props.showData ? '16px 0' : '0'}
                moreStyle={{
                  borderBottom: props.showData
                    ? i === props.list.length - 1
                      ? '0'
                      : `1px solid ${theme.color.gray10}`
                    : '0',
                }}
              >
                <Div
                  display="flex"
                  alignItems={props.showData ? 'flex-start' : 'center'}
                  justifyContent="space-between"
                  margin={props.margin}
                >
                  <Div
                    display="flex"
                    alignItems="center"
                    moreStyle={{ flexGrow: 1 }}
                  >
                    {!props.isEdit && !props.showData && (
                      <Icon
                        cursor="pointer"
                        color={theme.color.lightBlue}
                        margin="0 24px 0 0"
                        icon="fal fa-trash-alt"
                        onClick={() => props.onDelete && props.onDelete(v.itemId)
                        }
                      />
                    )}
                    <Div
                      display="flex"
                      alignItems="flex-start"
                      moreStyle={{ flexGrow: 1 }}
                      margin={
                        props.showData
                          ? '0'
                          : !props.isEdit
                            ? '0 40px 0 0'
                            : '0 40px 0 36.25px'
                      }
                    >
                      <SmallTextWithLabel
                        className="small-text-with-label"
                        style={{ width: '70px', wordBreak: 'break-all' }}
                        margin="0 16px 0 0"
                        title={{ text: 'รหัส' }}
                        content={{ text: v.sku }}
                      />
                      <SmallTextWithLabel
                        className="small-text-with-label"
                        style={{
                          flexBasis: '40%',
                          width: '40%',
                          wordBreak: 'break-all',
                        }}
                        margin="0 16px 0 0"
                        title={{ text: 'ชื่อ' }}
                        content={{ text: v.productName }}
                      />
                      <SmallTextWithLabel
                        className="small-text-with-label"
                        style={{ flexBasis: props.showData ? '23%' : '18%' }}
                        margin="0 16px 0 0"
                        title={{ text: 'ยี่ห้อ' }}
                        content={{ text: v.brand }}
                      />
                    </Div>
                  </Div>
                  {!props.showData ? (
                    <Div
                      key={`${v.sku}-${v.productName}`}
                      display="flex"
                      alignItems="center"
                      moreStyle={{ flexBasis: '45%' }}
                    >
                      <Div margin="0 32px 0 0" width="33%">
                        <Input
                          disabled={
                            (v.isInPO && !v.isReplaceItemInPO)
                            || !v.isInPO
                            || (props.isEdit
                              && props.status !== GR_KEYS.draft
                              && props.status !== GR_KEYS.confirm)
                          }
                          value={v.price}
                          width="100%"
                          rule={{ type: 'float' }}
                          onChange={(e) => {
                            if (props.onPriceChange) {
                              props.onPriceChange(
                                v.itemId,
                                _.isEmpty(e.target.value)
                                  ? null
                                  : parseFloat(e.target.value),
                              )
                            }
                          }}
                          state={
                            _.isNaN(v.price)
                            || (_.isEmpty(v.price) && !_.isNumber(v.price))
                              ? 'error'
                              : 'default'
                          }
                        />
                      </Div>
                      <Div margin="0 32px 0 0" width="33%">
                        <Input
                          state="default"
                          disabled={
                            !v.isInPO
                            || (v.isInPO && !v.isReplaceItemInPO)
                            || (props.isEdit
                              && props.status !== GR_KEYS.draft
                              && props.status !== GR_KEYS.confirm)
                          }
                          value={v.itemDiscount}
                          width="100%"
                          rule={{ type: 'float' }}
                          onChange={(e) => {
                            if (props.onDiscountChange) {
                              props.onDiscountChange(
                                v.itemId,
                                _.isEmpty(e.target.value)
                                  ? null
                                  : parseFloat(e.target.value),
                              )
                            }
                          }}
                        />
                      </Div>
                      <Div margin="0 32px 0 0" width="34%">
                        <Input
                          disabled={
                            props.isEdit && props.status !== GR_KEYS.draft
                          }
                          value={v.quantity}
                          width="100%"
                          rule={{ type: 'float' }}
                          onChange={(e) => {
                            if (props.onQuantityChange) {
                              props.onQuantityChange(
                                v.itemId,
                                _.isEmpty(e.target.value)
                                  || parseFloat(e.target.value) === 0
                                  || (e.target.value === '.'
                                    && _.isEmpty(v.quantity))
                                  ? null
                                  : e.target.value,
                              )
                            }
                          }}
                          state={
                            _.isNaN(v.quantity)
                            || (_.isEmpty(v.quantity)
                              && !_.isNumber(v.quantity))
                            || v.quantity <= 0
                              ? 'error'
                              : 'default'
                          }
                        />
                      </Div>
                    </Div>
                  ) : (
                    <Div
                      display="flex"
                      alignItems="center"
                      moreStyle={{ flexBasis: '45%' }}
                    >
                      <SmallTextWithLabel
                        className="small-text-with-label"
                        style={{ flexBasis: '38.5%' }}
                        margin="0 16px 0 0"
                        title={{ text: 'ราคา' }}
                        content={{ text: v.price || 0, bold: true }}
                      />
                      <SmallTextWithLabel
                        className="small-text-with-label"
                        style={{ flexBasis: '38.5%' }}
                        margin="0 16px 0 0"
                        title={{ text: 'ส่วนลด' }}
                        content={{ text: v.itemDiscount || 0 }}
                      />
                      <SmallTextWithLabel
                        className="small-text-with-label"
                        style={{ flexBasis: '40%' }}
                        margin="0 16px 0 0"
                        title={{ text: 'จำนวนที่รับ' }}
                        content={{ text: v.quantity }}
                      />
                    </Div>
                  )}
                </Div>
                {props.isEdit || props.showData ? (
                  <Div
                    margin={props.showData ? '0 0 0 16px' : '0 0 40px 36.25px'}
                  >
                    <Divider margin="12px 0" />
                    {v.isInPO ? (
                      <Div>
                        {v.isReplaceItemInPO ? (
                          <SmallText className="bold" bold display="block">
                            แทนสินค้า{' '}
                            {_.isEmpty(
                              _.find(itemList, (o) => o.value === v.orderItemId),
                            )
                              ? '-'
                              : _.find(
                                itemList,
                                (o) => o.value === v.orderItemId,
                              ).text}{' '}
                            (
                            {_.isEmpty(
                              _.find(
                                poList,
                                (o) => o.value === v.purchaseOrderId,
                              ),
                            )
                              ? '-'
                              : _.find(
                                poList,
                                (o) => o.value === v.purchaseOrderId,
                              ).text}
                            )
                          </SmallText>
                        ) : (
                          <SmallText
                            className="bold"
                            margin="0 0 4px"
                            bold
                            display="block"
                          >
                            เป็นของแถมใน{' '}
                            {_.isEmpty(
                              _.find(
                                poList,
                                (o) => o.value === v.purchaseOrderId,
                              ),
                            )
                              ? '-'
                              : _.find(
                                poList,
                                (o) => o.value === v.purchaseOrderId,
                              ).text}
                          </SmallText>
                        )}
                      </Div>
                    ) : (
                      <SmallText className="bold" bold>
                        เป็นของแถม
                      </SmallText>
                    )}
                  </Div>
                ) : (
                  <Div margin="0 0 40px 36.25px">
                    <Divider margin="12px 0" />
                    <SmallText bold margin="0 16px 0 0">
                      ต้องการเพิ่มสินค้าในใบใบสั่งซื้อหรือไม่ ?
                    </SmallText>
                    <RadioGroup
                      name={`isInPO-${v.grOrderId || v.itemId}`}
                      list={[
                        {
                          value: `notInPO-${v.grOrderId || v.itemId}`,
                          text: 'ไม่เพิ่มในใบ PO',
                          id: 'test1',
                        },
                        {
                          value: `inPO-${v.grOrderId || v.itemId}`,
                          text: 'เพิ่มในใบ PO',
                          id: 'test2',
                        },
                      ]}
                      defaultCheckedValue={
                        v.isInPO
                          ? `inPO-${v.grOrderId || v.itemId}`
                          : `notInPO-${v.grOrderId || v.itemId}`
                      }
                      onChange={(e) => props.onChangeIsInPo(
                        v.itemId,
                        e.target.value === `inPO-${v.grOrderId || v.itemId}`,
                      )
                      }
                      small
                    />
                    {v.isInPO && !_.isEmpty(poList) && (
                      <Div>
                        <Div
                          margin="8px 0 0"
                          display="flex"
                          alignItems="center"
                        >
                          <SmallText bold margin="0 16px 0 0">
                            เลือกใบ PO
                          </SmallText>
                          <Select
                            style={{ width: '200px' }}
                            placeholder="เลือกใบ PO"
                            options={poList}
                            value={
                              v && v.purchaseOrderId
                                ? v.purchaseOrderId
                                : undefined
                            }
                            onChange={(value) => props.onPoRefChange
                              && props.onPoRefChange(v.itemId, value)
                            }
                          />
                        </Div>
                        {!_.isEmpty(itemList)
                          && !_.isEmpty(v)
                          && v.purchaseOrderId && (
                          <Div>
                            <SmallText margin="8px 16px 0 0" bold>
                                ต้องการแทน Item ในใบสั่งซื้อหรือไม่ ?
                            </SmallText>
                            <RadioGroup
                              name={`isReplaceItemInPO-${
                                  v.grOrderId || v.itemId
                                }`}
                              small
                              list={[
                                {
                                  value: `notReplaceItemInPO-${
                                      v.grOrderId || v.itemId
                                    }`,
                                  text: 'ไม่แทนสินค้าในใบ PO',
                                },
                                {
                                  value: `replaceItemInPO-${
                                      v.grOrderId || v.itemId
                                    }`,
                                  text: 'แทนสินค้าใน PO',
                                },
                              ]}
                              defaultCheckedValue={
                                v.isReplaceItemInPO
                                  ? `replaceItemInPO-${
                                        v.grOrderId || v.itemId
                                      }`
                                  : `notReplaceItemInPO-${
                                        v.grOrderId || v.itemId
                                      }`
                              }
                              onChange={(e) => props.onChangeIsReplaceItem
                                  && props.onChangeIsReplaceItem(
                                    v.itemId,
                                    e.target.value
                                      === `replaceItemInPO-${
                                        v.grOrderId || v.itemId
                                      }`,
                                  )
                              }
                            />
                          </Div>
                        )}
                        {v.isReplaceItemInPO && !_.isEmpty(itemList) && (
                          <Div
                            margin="8px 0 0"
                            display="flex"
                            alignItems="center"
                          >
                            <SmallText margin="0 16px 0 0" bold>
                              เลือก Item ที่ต้องการแทน
                            </SmallText>
                            <Select
                              style={{ width: '400px' }}
                              placeholder="เลือก Item ที่ต้องการแทน"
                              options={itemList}
                              value={v.orderItemId}
                              onChange={(value) => props.onReplaceItemChange
                                && props.onReplaceItemChange(v.itemId, value)
                              }
                            />
                          </Div>
                        )}
                      </Div>
                    )}
                  </Div>
                )}
              </Div>
            )
          })}
      </Div>
    </Div>
  )
}

const DoActionButton = (props) => (
  <Button
    margin="0 4px"
    text={
      props.isDraft
        ? 'บันทึกร่าง'
        : props.isEdit
          ? 'อัพเดตใบ GR'
          : 'ยืนยันรับสินค้าเข้าคลัง และสร้างใบ GR'
    }
    inverse={!props.isDraft && !props.isEdit}
    color={props.isDraft ? theme.color.secondaryColor : theme.color.success}
    icon={props.isDraft ? 'fal fa-pencil-ruler' : 'fal fa-check'}
    loading={props.disabledButton}
    disabled={
      props.disabledButton
      || (!props.isEdit
        ? validateData(
          true,
          props.validateCreate(),
          [],
        )
        : validateData(
          false,
          props.validateUpdate(),
          props.allData,
        ))
    }
    onClick={() => {
      props.setDisabledButton(true)
      if (props.isEdit) {
        props.updateOnEdit()
      } else {
        props.confirmBeforeCreate()
      }
    }}
  />
)

const GrForm = (props) => {
  const [isDataHasChange, setIsDataHasChange] = useState(false)
  const [invoiceRef, setInvoiceRef] = useState()
  const [remark, setRemark] = useState('')
  const [fileList, setFileList] = useState([])
  const [existFileList, setExistFileList] = useState([])
  const [selectedOrderItem, setSelectedOrderItem] = useState([])
  const [selectedInventoryItem, setSelectedInventoryItem] = useState([])
  const [dueDate, setDueDate] = useState(undefined)
  const [searchPo, setSearchPo] = useState('')
  const [selectedSupplier, setSelectedSupplier] = useState(1)
  const [disabledButton, setDisabledButton] = useState(false)
  const [isSearchRefetch, setIsSearchRefetch] = useState(false)
  const [selectedWarehouse, setSelectedWarehouse] = useState()
  const [isIncludeVat, setIsIncludeVat] = useState(true)
  const { data, loading } = useQuery(SUPPLIERS)
  const { data: warehouseData, loading: warehouseLoading } = useQuery(WAREHOUSES)
  const { data: nextGrNumberData, loading: nextGrNumberLoading } = useQuery(NEXT_GR_NUMBER)
  const history = useHistory()
  const [
    getPOItemsBySupplier,
    { data: poListsData, loading: poListsLoading, refetch: poListsRefetch },
  ] = useLazyQuery(PURCHASE_ORDERS_BY_SUPPLIER)
  const supplierListOption = !loading
    && data
    && data.supplierList
    && data.supplierList.data
    && data.supplierList.data.total > 0
    ? data.supplierList.data.list.map((v) => ({
      text: v.name,
      value: v.id,
      paymentType: v.paymentType,
    }))
    : []
  const [doCreateGr, { loading: createGrLoading }] = useMutation(
    CREATE_GOOD_RECEIVE,
    { refetchQueries: ['remainingJob', 'remainingAccountant'] },
  )
  const [doUpdateGr, { loading: updateGrLoading }] = useMutation(
    UPDATE_GOOD_RECEIVE,
    { refetchQueries: ['remainingJob', 'remainingAccountant'] },
  )
  const [doActivateGr] = useMutation(ACTIVE_GOOD_RECEIVE, {
    refetchQueries: ['remainingJob', 'remainingAccountant'],
  })

  useEffect(() => {
    if (props.data) {
      setInvoiceRef(props.data.refId)
      setRemark(props.data.remarks)
      setExistFileList(props.data.goodsReceiveFiles)
      getPOItemsBySupplier({
        variables: {
          id: props.data?.supplier?.id,
          filterStatus: true,
        },
      })
      if (props.data.paymentDueDate) {
        setDueDate(moment(props.data.paymentDueDate))
      }
      if (props.data.supplier !== selectedSupplier) {
        const supplierId = props.data.supplier.id
        setSelectedSupplier(supplierId)
      }
      if (props.data.warehouse) {
        setSelectedWarehouse(props.data.warehouse.id)
      }
      if (props.data.orderItemGR) {
        const inventoryItem = []
        const orderItem = []
        setIsIncludeVat(props.data.orderItemGR[0]?.vatInclude)
        props.data.orderItemGR.forEach((v) => {
          const orderData = v.orderItem
          const itemData = {
            itemDiscount: v.discount,
            quantity: v.quantity,
            sku: orderData.pack?.inventoryOption?.inventory?.sku || '-',
            productName: orderData.pack?.inventoryOption?.variantName || '-',
            brand:
              orderData.pack?.inventoryOption?.inventory?.brand?.name || '-',
            grOrderId: v.id,
            price: v.price || 0,
            purchaseOrderId:
              orderData.purchaseOrder && orderData.purchaseOrder.id,
          }
          if (!v.isExtra && _.isEmpty(v.replace) && !_.isEmpty(orderData)) {
            if (orderData.quantity !== orderData.receivedQuantity) {
              orderItem.push({
                ...itemData,
                allQuantity: orderData.quantity,
                receivedQuantity: orderData.receivedQuantity,
                soNumber:
                  _.isEmpty(orderData.lot)
                  || _.isEmpty(orderData.lot.salesOrder)
                    ? '-'
                    : orderData.lot.salesOrder.soNumber,
                pack: orderItem.pack?.name,
                newPrice: v.price,
              })
            }
          } else {
            inventoryItem.push({
              ...itemData,
              itemId:
                orderData
                && orderData.inventoryOption
                && orderData.inventoryOption.id,
              orderItemId: v.replace && v.replace.id,
              isInPO:
                (v.isExtra && _.isEmpty(v.replace))
                || (!v.isExtra && !_.isEmpty(v.replace)),
              isReplaceItemInPO: !v.isExtra && !_.isEmpty(v.replace),
              itemDiscount: v.discount || 0,
              pack: orderData.pack?.id,
            })
          }
        })
        if (!_.isEmpty(inventoryItem)) {
          setSelectedInventoryItem(inventoryItem)
        }
        if (!_.isEmpty(orderItem)) {
          setSelectedOrderItem(orderItem)
        }
      }
    }
    if (props?.userData?.data?.userDetail?.data?.warehouse) {
      const warehouseId = props.userData?.data?.userDetail?.data?.warehouse?.id
      setSelectedWarehouse(warehouseId)
      getPOItemsBySupplier({
        variables: {
          id: selectedSupplier || undefined,
          filterStatus: true,
        },
      })
    }
  }, [props])

  useEffect(() => {
    getPOItemsBySupplier({
      variables: {
        id: selectedSupplier,
        filterStatus: true,
      },
    })
  }, [])

  const selectSupplierModal = (supplierList, onChange) => {
    Modal.info({
      title: 'เลือก Supplier',
      content: (
        <Div>
          <SelectWithLabel
            style={{ width: '100%' }}
            title={{
              text: '',
            }}
            select={{
              placeholder: 'คลิกเลือก supplier',
              onChange: (value) => {
                if (onChange) {
                  onChange(value)
                }
              },
              style: { width: '100%' },
              options: supplierList,
            }}
          />
        </Div>
      ),
      okButtonProps: { style: { display: 'none' } },
    })
  }

  const updateDataAfterSelect = (
    type,
    selectedList,
    getValue,
  ) => {
    const setData = _.isArray(getValue)
      ? _.unionBy([...selectedList], getValue, 'orderItemId')
      : [...selectedList, getValue]
    if (type === 'inventory') {
      setSelectedInventoryItem(setData)
    } else {
      setSelectedOrderItem(setData)
    }
  }

  const setSupplierNullAndRefetch = () => {
    setSelectedSupplier(null)
    poListsRefetch({
      id: undefined,
      filterStatus: true,
      search: searchPo,
    })
  }

  const checkAndSetSupplier = (
    supplierObj,
    type,
    selectedList,
    getValue,
    supplier,
  ) => {
    if (_.isArray(supplierObj) && supplierObj.length > 1) {
      selectSupplierModal(
        _.reduce(
          supplier.supplier,
          (array, n) => [...array, { text: n.name, value: n.id }],
          [],
        ),
        (value) => {
          setSelectedSupplier(value)
          updateDataAfterSelect(type, selectedList, getValue, value, true)
          Modal.destroyAll()
        },
      )
    } else {
      const supplierId = _.isArray(supplierObj)
        ? supplierObj[0].id
        : supplierObj.id
      setSelectedSupplier(supplierId)
      updateDataAfterSelect(type, selectedList, getValue, supplierId, true)
    }
  }

  const rowRenderData = (type, v, otherData) => {
    const key = 'inventoryOption'
    let orderItemsList = []

    if (type === 'inventory') {
      orderItemsList = !_.isEmpty(v.items)
        && _.compact(
          v.items.map((o) => {
            if (_.isEmpty(o[key])) return null
            return {
              itemId: o[key].id,
              quantity: 1,
              price: o[key].buyPrice,
              itemDiscount: 0,
              purchaseOrderId: null,
              orderItemId: null,
              sku: o[key].inventory.sku,
              isInPO: false,
              isReplaceItemInPO: false,
              productName: o[key].inventory
                ? o[key].inventory.productName
                : '-',
              brand: o[key].inventory ? o[key].inventory.brand : '-',
              pack: o[key].pack[0].id,
            }
          }),
        )
    } else {
      orderItemsList = !_.isEmpty(v.items)
        && _.compact(
          _.flatten(
            v.items.map((o) => {
              if (o.quantity !== o.receivedQuantity) {
                if (!_.isEmpty(o.childOrderItems)) {
                  return o.childOrderItems.map((orderItem) => {
                    const productRender = !_.isEmpty(orderItem) ? orderItem : o
                    return {
                      purchaseOrderId: otherData.id,
                      orderItemId: orderItem.id,
                      allQuantity: productRender.quantity,
                      quantity:
                        productRender.quantity - productRender.receivedQuantity,
                      receivedQuantity: productRender.receivedQuantity,
                      price: orderItem.buyPrice,
                      itemDiscount: 0,
                      soNumber: o.soNumber,
                      productName:
                        productRender.pack?.inventoryOption?.variantName || '-',
                      brand:
                        productRender.pack?.inventoryOption?.inventory?.brand
                          ?.name || '-',
                      sku:
                        productRender.pack?.inventoryOption?.inventory?.sku
                        || '-',
                      pack: productRender.pack?.name || '-',
                      newPrice: 0,
                      productSetPrice: o.buyPrice - o.purchaseOrderDiscount,
                      childItem: orderItem,
                      productSet: o.productSet,
                      orderItem: o,
                      vat: productRender.pack?.inventoryOption?.inventory.vat,
                    }
                  })
                }
                return {
                  purchaseOrderId: otherData.id,
                  orderItemId: !_.isUndefined(o._id) ? o._id : o.id, //eslint-disable-line
                  allQuantity: o.quantity,
                  quantity: o.quantity - o.receivedQuantity,
                  receivedQuantity: o.receivedQuantity,
                  price: o.buyPrice,
                  itemDiscount: _.isNull(o.purchaseOrderDiscount)
                    ? 0
                    : o.purchaseOrderDiscount,
                  soNumber: o.soNumber,
                  productName: o.pack?.inventoryOption?.variantName || '-',
                  brand: o.pack?.inventoryOption?.inventory?.brand?.name || '-',
                  sku: o.pack?.inventoryOption?.inventory?.sku || '-',
                  pack: o.pack?.name,
                  newPrice: 0,
                  vat: o.vat,
                }
              }
              return null
            }),
          ),
        )
    }

    return {
      header:
        type === 'inventory' || _.isEmpty(orderItemsList)
          ? []
          : [
            {
              value:
                  type === 'inventory'
                    ? `inventory - ${otherData.id} - ${otherData.sku}`
                    : `po - ${otherData.id} - ${otherData.number}`,
              disabled: type === 'inventory',
              checked:
                  type === 'inventory'
                    ? _.isEmpty(
                      _.differenceBy(
                        orderItemsList,
                        selectedInventoryItem,
                        'itemId',
                      ),
                    )
                    : _.isEmpty(
                      _.differenceBy(
                        orderItemsList,
                        selectedOrderItem,
                        'orderItemId',
                      ),
                    )
                      && _.isEmpty(
                        _.differenceBy(
                          orderItemsList,
                          selectedOrderItem,
                          'purchaseOrderId',
                        ),
                      ),
              onChange: (e) => {
                if (e.target.checked) {
                  const newList = type === 'inventory'
                    ? [...selectedInventoryItem]
                    : [...selectedOrderItem]
                  if (!_.isEmpty(orderItemsList)) {
                    orderItemsList.forEach(
                      (d) => !_.some(newList, d) && newList.push(d),
                    )
                  }
                  const selectedList = type === 'inventory'
                    ? selectedInventoryItem
                    : selectedOrderItem
                  checkAndSetSupplier(
                    v.supplier,
                    type,
                    selectedList,
                    newList,
                    v.v,
                  )
                } else {
                  const newList = type === 'inventory'
                    ? [...selectedInventoryItem]
                    : [...selectedOrderItem]
                  v.items.forEach((o) => {
                    _.remove(newList, (d) => (type === 'inventory'
                      ? d.itemId === o[key]._id //eslint-disable-line
                      : d.purchaseOrderId === otherData.id
                            && d.orderItemId === o._id)) //eslint-disable-line
                  })
                  if (
                    _.isEmpty(newList) && type === 'inventory'
                      ? _.isEmpty(selectedOrderItem)
                      : _.isEmpty(selectedInventoryItem)
                  ) {
                    setSupplierNullAndRefetch()
                  }
                  if (type === 'inventory') {
                    setSelectedInventoryItem(newList)
                  } else {
                    setSelectedOrderItem(newList)
                  }
                }
              },
              data: [
                {
                  title: {
                    text: type === 'inventory' ? 'รหัสสินค้า' : 'PO ID',
                  },
                  content: {
                    text:
                        type === 'inventory' ? otherData.sku : otherData.number,
                  },
                },
                type !== 'inventory' && {
                  title: {
                    text: 'PO Reference',
                  },
                  content: {
                    text: otherData.extRef || '-',
                  },
                },
              ],
            },
          ],
      items: _.isEmpty(v.items)
        ? []
        : _.flatten(
          v.items.map((o) => {
            const selectedList = type === 'inventory' ? selectedInventoryItem : selectedOrderItem
            if (type !== 'inventory' && o.quantity === o.receivedQuantity) {
              return {}
            }
            const objRender = (renderItem) => {
              const uniq = `${otherData.id} - ${
                  type === 'inventory'
                    ? o[key].id
                    : !_.isEmpty(renderItem)
                      ? renderItem.id
                      : !_.isUndefined(o.id)
                        ? o.id
                        : o.id
                }`
              const productRender = !_.isEmpty(renderItem) ? renderItem : o
              if (
                !_.isEmpty(renderItem)
                  && renderItem.quantity === renderItem.receivedQuantity
              ) { return {} }
              return {
                id: uniq,
                value: uniq,
                name: uniq,
                disabled:
                    type !== 'inventory' && o.quantity === o.receivedQuantity,
                checked: !_.isEmpty(
                  type === 'inventory'
                    ? _.find(selectedList, (d) => d.itemId === o[key].id)
                    : _.find(
                      selectedList,
                      (d) => d.purchaseOrderId === otherData.id
                            && d.orderItemId
                              === (!_.isEmpty(renderItem)
                                ? renderItem.id
                                : o._id), //eslint-disable-line
                    ),
                ),
                onChange: (e) => {
                  if (e.target.checked) {
                    const getValue = type === 'inventory'
                      ? _.find(
                        orderItemsList,
                        (d) => d.itemId === o[key].id,
                      )
                      : _.find(
                        orderItemsList,
                        (d) => d.purchaseOrderId === otherData.id
                                && d.orderItemId
                                  === (!_.isEmpty(renderItem)
                                    ? renderItem.id
                                    : o._id), //eslint-disable-line
                            ) //eslint-disable-line
                    if (!_.isEmpty(getValue)) {
                      if (_.isNull(selectedSupplier)) {
                        const supplierObj = type === 'inventory' ? v.v.supplier : v.supplier
                        checkAndSetSupplier(
                          supplierObj,
                          type,
                          selectedList,
                          getValue,
                          v.v,
                        )
                      } else {
                        updateDataAfterSelect(
                          type,
                          selectedList,
                          getValue,
                          selectedSupplier,
                          false,
                        )
                      }
                    }
                  } else {
                    const newList = [...selectedList]
                    _.remove(newList, (d) => type === 'inventory' ? d.itemId === o[key].id : d.purchaseOrderId === otherData.id && d.orderItemId === o._id) //eslint-disable-line
                    if (
                      _.isEmpty(newList) && type === 'inventory'
                        ? _.isEmpty(selectedOrderItem)
                        : _.isEmpty(selectedInventoryItem)
                    ) {
                      setSupplierNullAndRefetch()
                    }
                    if (type === 'inventory') {
                      setSelectedInventoryItem(newList)
                    } else {
                      setSelectedOrderItem(newList)
                    }
                  }
                },
                data:
                    type === 'inventory'
                      ? [
                        {
                          title: { text: '' },
                          content: {
                            text: o[key].inventory.sku,
                            style: { width: '100px' },
                          },
                        },
                        {
                          title: { text: '' },
                          content: {
                            text: o[key].inventory
                              ? o.pack
                                ? `${o[key].inventory.productName} (${o.pack.name})`
                                : o[key].inventory.productName
                              : '-',
                            style: { width: '140px', wordBreak: 'break-all' },
                          },
                          margin: '0 16px 0 0',
                        },
                        {
                          title: { text: '' },
                          content: {
                            text: o[key].inventory?.brand || '-',
                          },
                          margin: '0 72px 0 0',
                        },
                      ]
                      : [
                        {
                          title: { text: 'รหัสสินค้า' },
                          content: {
                            text:
                                productRender.pack?.inventoryOption?.inventory
                                  ?.sku || '-',
                            style: { width: '100px', wordBreak: 'break-all' },
                          },
                        },
                        {
                          title: { text: 'ชื่อ' },
                          content: {
                            text:
                                (!_.isEmpty(renderItem)
                                  ? renderItem.variantName
                                  : o.pack?.inventoryOption?.variantName)
                                || '-',
                            style: { width: '200px', wordBreak: 'break-all' },
                          },
                          margin: '0 16px 0 0',
                        },
                        {
                          title: { text: 'ยี่ห้อ' },
                          content: {
                            text:
                                productRender.pack?.inventoryOption?.inventory
                                  ?.brand?.name || '-',
                            style: { width: '100px', wordBreak: 'break-all' },
                          },
                          margin: '0 72px 0 0',
                        },
                        {
                          title: { text: 'SO Reference' },
                          content: {
                            text:
                                !_.isUndefined(o.soNumber)
                                && !_.isEmpty(o.soNumber)
                                  ? o.soNumber
                                  : '-',
                            style: { width: '80px' },
                          },
                        },
                        {
                          title: { text: 'จำนวนสินค้า' },
                          content: { text: `${productRender.quantity} ชิ้น` },
                        },
                        {
                          title: { text: 'จำนวนที่รับแล้ว' },
                          content: {
                            text: `${
                                !_.isEmpty(renderItem)
                                  ? renderItem.receivedQuantity
                                  : o.receivedQuantity
                              } ชิ้น`,
                          },
                        },
                      ],
              }
            }
            if (!_.isEmpty(o.childOrderItems)) {
              return o.childOrderItems.map((dataItem) => objRender(dataItem))
            }
            return objRender()
          }),
        ),
    }
  }

  const queryPoData = poListsData?.purchaseOrderListBySupplier?.data
  const poList = _.compact(queryPoData?.map((v) => {
    if (
      !_.isEmpty(v.orderItemWithOutSaleOrder)
        || !_.isEmpty(v.orderItemWithSaleOrder)
    ) {
      const items = _.concat(
        v.orderItemWithOutSaleOrder,
        _.flatten(
          _.reduce(
            v.orderItemWithSaleOrder,
            (array, n) => [
              ...array,
              {
                soNumber: n.soNumber,
                orderId: n.id,
                ..._.flatten(n.items)[0],
              },
            ],
            [],
          ),
          ) //eslint-disable-line
      )
      if (!_.isEmpty(items)) {
        return rowRenderData(
          'po',
          {
            id: v.id,
            number: v.number,
            supplier: v.supplier,
            items,
          },
          {
            ..._.omit(v, [
              'orderItemWithOutSaleOrder',
              'orderItemWithSaleOrder',
            ]),
          },
        )
      }
      return null
    }
    return null
  }))

  const setNewSelectedInventoryItem = (itemId, key, value) => {
    if (props.isEdit) {
      setIsDataHasChange(true)
    }
    const newData = [...selectedInventoryItem]
    const newObj = _.find(
      [...selectedInventoryItem],
      (v) => v.itemId === itemId,
    )
    if (!_.isEmpty(newObj)) {
      newObj[key] = value
      if (key === 'isInPO') {
        newObj.purchaseOrderId = null
        newObj.orderItemId = null
      } else if (key === 'isReplaceItemInPO') {
        newObj.orderItemId = null
      }
      setSelectedInventoryItem(newData)
    }
  }

  const newCreateSelectedOrderItem = _.reduce(
    selectedOrderItem,
    (array, n) => [
      ...array,
      _.omit(n, [
        'allQuantity',
        'name',
        'purchaseOrderId',
        'receivedQuantity',
        'sku',
        'soNumber',
      ]),
    ],
    [],
  )
  const newCreateSelectedInventoryItem = _.reduce(
    selectedInventoryItem,
    (array, n) => [
      ...array,
      _.omit(n, ['name', 'sku', 'isInPO', 'isReplaceItemInPO']),
    ],
    [],
  )

  const validateCreate = () => {
    const inventories = _.isEmpty(newCreateSelectedInventoryItem)
      ? null
      : _.flatten(
        _.flatten(
          newCreateSelectedInventoryItem.map((v) => [
            v.itemId,
            v.quantity,
            v.price,
          ]),
        ),
      )
    const orderItems = _.isEmpty(newCreateSelectedOrderItem)
      ? null
      : _.flatten(
        _.flatten(
          newCreateSelectedOrderItem.map((v) => [
            v.orderItemId,
            v.quantity,
            v.newPrice,
          ]),
        ),
      )
    return _.concat(
      selectedWarehouse,
      selectedSupplier,
      _.flatten(_.compact([inventories, orderItems])),
    )
  }

  const validateUpdate = () => {
    const inventories = _.isEmpty(selectedInventoryItem)
      ? null
      : _.flatten(selectedInventoryItem.map((v) => [v.grOrderId, v.quantity]))
    const orderItems = _.isEmpty(selectedOrderItem)
      ? null
      : _.flatten(selectedOrderItem.map((v) => [v.grOrderId, v.quantity]))
    return _.flatten(_.compact([inventories, orderItems]))
  }

  const changeIsDataHasChangeState = () => {
    if (props.isEdit) {
      setIsDataHasChange(true)
    }
  }

  const groupSelectOrderItem = _.groupBy(
    selectedOrderItem,
    (value) => `${value.purchaseOrderId}#${value.productSet?.id}`,
  )

  const confirmBeforeCreate = (onOk, onCancel) => {
    Modal.confirm({
      width: 850,
      icon: (
        <Icon
          color={theme.color.warning}
          icon="fas fa-exclamation-triangle"
          style={{
            float: 'left',
            marginRight: '8px',
            fontSize: theme.fonts.size.subTitle,
          }}
        />
      ),
      title: MESSAGE.gr.create.modal.title,
      content: (
        <ShowDataWrapper>
          <P style={{ fontFamily: theme.fonts.family.normal }}>
            {MESSAGE.gr.create.modal.content}
          </P>
          {!_.isEmpty(selectedOrderItem) && (
            <Div
              margin={
                !_.isEmpty(selectedInventoryItem) ? '8px 0 16px' : '8px 0 0'
              }
            >
              <SmallText
                display="block"
                padding="16px"
                style={{ backgroundColor: theme.color.remark }}
                className="bold"
                bold
                margin="0 0 16px"
              >
                สรุปสินค้าจากใบ PO
              </SmallText>
              <ConcludeTable
                showData
                list={groupSelectOrderItem}
                isIncludeVat={isIncludeVat}
                onQuantityChange={(poId, orderId, value) => {
                  changeIsDataHasChangeState()
                  const newSelectedOrderItem = [...selectedOrderItem]
                  const findObj = _.find(
                    newSelectedOrderItem,
                    (o) => o.purchaseOrderId === poId && o.orderItemId === orderId,
                  )
                  if (!_.isEmpty(findObj)) {
                    findObj.quantity = value
                    setSelectedOrderItem(newSelectedOrderItem)
                  }
                }}
                onDelete={(poId, orderId) => {
                  const newSelectedOrderItem = [...selectedOrderItem]
                  _.remove(
                    newSelectedOrderItem,
                    (o) => o.purchaseOrderId === poId && o.orderItemId === orderId,
                  )
                  setSelectedOrderItem(newSelectedOrderItem)
                }}
              />
            </Div>
          )}
          {!_.isEmpty(selectedInventoryItem) && (
            <Div
              margin={!_.isEmpty(selectedOrderItem) ? '24px 0 0' : '8px 0 0'}
            >
              <SmallText
                display="block"
                padding="16px"
                style={{ backgroundColor: theme.color.remark }}
                className="bold"
                bold
                margin="0 0 16px"
              >
                สรุปสินค้าจาก Inventory
              </SmallText>
              <ConcludeTableInventory
                showData
                list={selectedInventoryItem}
                poListBySupplier={queryPoData}
                onChangeIsReplaceItem={(itemId, value) => setNewSelectedInventoryItem(
                  itemId,
                  'isReplaceItemInPO',
                  value,
                )
                }
                onChangeIsInPo={(itemId, value) => setNewSelectedInventoryItem(itemId, 'isInPO', value)
                }
                onPoRefChange={(itemId, value) => setNewSelectedInventoryItem(
                  itemId,
                  'purchaseOrderId',
                  parseInt(value),
                )
                }
                onReplaceItemChange={(itemId, value) => setNewSelectedInventoryItem(
                  itemId,
                  'orderItemId',
                  parseInt(value),
                )
                }
                onQuantityChange={(itemId, value) => setNewSelectedInventoryItem(itemId, 'quantity', value)
                }
                onPriceChange={(itemId, value) => setNewSelectedInventoryItem(itemId, 'price', value)
                }
                onDiscountChange={(itemId, value) => setNewSelectedInventoryItem(itemId, 'itemDiscount', value)
                }
              />
            </Div>
          )}
          <P
            margin="16px 0 0"
            style={{
              fontFamily: theme.fonts.family.normal,
              fontWeight: theme.fonts.style.medium,
            }}
            bold
            color={theme.color.error}
          >
            {MESSAGE.gr.create.modal.warningText}
          </P>
        </ShowDataWrapper>
      ),
      okText: 'ยืนยันรับสินค้าตามนี้',
      cancelText: 'กลับไปแก้ไข',
      onOk() {
        onOk()
        return false
      },
      onCancel() {
        onCancel()
      },
    })
  }

  const warehouseList = !warehouseLoading
    && !_.isEmpty(warehouseData)
    && !_.isEmpty(warehouseData.warehouseList)
    ? warehouseData.warehouseList.data.list.map((v) => ({
      text: v.warehouseName,
      value: v.id,
    }))
    : []

  const createGrFunc = (isDraft) => {
    const orderItemFormat = _.reduce(
      selectedOrderItem,
      (array, n) => [
        ...array,
        {
          itemDiscount: n.itemDiscount,
          orderItemId: n.orderItemId,
          quantity: parseFloat(n.quantity),
          price: parseFloat(n.newPrice),
          vatInclude: isIncludeVat,
        },
      ],
      [],
    )
    doCreateGr({
      variables: {
        refId: invoiceRef,
        warehouseId: selectedWarehouse,
        supplierId: selectedSupplier,
        orderItems: orderItemFormat,
        items: _.reduce(
          selectedInventoryItem,
          (arr, n) => [
            ...arr,
            {
              itemId: n.pack,
              quantity: parseFloat(n.quantity),
              price: n.price,
              itemDiscount: n.itemDiscount,
              purchaseOrderId: n.purchaseOrderId,
              orderItemId: n.orderItemId,
            },
          ],
          [],
        ),
        remarks: remark,
        files: fileList,
        isDraft,
      },
    }).then((resp) => {
      setDisabledButton(false)
      if (resp.data.createGoodsReceive) {
        if (resp.data.createGoodsReceive.success) {
          message.success(resp.data.createGoodsReceive.message)
          history.push(PATH.gr)
        } else {
          message.error(resp.data.createGoodsReceive.message)
        }
      }
    })
  }

  const updateGrFunc = (callback) => {
    const inventoryFormat = _.reduce(
      selectedInventoryItem,
      (array, n) => [
        ...array,
        {
          orderItemGRId: n.grOrderId,
          price: n.price,
          itemDiscount: n.itemDiscount,
          quantity: parseInt(n.quantity),
        },
      ],
      [],
    )
    const orderItemFormat = _.reduce(
      selectedOrderItem,
      (array, n) => [
        ...array,
        {
          orderItemGRId: n.grOrderId,
          price: !_.isUndefined(n.newPrice) ? parseFloat(n.newPrice) : n.price,
          quantity: parseInt(n.quantity),
        },
      ],
      [],
    )
    doUpdateGr({
      variables: {
        id: props.data.id,
        refId: invoiceRef,
        remarks: remark,
        files: _.concat(existFileList, fileList),
        paymentDueDate: dueDate,
        orderItemGR: _.concat(inventoryFormat, orderItemFormat),
      },
    }).then((resp) => {
      setDisabledButton(false)
      if (resp.data.updateGoodsReceive) {
        if (resp.data.updateGoodsReceive.success) {
          if (callback) {
            callback()
          } else {
            message.success(resp.data.updateGoodsReceive.message)
            history.push(PATH.gr)
          }
        } else {
          message.error(resp.data.updateGoodsReceive.message)
        }
      }
    })
  }

  return (
    <FullPageContainer
      title={[{ text: pageName, icon: 'fal fa-dolly-flatbed-alt' }]}
      detail={{ title: [{ text: `สร้าง ${pageName}`, icon: 'fal fa-plus' }] }}
      loading={
        props.loading || loading || nextGrNumberLoading
      }
    >
      <Div padding="0 0 24px">
        <Div
          margin="8px 0 24px"
          display="flex"
          alignItems="flex-end"
          justifyContent='flex-end'
        >
          {props.isEdit && (
            <Div margin="0 16px 0 0">
              <SmallText display="block" bold margin="0 0 4px">
                วันที่กำหนดชำระ
              </SmallText>
              <DatePicker
                value={dueDate}
                format="DD/MM/YY"
                onChange={(date) => {
                  changeIsDataHasChangeState()
                  setDueDate(date)
                }}
              />
            </Div>
          )}
          {!_.isEmpty(nextGrNumberData) && (
            <H3
              display="block"
              bold
              textAlign="right"
              color={theme.color.primaryColor}
            >
              {props.data?.number || nextGrNumberData.nextGRNumber}
            </H3>
          )}
        </Div>
        <Div display="flex" alignItems="center">
          <SelectWithLabel
            style={{ flexGrow: 1 }}
            title={{
              text: (
                <SmallText bold>
                  เลือก Supplier <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
              margin: '0 0 8px',
            }}
            select={{
              disabled: true,
              placeholder: 'คลิกเลือก supplier',
              value: _.isNull(selectedSupplier)
                ? undefined
                : selectedSupplier,
              onChange: (value) => {
                setSelectedSupplier(value)
                setSelectedInventoryItem([])
                setSelectedOrderItem([])
              },
              style: { width: '100%' },
              options: supplierListOption,
              className:
                ((_.isEmpty(selectedSupplier)
                  && !_.isNumber(selectedSupplier))
                  || _.isNaN(selectedSupplier))
                && 'state-error',
            }}
          />
        </Div>
        <Div>
          {!props.isEdit && (
            <Div margin="0 0 32px">
              <Div>
                <SmallText bold margin="32px 0 8px">
                  เลือกสินค้าจากใบ PO
                </SmallText>
                {
                  !poListsLoading && poList.length <= 0 && !isSearchRefetch
                    ? <MessagePage
                      margin="24px 0 0"
                      icon={{
                        icon: 'fal fa-times',
                        fontSize: theme.fonts.size.title,
                        color: theme.color.lightBlue,
                        margin: '0 0 16px',
                      }}
                      text={`${MESSAGE.empty.list}สินค้าจากใบ PO`}
                      color={theme.color.lightBlue}
                      bold
                    />
                    : <>
                      <Input
                        type="text"
                        width="100%"
                        prefix={<Icon icon="prefix fal fa-search" />}
                        placeholder="ใบสั่งซื้อ"
                        value={searchPo}
                        onChange={(e) => {
                          setSearchPo(e.target.value)
                          setIsSearchRefetch(true)
                          poListsRefetch({
                            id: _.isNull(selectedSupplier)
                              ? undefined
                              : selectedSupplier,
                            filterStatus: true,
                            search: e.target.value,
                          })
                        }}
                        margin="0 0 8px"
                      />
                      <Div moreStyle={{ overflow: 'auto', maxHeight: '250px' }}>
                        {poListsLoading && isEmpty(poList) && isSearchRefetch ? (
                          <Div margin="48px 0" textAlign="center">
                            <Loading size="small" />
                          </Div>
                        ) : (
                          poList.map((v, i) => (
                            <GroupRowSelect key={i} list={v} />
                          ))
                        )}
                      </Div>
                    </>
                }
              </Div>
            </Div>
          )}
          {(!_.isEmpty(selectedOrderItem) || props.isEdit) && (
            <Divider margin="32px 0" />
          )}
          <Div>
            {(!_.isEmpty(selectedOrderItem)
              || !_.isEmpty(selectedInventoryItem)) && (
              <Div
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                margin="0 0 8px"
              >
                <SelectWithLabel
                  title={{
                    text: <SmallText bold>เลือก Vat</SmallText>,
                    margin: '0 0 4px',
                  }}
                  select={{
                    placeholder: 'เลือกประเภทการชำระเงิน',
                    value: isIncludeVat,
                    onChange: (value) => setIsIncludeVat(value),
                    options: [
                      { text: 'มูลค่ารวมภาษี (VAT included)', value: true },
                      {
                        text: 'มูลค่าไม่รวมภาษี (VAT excluded)',
                        value: false,
                      },
                    ],
                  }}
                />
              </Div>
            )}
            {!_.isEmpty(groupSelectOrderItem) && (
              <Div margin="0 0 8px">
                <SmallText bold margin="0 0 16px">
                  สรุปสินค้าจากใบ PO
                </SmallText>
                <ConcludeTable
                  status={props.data?.status}
                  isEdit={props.isEdit}
                  isIncludeVat={isIncludeVat}
                  list={groupSelectOrderItem}
                  onQuantityChange={(poId, orderId, value) => {
                    changeIsDataHasChangeState()
                    const newSelectedOrderItem = [...selectedOrderItem]
                    const findObj = _.find(
                      newSelectedOrderItem,
                      (o) => o.purchaseOrderId === poId
                        && (o.grOrderId === orderId || o.orderItemId === orderId),
                    )
                    if (!_.isEmpty(findObj)) {
                      findObj.quantity = value
                      setSelectedOrderItem(newSelectedOrderItem)
                    }
                  }}
                  onDelete={(poId, orderId) => {
                    const newSelectedOrderItem = [...selectedOrderItem]
                    _.remove(
                      newSelectedOrderItem,
                      (o) => o.purchaseOrderId === poId
                        && o.orderItemId === orderId,
                    )
                    setSelectedOrderItem(newSelectedOrderItem)
                  }}
                  onNewPriceChange={(poId, orderId, value) => {
                    changeIsDataHasChangeState()
                    const newSelectedOrderItem = [...selectedOrderItem]
                    const findObj = _.find(
                      newSelectedOrderItem,
                      (o) => o.purchaseOrderId === poId
                        && (o.grOrderId === orderId || o.orderItemId === orderId),
                    )
                    if (!_.isEmpty(findObj)) {
                      findObj.newPrice = value
                      setSelectedOrderItem(newSelectedOrderItem)
                    }
                  }}
                />
              </Div>
            )}
            {!_.isEmpty(selectedInventoryItem) && (
              <Div margin={!_.isEmpty(selectedOrderItem) ? '24px 0 0' : '0'}>
                <SmallText bold margin="0 0 16px">
                  สรุปสินค้าจาก Inventory
                </SmallText>
                <ConcludeTableInventory
                  status={props.data?.status}
                  isEdit={props.isEdit}
                  list={selectedInventoryItem}
                  poListBySupplier={queryPoData}
                  onChangeIsReplaceItem={(itemId, value) => setNewSelectedInventoryItem(
                    itemId,
                    'isReplaceItemInPO',
                    value,
                  )
                  }
                  onChangeIsInPo={(itemId, value) => setNewSelectedInventoryItem(itemId, 'isInPO', value)
                  }
                  onPoRefChange={(itemId, value) => setNewSelectedInventoryItem(
                    itemId,
                    'purchaseOrderId',
                    parseInt(value),
                  )
                  }
                  onReplaceItemChange={(itemId, value) => setNewSelectedInventoryItem(
                    itemId,
                    'orderItemId',
                    parseInt(value),
                  )
                  }
                  onQuantityChange={(itemId, value) => setNewSelectedInventoryItem(itemId, 'quantity', value)
                  }
                  onPriceChange={(itemId, value) => setNewSelectedInventoryItem(itemId, 'price', value)
                  }
                  onDiscountChange={(itemId, value) => setNewSelectedInventoryItem(itemId, 'itemDiscount', value)
                  }
                  onDelete={(itemId) => {
                    const newSelectedInventoryItem = [
                      ...selectedInventoryItem,
                    ]
                    _.remove(
                      newSelectedInventoryItem,
                      (v) => v.itemId === itemId,
                    )
                    setSelectedInventoryItem(newSelectedInventoryItem)
                  }}
                />
              </Div>
            )}
          </Div>
          <Div display="flex" alignItems="center" margin="24px 0 0">
            <SelectWithLabel
              title={{
                text: (
                  <SmallText bold>
                      คลังสินค้า <Label color={theme.color.error}>*</Label>
                  </SmallText>
                ),
                margin: '0 0 4px',
              }}
              select={{
                disabled: props.isEdit,
                placeholder: 'คลิกเลือกคลังสินค้า',
                value: selectedWarehouse,
                onChange: (value) => {
                  setSelectedWarehouse(value)
                },
                style: { width: '200px' },
                options: warehouseList,
              }}
            />
          </Div>
          {(!_.isEmpty(selectedOrderItem)
            || !_.isEmpty(selectedInventoryItem)) && (
            <Div>
              <Divider margin="32px 0 16px" />
              <InputWithLabel
                title={{ text: 'เลข Invoice Reference', margin: '0 0 4px' }}
                input={{
                  value: invoiceRef,
                }}
                onChange={(e) => {
                  changeIsDataHasChangeState()
                  setInvoiceRef(e.target.value)
                }}
                margin="0 16px 16px 0"
              />
              <Div display="flex">
                <Div moreStyle={{ flexBasis: '100%' }}>
                  <TextareaWithLabel
                    style={{ width: '100%' }}
                    title={{ text: 'หมายเหตุเพิ่มเติม', margin: '0 0 8px' }}
                    textarea={{
                      rows: 3,
                      maxLength: 255,
                      style: { width: '100%' },
                      value: remark,
                      onChange: (e) => {
                        changeIsDataHasChangeState()
                        setRemark(e.target.value)
                      },
                    }}
                  />
                  <Div
                    margin="24px 0 0"
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <SmallText bold>แนบเอกสาร</SmallText>
                    <Uploader
                      isMultiple
                      buttonText="คลิกเพื่อเลือกไฟล์"
                      inverse={false}
                      icon="fal fa-paperclip"
                      onChange={(e) => {
                        changeIsDataHasChangeState()
                        setFileList(e)
                      }}
                      data={fileList}
                    />
                  </Div>
                  {!_.isEmpty(existFileList) && (
                    <Div margin="18px 0 0">
                      <SmallText bold margin="0 0 8px">
                        เอกสารที่อัพโหลดแล้ว
                      </SmallText>
                      {existFileList.map((v, i) => (
                        <Div
                          key={v.id}
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="space-between"
                          margin={
                            i !== existFileList.length - 1 && '0 0 8px 0'
                          }
                        >
                          <TextLink
                            href={v.url}
                            target="_blank"
                            style={{ width: '100%', maxWidth: '90%' }}
                          >
                            <SmallText
                              display="block"
                              style={{
                                width: '100%',
                                wordBreak: 'break-all',
                              }}
                            >
                              {v.fileName}
                            </SmallText>
                          </TextLink>
                          <Icon
                            onClick={() => {
                              changeIsDataHasChangeState()
                              const newArray = [...existFileList]
                              _.remove(newArray, (n) => n.url === v.url)
                              setExistFileList(newArray)
                            }}
                            margin="4px 0 0"
                            icon="fal fa-times"
                            cursor="pointer"
                          />
                        </Div>
                      ))}
                    </Div>
                  )}
                </Div>
              </Div>
            </Div>
          )}
          {(!_.isEmpty(selectedOrderItem)
            || !_.isEmpty(selectedInventoryItem)) && (
            <Div
              display="flex"
              alignItem="center"
              justifyContent="center"
              margin="40px 0 0"
            >
              <Popconfirm
                title={
                  props.isEdit
                    ? MESSAGE.gr.edit.cancel
                    : MESSAGE.gr.create.cancel
                }
                onConfirm={() => {
                  history.push(PATH.gr)
                }}
                okText={
                  props.isEdit
                    ? 'ยืนยันยกเลิกการแก้ไข'
                    : 'ยืนยันยกเลิกการสร้าง'
                }
                cancelText={
                  props.isEdit ? 'ไม่ยกเลิกการแก้ไข' : 'ไม่ยกเลิกการสร้าง'
                }
              >
                <Button
                  margin="0 4px"
                  disabled={disabledButton}
                  color={theme.color.error}
                  text="ยกเลิก"
                  icon="fal fa-times"
                />
              </Popconfirm>
              {props.goodsReceiveAutho?.create && (
                <DoActionButton
                  {...props}
                  disabledButton={
                    disabledButton || createGrLoading || updateGrLoading
                  }
                  validateCreate={() => validateCreate()}
                  validateUpdate={() => validateUpdate()}
                  allData={
                    isDataHasChange
                      ? _.concat(
                        props.data.id,
                        remark,
                        _.concat(existFileList, fileList),
                        dueDate,
                        invoiceRef,
                        validateUpdate(),
                      )
                      : []
                  }
                  setDisabledButton={(value) => setDisabledButton(value)}
                  selectedInventoryItem={selectedInventoryItem}
                  selectedOrderItem={selectedOrderItem}
                  confirmBeforeCreate={() => confirmBeforeCreate(
                    () => {
                      setDisabledButton(false)
                      createGrFunc(false)
                    },
                    () => {
                      setDisabledButton(false)
                    },
                  )
                  }
                  updateOnEdit={() => {
                    updateGrFunc()
                  }}
                />
              )}
              {!props.isEdit && props.goodsReceiveAutho.createDraft && (
                <Div>
                  {props.goodsReceiveAutho?.create
                    && props.goodsReceiveAutho.createDraft && (
                    <SmallText margin="0 24px">หรือ</SmallText>
                  )}
                  <DoActionButton
                    {...props}
                    disabledButton={disabledButton}
                    validateCreate={() => validateCreate()}
                    validateUpdate={() => validateUpdate()}
                    allData={
                      isDataHasChange
                        ? _.concat(
                          props.data.id,
                          remark,
                          _.concat(existFileList, fileList),
                          dueDate,
                          invoiceRef,
                          validateUpdate(),
                        )
                        : []
                    }
                    setDisabledButton={(value) => setDisabledButton(value)}
                    selectedInventoryItem={selectedInventoryItem}
                    selectedOrderItem={selectedOrderItem}
                    confirmBeforeCreate={() => confirmBeforeCreate(
                      () => {
                        setDisabledButton(false)
                        createGrFunc(true)
                      },
                      () => {
                        setDisabledButton(false)
                      },
                    )
                    }
                    isDraft
                  />
                </Div>
              )}
              {props.isEdit
                && props.data.status === GR_KEYS.draft
                && props.goodsReceiveAutho.confirmDraft && (
                <Div>
                  <SmallText margin="0 24px">หรือ</SmallText>
                  <Button
                    text="ยืนยันสร้าง"
                    icon="fal fa-check"
                    disabled={disabledButton}
                    onClick={() => {
                      setDisabledButton(true)
                      confirmBeforeCreate(
                        () => {
                          updateGrFunc(() => {
                            doActivateGr({
                              variables: { id: props.data.id },
                            }).then((resp) => {
                              setDisabledButton(false)
                              if (resp.data.activeGoodsReceive.success) {
                                message.success(
                                  resp.data.activeGoodsReceive.message,
                                )
                                history.push(PATH.gr)
                              } else {
                                message.error(
                                  resp.data.activeGoodsReceive.message,
                                )
                              }
                            })
                          })
                        },
                        () => {
                          setDisabledButton(false)
                        },
                      )
                    }}
                  />
                </Div>
              )}
            </Div>
          )}
        </Div>
      </Div>
    </FullPageContainer>
  )
}

const GrRender = (props) => {
  const [getGRDetail, grDetailResponse] = useLazyQuery(GOOD_RECEIVE_INFO)
  const editId = !_.isEmpty(props.match.params)
    ? parseInt(props.match.params.id)
    : null

  const authorization = useContext(AuthorizationContext)
  const goodsReceiveAutho = authorization.authorization?.goodsReceive || {}

  const userData = useQuery(USER, {
    variables: { id: getTokenData().userId },
    skip: !goodsReceiveAutho?.create && !goodsReceiveAutho?.createDraft,
  })

  useEffect(() => {
    if (!_.isNull(editId) && goodsReceiveAutho?.edit) {
      getGRDetail({
        variables: {
          id: editId,
        },
      })
    } else if (!goodsReceiveAutho?.create && !goodsReceiveAutho?.createDraft) {
      permissionRedirect()
    }
  }, [])

  if (
    !_.isNull(editId)
    && (grDetailResponse?.loading
      || _.isEmpty(grDetailResponse?.data?.goodsReceiveInfo?.data))
  ) {
    return (
      <Div margin="48px 0 0" textAlign="center">
        <Loading size="small" />
      </Div>
    )
  }

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <GrForm
        isEdit={!_.isNull(editId)}
        loading={!_.isNull(editId) && grDetailResponse.loading}
        data={grDetailResponse?.data?.goodsReceiveInfo?.data}
        userData={userData}
        goodsReceiveAutho={goodsReceiveAutho}
      />
    </Div>
  )
}

export default GrRender
