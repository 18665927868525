/* eslint-disable import/no-cycle */
import { LoadingOutlined, MinusOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import {
  Alert, AutoComplete, Button, Col,
  Collapse as AntCollapse, DatePicker as DatePickerAnt, message, notification, Pagination as PaginationAnt, Radio, Result, Row, Select as SelectAntd, Skeleton, Spin, TimePicker as TimePickerAnt, Tooltip, Upload,
} from 'antd'
import _, { isNil, first, omit, sortBy, sumBy, uniqBy } from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment-timezone'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import InputAddress from 'react-thailand-address-autocomplete'
import { searchAddressByZipcode } from 'thai-address-database'
import { CREATE_INVENTORY } from '../api/mutation'
import {
  BANK_LIST, FINNIC_BANKS, GET_BRANDS_SUPPLIER, GET_CATEGORIES_GROUP, GET_DELIVERY_CHANNEL, GET_GROUPS_BRAND, GET_TAX_INVOICE_DELIVERY_CHANNEL,
  RETURN_SALES_ORDER_DETAIL, SUPPLIERS,
} from '../api/query'
import noImg from '../assets/image-not-available.png'
import USER_IMAGE from '../assets/user.png'
import { BILLING_TYPE } from '../constants/enum'
import MESSAGE from '../constants/message'
import { SALE_TYPE } from '../pages/inventory/constants'
import { theme } from '../styles/_variables'
import { getLocalStorage } from '../utils/local-data-service'
import { renderAddress, validateData } from '../utils/util-services'
import * as Konsys from './common/konsys-ui'
import {
  AlertCustomized, CardListContentWrapper, CardListHeaderWrapper, CardListWrapper, CollapseContentWrapper,
  CollapseWrapper, DividerStyled, ListWrapper, ModalCustomized, RadioWrapper, RowSelectChildWrapper, SummaryCardWrapper, TabContentWrapper, TabHeaderWrapper,
  TabTitleWrapper, TabWrapper, TagWrapper,
} from './common/styled'
import { roundNumber } from '../utils'

const addressField = [
  {
    title: 'เลขที่',
    require: true,
    keyName: 'addressNo',
  },
  {
    title: 'รหัสไปรษณีย์',
    require: true,
    keyName: 'zipcode',
    isInputAddress: true,
  },
  {
    title: 'แขวง/ตำบล',
    require: true,
    keyName: 'subdistrict',
    isInputAddress: true,
  },
  {
    title: 'เขต/อำเภอ',
    require: true,
    keyName: 'district',
    isInputAddress: true,
  },
  {
    title: 'จังหวัด',
    require: true,
    margin: 0,
    keyName: 'province',
    isInputAddress: true,
  },
]

const shippingAddressField = [
  {
    title: 'ชื่อ - นามสกุล',
    require: true,
    margin: '24px 0 16px',
    keyName: 'contactName',
  },
  {
    title: 'เบอร์มือถือ',
    require: true,
    margin: '0 0 24px',
    keyName: 'contactPhoneNumber',
  },
  ...addressField,
]

const shippingAddressNotRequireField = [
  {
    title: 'ชื่อ - นามสกุล',
    require: false,
    margin: '24px 0 16px',
    keyName: 'contactName',
  },
  {
    title: 'เบอร์มือถือ',
    require: false,
    margin: '0 0 24px',
    keyName: 'contactPhoneNumber',
  },
  ...addressField.map((v) => ({ ...v, require: false })),
]

const customerShippingAddressField = [
  {
    title: 'ชื่อ - นามสกุล',
    require: false,
    margin: '24px 0 16px',
    keyName: 'contactName',
  },
  {
    title: 'เบอร์มือถือ',
    require: false,
    keyName: 'contactPhoneNumber',
  },
  ...addressField.map((v) => ({ ...v, require: false })),
]

const billingAddressNotRequireField = [
  {
    title: 'ชื่อที่ใช้ในการออกใบกำกับภาษี',
    require: false,
    margin: '24px 0 16px',
    keyName: 'contactName',
  },
  {
    title: 'เลขประจำตัวผู้เสียภาษี',
    require: false,
    margin: '0 0 24px',
    keyName: 'taxId',
  },
  ...addressField.map((v) => ({ ...v, require: false })),
]

const requireFieldText = (title, require) => (
  <Konsys.SmallText>
    {title}
    {require && (
      <Konsys.Label color={theme.color.error} margin="0 0 0 4px">
        *
      </Konsys.Label>
    )}
  </Konsys.SmallText>
)

export const NewAddress = (props) => {
  const [personType, setPersonType] = useState(props.data?.billingType)

  const getBillingField = (type) => {
    const field = [
      {
        title: 'ชื่อที่ใช้ในการออกใบกำกับภาษี',
        require: true,
        margin: '24px 0 16px',
        keyName: 'contactName',
      },
      {
        title: 'เลขประจำตัวผู้เสียภาษี',
        require: true,
        margin: '0 0 24px',
        keyName: 'taxId',
      },
    ]
    if (type === BILLING_TYPE[1]?.value) {
      return [...field, ...addressField]
    }
    return [...field, ...addressField.map((v) => ({ ...v, require: false }))]
  }

  const checkKeyName = (keyName) => {
    if (keyName === 'zipcode') return 'postcode'
    if (keyName === 'subdistrict') return 'subDistrict'
    return keyName
  }
  const fieldList = props.type === 'billing'
    ? getBillingField(personType)
    : props.type === 'customer'
      ? customerShippingAddressField
      : props.type === 'billing-not-require'
        ? billingAddressNotRequireField
        : props.type === 'shipping-not-require'
          ? shippingAddressNotRequireField
          : props.type === 'address'
            ? addressField.map((o, i) => {
              if (i === 0) {
                return { ...o, margin: '24px 0 16px' }
              }
              return o
            })
            : shippingAddressField

  return (
    <Konsys.Div
      position="relative"
      width="100%"
      padding="8px"
      borderRadius="4px"
      border={`1px solid ${theme.color.lightBlue}`}
      bgColor={theme.color.primaryColor10}
    >
      {!props.noTitle && (
        <Konsys.H4 margin="8px 0 0" bold>
          {props.type === 'billing'
            ? 'ที่อยู่ออกใบกำกับภาษี'
            : props.type === 'address'
              ? 'ที่อยู่'
              : 'ที่อยู่จัดส่ง'}{' '}
          <Konsys.P color={theme.color.error}>*</Konsys.P>
        </Konsys.H4>
      )}
      {props.type === 'billing' && (
        <Konsys.Div margin="16px 0 0">
          <Konsys.SmallText display="block" bold margin="0 0 4px">
            ประเภทบุคคล
          </Konsys.SmallText>
          <Radio.Group
            value={personType}
            onChange={(e) => {
              if (props.onChangePersonType) {
                props.onChangePersonType(e.target.value)
              }
              setPersonType(e.target.value)
            }}
          >
            {BILLING_TYPE?.map((obj, i) => (
              <Radio
                key={obj?.value}
                style={{
                  margin: i !== BILLING_TYPE.length - 1 ? '0 8px 0 0' : '0',
                }}
                value={obj?.value}
                checked={props?.data?.billingType === obj?.value}
              >
                {obj?.text}
              </Radio>
            ))}
          </Radio.Group>
        </Konsys.Div>
      )}
      {
        props.data.thirdPartyAddress
          ? <Konsys.Div display='flex' flexDirection='column'>
            <LargeTextWithLabel
              margin="0 0 16px 0"
              title={{ text: 'ชื่อ - นามสกุล' }}
              content={{ text: props.data.contactName }}
            />
            <LargeTextWithLabel
              margin="0 0 16px 0"
              title={{ text: 'เบอร์มือถือ' }}
              content={{ text: props.data.contactPhoneNumber }}
            />
            <LargeTextWithLabel
              title={{ text: 'ที่อยู่ E-Commerce' }}
              content={{ text: props.data.thirdPartyAddress || '' }}
            />
          </Konsys.Div>
          : (
            fieldList?.map((v, i) => {
              if (v?.isInputAddress) {
                return (
                  <Konsys.Div
                    key={i}
                    className={[
                      'auto-address-input',
                      v.require
                        && (_.isEmpty(props.data)
                          || _.isEmpty(props?.data[v?.keyName]))
                        && 'empty',
                    ]}
                  >
                    <Konsys.SmallText>
                      {requireFieldText(v.title, v.require)}
                    </Konsys.SmallText>
                    <InputAddress
                      placeholder={`กรอก${v.title}`}
                      address={v?.keyName}
                      value={props.data && props.data[checkKeyName(v?.keyName)]}
                      onChange={(e) => {
                        if (props.onChange) {
                          props.onChange(checkKeyName(v?.keyName), e.target.value)
                        }
                      }}
                      onSelect={(val) => props.onSelect(val)}
                    />
                  </Konsys.Div>
                )
              }
              return (
                <Konsys.Div
                  key={i}
                  width={v.width || '100%'}
                  margin={v.margin || '0 0 16px'}
                >
                  <Konsys.SmallText>
                    {requireFieldText(v.title, v.require)}
                  </Konsys.SmallText>
                  <Konsys.Input
                    width="100%"
                    onChange={(e) => {
                      if (
                        v.keyName !== 'taxId'
                        || (v.keyName === 'taxId' && e.target.value.length <= 13)
                      ) {
                        if (props.onChange) {
                          props.onChange(v?.keyName, e.target.value)
                        }
                      }
                    }}
                    rule={v.keyName === 'taxId' && { type: 'int' }}
                    placeholder={`กรอก${v.title}`}
                    value={!_.isEmpty(props?.data) ? props?.data[v.keyName] : null}
                    state={
                      (v.require
                        && (_.isEmpty(props.data)
                          || (!_.isEmpty(props.data)
                            && _.isEmpty(props.data[v.keyName]))))
                      || (v.keyName === 'taxId' && props?.data[v.keyName]?.length < 13)
                        ? 'error'
                        : 'default'
                    }
                  />
                </Konsys.Div>
              )
            })
          )
      }
    </Konsys.Div>
  )
}

export const TextWithIcon = (props) => (
  <Konsys.Div {...props}>
    {!_.isEmpty(props.link) ? (
      props.type === 'a' ? (
        <Konsys.TextLink href={props.link} color={props.color}>
          {props.icon && <Konsys.Icon icon={props.icon} margin="0 8px 0 0" />}
          {props.text}
        </Konsys.TextLink>
      ) : (
        <Konsys.ReactRouterLink to={props.link} color={props.color}>
          {props.icon && <Konsys.Icon icon={props.icon} margin="0 8px 0 0" />}
          {props.text}
        </Konsys.ReactRouterLink>
      )
    ) : (
      <Konsys.P bold={props.bold} color={props.color}>
        {props.icon && (
          <Konsys.Icon
            icon={props.icon}
            margin="0 8px 0 0"
            color={props.color}
          />
        )}
        {props.text}
      </Konsys.P>
    )}
  </Konsys.Div>
)

export const TextWithIconSmall = (props) => (
  <Konsys.Div {...props}>
    {!_.isEmpty(props.link) ? (
      props.type === 'a' ? (
        <Konsys.SmallTextLink href={props.link} color={props.color}>
          {props.icon && (
            <Konsys.Icon
              fontSize={theme.fonts.size.label}
              icon={props.icon}
              margin="0 8px 0 0"
            />
          )}
          {props.text}
        </Konsys.SmallTextLink>
      ) : (
        <Konsys.SmallReactRouterLink to={props.link} color={props.color}>
          {props.icon && (
            <Konsys.Icon
              fontSize={theme.fonts.size.label}
              icon={props.icon}
              margin="0 8px 0 0"
            />
          )}
          {props.text}
        </Konsys.SmallReactRouterLink>
      )
    ) : (
      <Konsys.SmallText bold={props.bold} color={props.color}>
        {props.icon && (
          <Konsys.Icon
            fontSize={theme.fonts.size.label}
            icon={props.icon}
            margin="0 8px 0 0"
            color={props.color}
          />
        )}
        {props.text}
      </Konsys.SmallText>
    )}
  </Konsys.Div>
)

export const TextBetween = (props) => (
  <Konsys.Div
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    margin={props.margin}
  >
    <TextWithIcon {...props.title}>{props.title.text}</TextWithIcon>
    <TextWithIcon textAlign="right" {...props.content} />
  </Konsys.Div>
)

export const Tag = (props) => (
  <TagWrapper {...props}>
    <Konsys.SmallText color={theme.color.gray70}>
      {props.text}
    </Konsys.SmallText>
  </TagWrapper>
)

export const Divider = (props) => <DividerStyled {...props} />

export const ComponentWithLabelLarge = (props) => (
  <Konsys.Div display="inline-block" {...props}>
    <Konsys.SmallText bold display="block" {...props.title}>
      {!_.isUndefined(props.title?.icon) && (
        <Konsys.Icon margin="0 8px 0 0" icon={props.title?.icon} />
      )}
      {props.title?.text}
    </Konsys.SmallText>
    {props.children}
  </Konsys.Div>
)

export const ComponentWithLabelSmall = (props) => (
  <Konsys.Div display="inline-block" {...props}>
    <Konsys.Label bold display="block" {...props.title}>
      {(!_.isEmpty(props.title.icon) || !_.isUndefined(props.title.icon)) && (
        <Konsys.Icon
          margin="0 6px 0 0"
          fontSize="10px"
          icon={props.title.icon}
        />
      )}
      {props.title.text}
    </Konsys.Label>
    {props.children}
  </Konsys.Div>
)

export const LargeTextWithLabel = (props) => (
  <ComponentWithLabelLarge {...props}>
    {_.isArray(props.content) ? (
      props.content.map((v, i) => (
        <TextWithIcon key={i} {...v}>
          {v.text}
        </TextWithIcon>
      ))
    ) : (
      <TextWithIcon {...props.content}>{props.content.text}</TextWithIcon>
    )}
  </ComponentWithLabelLarge>
)

export const SmallTextWithLabel = (props) => (
  <ComponentWithLabelSmall {...props}>
    <TextWithIconSmall {...props.content}>
      {props.content.text}
    </TextWithIconSmall>
  </ComponentWithLabelSmall>
)

export const TextareaWithLabel = (props) => (
  <ComponentWithLabelLarge {...props}>
    <Konsys.Input as="textarea" rows={5} margin="8px 0 0" {...props.textarea} />
  </ComponentWithLabelLarge>
)

export const InputWithLabel = (props) => (
  <ComponentWithLabelLarge margin="8px 0" {...props}>
    <Konsys.Input
      {...props.input}
      state="default"
      onChange={(e) => props.onChange && props.onChange(e)}
    />
  </ComponentWithLabelLarge>
)

export const AutoCompleteWithLabel = (props) => (
  <ComponentWithLabelLarge margin="8px 0" {...props}>
    <SelectAntd {...props.select} showSearch={true}>
      {!_.isEmpty(props.select.options)
        && props.select.options.map((v, i) => (
          <SelectAntd.Option key={i} value={v.value} {...v}>
            {v.text}
          </SelectAntd.Option>
        ))}
    </SelectAntd>
  </ComponentWithLabelLarge>
)

export const DateWithLabel = (props) => (
  <ComponentWithLabelLarge {...props}>
    <Konsys.Input
      margin="8px 0 0"
      rule={{
        type: 'date',
      }}
      {...props.input}
    />
  </ComponentWithLabelLarge>
)

export const Select = (props) => (
  <SelectAntd {...omit(props, 'options')}>
    {!_.isEmpty(props.options)
      && props.options.map((v, i) => (
        <SelectAntd.Option key={i} value={v?.value} {...v}>
          {props.tooltip ? (
            <Tooltip title={v?.text}>{v?.text}</Tooltip>
          ) : (
            v?.text
          )}
        </SelectAntd.Option>
      ))}
  </SelectAntd>
)

export const SelectWithLabel = (props) => (
  <ComponentWithLabelLarge {...props}>
    <Select
      {...props.select}
      style={{ width: 200, ...props.select.style }}
      options={props.select.options}
    />
  </ComponentWithLabelLarge>
)

export const SummaryCard = (props) => (
  <SummaryCardWrapper>
    <TextWithIconSmall color={theme.color.gray70} bold {...props.title}>
      {props.title.text}
    </TextWithIconSmall>
    <Konsys.H3 margin="24px 0 8px -2px" bold>
      {props.content}
    </Konsys.H3>
    <Konsys.Div display="flex" alignItems="center">
      <TextWithIconSmall
        text={props.subContent.percent}
        icon={
          props.subContent.type === 'up'
            ? 'fal fa-arrow-up'
            : 'fal fa-arrow-down'
        }
        bold
        color={
          props.subContent.type === 'up'
            ? theme.color.success
            : theme.color.error
        }
      />
      <TextWithIconSmall
        margin="0 0 0 8px"
        text={props.subContent.text}
        icon={props.subContent.icon}
      />
    </Konsys.Div>
  </SummaryCardWrapper>
)

export const Collapse = (props) => {
  const [isOpen, setIsOpen] = useState(props.open)
  return (
    <CollapseWrapper
      open={isOpen}
      fullHeight={props.fullHeight}
      minHeight={props.minHeight}
      {..._.omit(props, ['onClick'])}
    >
      <Konsys.Div
        padding="0 0 6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        moreStyle={{ borderBottom: `1px solid ${theme.color.primaryColor10}` }}
        onClick={() => {
          setIsOpen(!isOpen)
          if (props.onClick) {
            props.onClick()
          }
        }}
      >
        {!_.isObject(props.text) ? (
          <TextWithIcon text={props.text} bold />
        ) : (
          props.text.render
        )}
        <Konsys.Icon icon="fal fa-chevron-up" color={theme.color.lightBlue} />
      </Konsys.Div>
      <CollapseContentWrapper>{props.children}</CollapseContentWrapper>
    </CollapseWrapper>
  )
}

/* =============== List ================ */
export const CardList = (props) => (
  <CardListWrapper
    newCard={props?.newCard}
    active={props.active}
    onClick={() => props.onClick()}
  >
    <CardListHeaderWrapper>
      <Konsys.Div display="flex" alignItems="center" style={props.titleStyle}>
        <Konsys.SmallText bold>{props.title}</Konsys.SmallText>
        <Konsys.Label color={theme.color.gray50} margin="0 0 0 4px">
          {props.label}
        </Konsys.Label>
      </Konsys.Div>
      {props.status ? (
        <Konsys.SmallText bold textAlign="right" color={props.status.color}>
          {props.status.text}
        </Konsys.SmallText>
      ) : props.right}
    </CardListHeaderWrapper>
    {!_.isEmpty(props.content) && (
      <CardListContentWrapper fullWidth={_.isEmpty(props.content.three)}>
        <Konsys.Div>
          {!_.isUndefined(props.content.one) && (
            <LargeTextWithLabel margin="0 16px 0 0" {...props.content.one} />
          )}
          {!_.isUndefined(props.content.two) && (
            <LargeTextWithLabel {...props.content.two} />
          )}
        </Konsys.Div>
        {!_.isUndefined(props.content.three) && (
          <LargeTextWithLabel {...props.content.three} />
        )}
      </CardListContentWrapper>
    )}
  </CardListWrapper>
)

const TabTitle = (props) => (
  <TabTitleWrapper active={props.active}>
    <TextWithIconSmall
      link={props.link}
      color={props.active ? theme.color.offWhite : theme.color.gray80}
      text={props.text}
      icon={props.icon}
      onClick={() => props.onClick()}
    />
  </TabTitleWrapper>
)

export const Tab = (props) => {
  const [currentTab, setCurrentTab] = useState(!props.noDefaultTab && 0)

  useEffect(() => {
    if (_.isNumber(props.current)) {
      setCurrentTab(props.current)
    }
  }, [props])

  return (
    <TabWrapper white={props.white}>
      <TabHeaderWrapper>
        <Konsys.Div display="flex">
          {!_.isUndefined(props.title)
            && !_.isEmpty(props.title)
            && props.title.map((v, i) => (
              <TabTitle
                link={v.link}
                active={currentTab === i}
                key={i}
                text={v.text}
                icon={v.icon}
                onClick={() => {
                  setCurrentTab(i)
                  if (props.onChangeTab) props.onChangeTab(i)
                }}
              />
            ))}
          {props.tabLoading && (
            <Loading size="extraSmall" margin="0 0 0 16px" />
          )}
        </Konsys.Div>
        <Konsys.Div>
          <Konsys.Div
            margin="0 16px 0 0"
            display="flex"
            height="100%"
            alignItems="center"
            moreStyle={{ flexWrap: 'wrap' }}
            justifyContent="flex-end"
          >
            {!_.isUndefined(props.buttonList)
              && !_.isEmpty(props.buttonList)
              && props.buttonList.map((v) => v)}
          </Konsys.Div>
        </Konsys.Div>
      </TabHeaderWrapper>
      <TabContentWrapper
        white={props.white}
        contentHeight={props.contentHeight}
        filterHeight={props.filterHeight}
        fullHeight={props.fullHeight}
      >
        {props.loading ? (
          <Konsys.Div>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </Konsys.Div>
        ) : _.isArray(props.children) ? (
          _.filter(props.children, (child) => !_.isNil(child))[currentTab]
        ) : (
          props.children
        )}
      </TabContentWrapper>
    </TabWrapper>
  )
}

export const FullPageContainer = (props) => (
  <Tab fullHeight title={props.title} buttonList={props.buttonList}>
    <Tab
      white
      loading={props.loading}
      title={props.detail.title}
      buttonList={props.detail.buttonList}
      current={props.detail.current}
    >
      {props.children}
    </Tab>
  </Tab>
)

export const CardListInTab = (props) => {
  const detectScroll = (e) => {
    if (_.ceil(e.target.offsetHeight + e.target.scrollTop) >= e.target.scrollHeight) {
      props.onInfiniteScroll()
    }
  }

  return (
    <ListWrapper
      onScroll={(e) => detectScroll(e)}
      bgColor={!props.loading && 'white'}
      margin="0 24px 0 0"
      height="100%"
      moreStyle={{
        overflow: 'auto',
        overflowAnchor: 'auto',
      }}
    >
      {!_.isEmpty(props.list)
        && props.list.map((v, i) => (
          <CardList
            active={props.currentCard === i}
            key={i}
            {..._.omit(v, ['detail'])}
            onClick={() => props.onClick(i)}
          />
        ))}
    </ListWrapper>
  )
}

export const ListWithoutTab = (props) => {
  const { currentCard, setCurrentCard } = props
  return (
    <Konsys.Div height="100%">
      {props.filter}
      {props.fetchMoreLoading && (
        <Loading style={{ display: 'block' }} margin="16px auto" />
      )}
      <Konsys.Div
        display="flex"
        alignItems="flex-start"
        height={`calc(100% - ${props.filterHeight || 58}px)`}
      >
        <Konsys.Div
          height="100%"
          moreStyle={{
            overflow: 'auto',
            flexBasis:
              _.isEmpty(props.list) || props.listLoading ? '100%' : '60%',
            maxWidth:
              _.isEmpty(props.list) || props.listLoading ? '100%' : '440px',
          }}
        >
          {props.listLoading ? (
            <Konsys.Div>
              <Skeleton active />
              <Skeleton active />
            </Konsys.Div>
          ) : _.isEmpty(props.list) ? (
            <Konsys.Div
              height={`calc(100% - ${props.filterHeight || 58}px)`}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Konsys.P color={theme.color.lightBlue}>
                {MESSAGE.empty.list}
              </Konsys.P>
            </Konsys.Div>
          ) : (
            <CardListInTab
              filterHeight={props.filterHeight || 58}
              list={props.list}
              currentCard={currentCard}
              onClick={(i) => setCurrentCard(i)}
              onInfiniteScroll={() => props.onInfiniteScroll()}
            />
          )}
        </Konsys.Div>
        {!props.listLoading && !_.isEmpty(props.list) && (
          <Tab
            tabLoading={props.detailTabLoading}
            filterHeight={props.filterHeight || 58}
            white
            title={
              !_.isEmpty(props.list)
              && props.list[currentCard]
              && props.list[currentCard].detail
              && props.list[currentCard].detail.title
            }
            buttonList={
              !_.isEmpty(props.list)
              && props.list[currentCard]
              && props.list[currentCard].detail
              && props.list[currentCard].detail.buttonList
            }
          >
            {props.list[currentCard]
              && props.list[currentCard].detail
              && props.list[currentCard].detail.content}
          </Tab>
        )}
      </Konsys.Div>
    </Konsys.Div>
  )
}

export const ListContainer = (props) => {
  const [currentCard, setCurrentCard] = useState(props?.currentCard || 0)

  useEffect(() => {
    setCurrentCard(props?.currentCard || 0)
  }, [props?.currentCard])

  return (
    <Tab
      fullHeight
      loading={props.loading}
      title={props.title}
      buttonList={props.buttonList}
      tabLoading={props.tabLoading}
    >
      <ListWithoutTab
        {...props}
        currentCard={currentCard}
        setCurrentCard={(value) => {
          setCurrentCard(value)
          if (props?.setCurrentCard) props.setCurrentCard(value)
        }}
      />
      {props.children}
    </Tab>
  )
}
/* =============== end List ================ */

/* =============== debt ================ */
const DebtItemChange = (props) => (
  <Konsys.Div margin="8px 0 16px">
    <Konsys.SmallText bold display="block" margin="0 0 8px">
      สินค้าที่มีการเปลี่ยนแปลง
    </Konsys.SmallText>
    <TableList
      data={props.itemChanges.map((v) => {
        if (
          _.isEmpty(v.orderItem)
          || (!_.isNumber(v.orderItem.id) && _.isEmpty(v.orderItem.id))
        ) {
          return [
            { text: v.remarks },
            { text: 0, textAlign: 'center' },
            { text: 0, textAlign: 'center' },
            { text: props.deliveryCost || 0, textAlign: 'center' },
            {
              text:
                props.type === 'DN'
                  ? props.deliveryCost + v.price
                  : props.deliveryCost - v.price,
              textAlign: 'center',
            },
          ]
        }
        return [
          {
            text:
              (v.orderItem?.type === 'SET'
                ? v.orderItem?.productSet?.name
                : v.orderItem?.variantName) || '-',
          },
          { text: v.orderItem.quantity, textAlign: 'center' },
          { text: v.quantity || 0, textAlign: 'center' },
          {
            text: numeral(v.orderItem.salesPrice).format('0,0.00'),
            textAlign: 'center',
          },
          {
            text: numeral(
              props.type === 'DN'
                ? v.orderItem.salesPrice + v.price
                : v.orderItem.salesPrice - v.price,
            ).format('0,0.00'),
            textAlign: 'center',
          },
        ]
      })}
      headerData={[
        {
          text: 'สินค้า',
        },
        {
          text: (
            <Konsys.Div>
              <Konsys.SmallText textAlign="center" bold display="block">
                จำนวน
              </Konsys.SmallText>
              <Konsys.SmallText textAlign="center" bold display="block">
                ก่อนเปลี่ยน
              </Konsys.SmallText>
            </Konsys.Div>
          ),
          textAlign: 'center',
        },
        {
          text: (
            <Konsys.Div>
              <Konsys.SmallText textAlign="center" bold display="block">
                จำนวน
              </Konsys.SmallText>
              <Konsys.SmallText textAlign="center" bold display="block">
                หลังเปลี่ยน
              </Konsys.SmallText>
            </Konsys.Div>
          ),
          textAlign: 'center',
        },
        {
          text: (
            <Konsys.Div>
              <Konsys.SmallText textAlign="center" bold display="block">
                ราคา/หน่วย
              </Konsys.SmallText>
              <Konsys.SmallText textAlign="center" bold display="block">
                ก่อนเปลี่ยน
              </Konsys.SmallText>
            </Konsys.Div>
          ),
          textAlign: 'center',
        },
        {
          text: (
            <Konsys.Div>
              <Konsys.SmallText textAlign="center" bold display="block">
                ราคา/หน่วย
              </Konsys.SmallText>
              <Konsys.SmallText textAlign="center" bold display="block">
                หลังเปลี่ยน
              </Konsys.SmallText>
            </Konsys.Div>
          ),
          textAlign: 'center',
        },
      ]}
      columnStyled={{
        0: { width: '150px' },
        1: { width: '100px' },
        2: { width: '100px' },
        3: { width: '100px' },
        4: { width: '100px' },
      }}
    />
  </Konsys.Div>
)
export const DebtDetail = (props) => {
  const { data: returnSO, loading } = useQuery(RETURN_SALES_ORDER_DETAIL, {
    variables: { receiptId: props.receiptId },
  })
  if (loading) {
    return (
      <Konsys.Div width="100%">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Konsys.Div>
    )
  }
  const data = returnSO?.returnSalesOrderDetail?.data
  return (
    <Konsys.Div width="100%">
      <Konsys.Div>
        <LargeTextWithLabel
          margin="0 16px 0 0"
          title={{ text: 'เลขใบ' }}
          content={{ text: props.number, bold: true }}
        />
        <LargeTextWithLabel
          margin="0 40px 0 0"
          title={{ text: 'วันที่ออกใบ' }}
          content={{ text: dayjs(data.returnDate).format('DD/MM/YYYY') }}
        />
        <LargeTextWithLabel
          title={{ text: 'ประเภทการจ่ายเงิน' }}
          content={{ text: first(data.orderItems)?.lot?.salesOrder?.isCOD ? 'ชำระเงินปลายทาง' : 'โอนเงิน' }}
        />
      </Konsys.Div>
      <Divider />
      <Konsys.Div
        display="flex"
        margin="16px 0 0"
        flexDirection={props.block ? 'column' : 'row'}
      >
        <Konsys.P bold margin='0 0 8px 0'>สินค้าที่ชำรุด</Konsys.P>
        <Row gutter={16}>
          <Col span={12}>
            <Konsys.P margin='0 16px 0 0' width='300px'>ชื่อสินค้า</Konsys.P>
          </Col>
          <Col span={6}>
            <Konsys.P>จำนวน</Konsys.P>
          </Col>
        </Row>
        {
          _.filter(data?.orderItems, (order) => order.brokenQuantity > 0).map((v) => (
            <Row key={v.id} gutter={16}>
              <Col span={12}>
                <Konsys.P margin='0 16px 0 0' width='300px'>{v?.pack?.inventoryOption?.variantName || v?.productSet?.name}</Konsys.P>
              </Col>
              <Col span={6}>
                <Konsys.P>{numeral(v.brokenQuantity).format('0,0')} ชิ้น</Konsys.P>
              </Col>
            </Row>
          ))
        }
        <Divider />
        {
          data?.isDeliveryAndBoxCount
            ? <Konsys.P bold color={theme.color.error}>หักค่าส่งและค่ากล่อง (แอดมิน: {first(data.orderItems)?.seller?.firstName} {first(data.orderItems)?.seller?.lastName})</Konsys.P>
            : <Konsys.P bold color={theme.color.info}>ไม่หักค่าส่งและค่ากล่อง</Konsys.P>
        }
        <Konsys.Div
          bgColor={theme.color.remark}
          padding="16px"
          margin={'16px 0'}
          moreStyle={{ flexBasis: props.block ? '100%' : '55%' }}
        >
          <LargeTextWithLabel
            title={{ text: 'หมายเหตุ' }}
            content={{ text: data?.remarks || '-' }}
          />
        </Konsys.Div>
        {
          data.returnFileUrl
          && <img
            style={{ width: '100%', maxWidth: '200px' }}
            src={data.returnFileUrl}
          />
        }
      </Konsys.Div>
    </Konsys.Div>
  )
}

export const DebtList = (props) => (
  <Collapse
    style={{ margin: props.margin }}
    open={props.isOpen}
    text={props.title}
    onClick={() => props.setIsOpen(!props.isOpen)}
    fullHeight={props.fullHeight}
  >
    <Konsys.Div>
      {_.isEmpty(props.list) ? (
        props.list.map((v, i) => (
          <Konsys.Div
            key={i}
            display="flex"
            margin={i !== v.length && '0 0 24px'}
          >
            <Konsys.P margin="0 8px 0 0">{i + 1}.</Konsys.P>
            <DebtDetail block={props.block} data={v} />
          </Konsys.Div>
        ))
      ) : (
        <MessagePage
          margin="16px 0"
          icon={{
            icon: 'fal fa-times',
            fontSize: theme.fonts.size.title,
            color: theme.color.lightBlue,
            margin: '0 0 16px',
          }}
          text={MESSAGE.empty.list}
          color={theme.color.lightBlue}
          bold
        />
      )}
    </Konsys.Div>
  </Collapse>
)
/* =============== end debt ================ */

export const SummaryPrice = (props) => {
  const [discount, setDiscount] = useState(0)
  const [discountType, setDiscountType] = useState(0)
  const [customDiscount, setCustomDiscount] = useState(0)
  const [vat, setVat] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [finalDiscount, setFinalDiscount] = useState(0)
  useEffect(() => {
    if (props.data) {
      if (props.data.vat) {
        setVat(props.data.vat.value || 0)
        if (props.data.shipping) {
          setShipping(props.data.shipping.value || 0)
        }
      }
      if (props.data.shipping) {
        setShipping(props.data.shipping.value || 0)
      }
      if (props.data.discount) {
        if (props.data.discount.type === 'PERCENT') {
          setDiscount(props.data.discount.value || 0)
        } else {
          setCustomDiscount(props.data.discount.value || 0)
        }
        setDiscountType(props.data.discount.type)
      }
      if (props.finalDiscount) {
        setFinalDiscount(props.finalDiscount.value)
      }
    }
  }, [props])

  const total = props.data.total || 0
  const totalCost = props.data.totalCost || 0
  const totalProfit = props.data.totalProfit || 0
  const totalWithShipping = parseFloat(total)
    + parseFloat(shipping)
    + parseFloat(props.excludeVat ? vat : 0)
  const discountValue = props.data
    && props.data.discount
    && !_.isUndefined(props.data.discount.type)
    && discountType !== 'PERCENT'
    ? props.data.discount.value
    : totalWithShipping * (discount / 100)
  const totalAfterDiscount = totalWithShipping - (discount > 0 ? discountValue : customDiscount)
  const totalSummary = totalAfterDiscount

  const commissions = sortBy(uniqBy(
    first(props.data?.lots)?.items, (o) => o.seller?.id,
  ), (o) => o.isUpsellItem).map((seller) => (
    {
      id: seller.seller?.id,
      name: seller.seller ? `${seller.seller?.firstName} ${seller.seller?.lastName}` : getLocalStorage('fullname'),
      commission: _.sum(
        _.filter(
          first(props.data?.lots)?.items, (i) => (i.seller?.id === seller.seller?.id && !i.isFree),
        )?.map((item) => ((item?.salesPrice - (item?.discount || 0)) * item?.commission * 0.01) * item?.quantity),
      ),
    }
  ))

  return (
    <Konsys.Div margin={props.margin}>
      {
        (!props.po && props.so && props.showCost)
        && <TextBetween
          margin="0 0 8px"
          title={{ text: 'ต้นทุนทั้งหมด' }}
          content={{ text: `${numeral(totalCost).format('0,0.00')} บาท` }}
        />
      }
      {
        (!props.po && props.so && props.showCost)
        && <TextBetween
          margin="0 0 8px"
          title={{ text: 'กำไรทั้งหมด' }}
          content={{ text: `${numeral(roundNumber(totalProfit - sumBy(commissions, 'commission') - discountValue - (props?.finalDiscount?.disabled ? first(props.data?.lots).discount : finalDiscount) - (props.bonusCommissionAdmin || 0) - (props.bonusCommissionUpsell || 0))).format('0,0.00')} บาท` }}
        />
      }
      <TextBetween
        margin="0 0 8px"
        title={{
          text: `ยอดขาย Admin: ${props?.data?.mutationVariables?.seller?.firstName ?? ''} ${props?.data?.mutationVariables?.seller?.lastName ?? ''}`,
        }}
        content={{
          text: `${numeral(
            _.sum(_.filter(first(props.data?.lots)?.items, (i) => !i.isUpsellItem)?.map((item) => item?.salesPrice * item?.quantity)),
          ).format('0,0.00')} บาท`,
        }}
      />
      {
        _.find(first(props.data?.lots)?.items, (i) => i.isUpsellItem)
        && <TextBetween
          margin="0 0 8px"
          title={{
            text: `ยอดขาย Upsell: ${_.find(first(props.data?.lots)?.items, (i) => i.isUpsellItem).seller ? `${_.find(first(props.data?.lots)?.items, (i) => i.isUpsellItem).seller?.firstName} ${_.find(first(props.data?.lots)?.items, (i) => i.isUpsellItem).seller?.lastName}` : getLocalStorage('fullname')}`,
          }}
          content={{
            text: `${numeral(
              _.sum(_.filter(first(props.data?.lots)?.items, (i) => i.isUpsellItem)?.map((item) => item?.salesPrice * item?.quantity)),
            ).format('0,0.00')} บาท`,
          }}
        />
      }
      {
        (!props.po && props.so && props.showCost)
        && commissions.map((v, i) => (
          <TextBetween
            key={v.id}
            margin="0 0 8px"
            title={{
              text: `ค่าคอมฯทั้งหมด (${i === 0 ? 'Admin: ' : 'Upsell: '}${
              i === 0
                ? `${props?.data?.mutationVariables?.seller?.firstName ?? ''} ${props?.data?.mutationVariables?.seller?.lastName ?? ''}`
                : v.name
            })`,
            }}
            content={{
              text: `${numeral(v.commission).format('0,0.00')} บาท`,
            }}
          />
        ))
      }
      {/* {
        (!props.po && props.so && props.showCost)
        && (
          <TextBetween
            margin="0 0 8px"
            title={{
              text: `ค่าคอมฯโบนัส (Admin: ${props?.data?.mutationVariables?.seller?.firstName ?? ''} ${props?.data?.mutationVariables?.seller?.lastName ?? ''})`,
            }}
            content={{
              text: `${numeral(props.bonusCommissionAdmin).format('0,0.00')} บาท`,
            }}
          />
        )
      }
      {
        (!props.po && props.so && props.showCost && !_.isEmpty(_.filter(first(props.data?.lots)?.items, (item) => item.isUpsellItem)))
        && (
          <TextBetween
            margin="0 0 8px"
            title={{
              text: `ค่าคอมฯโบนัส (Upsell: ${commissions[1] ? commissions[1]?.name : commissions[0]?.name})`,
            }}
            content={{
              text: `${numeral(props.bonusCommissionUpsell).format('0,0.00')} บาท`,
            }}
          />
        )
      } */}
      {
        !props.po
        && <TextBetween
          margin="0 0 8px"
          title={{ text: 'ยอดรวมทั้งหมด' }}
          content={{ text: `${numeral(total).format('0,0.00')} บาท` }}
        />
      }
      {!props.po && !_.isUndefined(props.data.vat) && (
        <TextBetween
          margin="0 0 8px"
          title={{ text: 'ภาษีมูลค่าเพิ่ม' }}
          content={{ text: `${numeral(vat).format('0,0.00')} บาท` }}
        />
      )}
      {props.data && props.isWithShipping && (
        <Konsys.Div>
          <Konsys.Div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            margin="0 0 8px"
          >
            <TextWithIcon text="ค่าขนส่ง" />
            {props.input ? (
              <Konsys.Div display="flex" alignItems="center">
                {props.data.shipping && props.data.shipping.disabled ? (
                  <Konsys.P margin="0 0 0 8px">{shipping} บาท</Konsys.P>
                ) : (
                  <Konsys.Div>
                    <Konsys.Input
                      width="150px"
                      type="text"
                      rule={{ type: 'float' }}
                      state="default"
                      value={shipping}
                      onChange={(e) => {
                        setShipping(e.target.value)
                        if (
                          props.data.shipping
                          && props.data.shipping.customOnChange
                        ) {
                          props.data.shipping.customOnChange(e.target.value)
                        }
                      }}
                    />
                    <Konsys.P margin="0 0 0 8px">บาท</Konsys.P>
                  </Konsys.Div>
                )}
              </Konsys.Div>
            ) : (
              <TextWithIcon
                textAlign="right"
                text={`${numeral(shipping).format('0,0.00')} บาท`}
              />
            )}
          </Konsys.Div>
          <TextBetween
            margin="0 0 8px"
            title={{ text: 'ยอดรวมค่าขนส่ง' }}
            content={{
              text: `${numeral(
                parseFloat(total)
                  + parseFloat(shipping)
                  + parseFloat(props.excludeVat ? vat : 0),
              ).format('0,0.00')} บาท`,
            }}
          />
        </Konsys.Div>
      )}
      {props.data && props.data.discount && (
        <Konsys.Div
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 8px"
        >
          {props.input ? (
            <Konsys.Div display="flex" alignItems="center">
              <TextWithIcon text="ส่วนลด" />
              {!props.data.discount.hidePercentInput && (
                <Konsys.Input
                  disabled={customDiscount > 0 || props.data.discount.disabled}
                  type="text"
                  width="80px"
                  margin="0 0 0 8px"
                  suffix={<Konsys.Icon icon="suffix fal fa-percent" />}
                  value={`${discount}`}
                  onChange={(e) => {
                    setDiscount(e.target.value)
                    setDiscountType('Percent')
                    if (props.data.discount.onChange) {
                      props.data.discount.onChange(e.target.value)
                    }
                  }}
                />
              )}
            </Konsys.Div>
          ) : (
            <TextWithIcon
              text={
                props.data.discount.type !== 'Percent'
                  ? 'ส่วนลด'
                  : `ส่วนลด ${discount}%`
              }
            />
          )}
          {props.input ? (
            <Konsys.Div display="flex" alignItems="center">
              <Konsys.P margin="0 8px 0 0">-</Konsys.P>
              <Konsys.Input
                disabled={discount > 0 || props.data.discount.disabled}
                width="150px"
                type="text"
                value={
                  discount > 0
                    ? numeral(discountValue).format('0,0.00')
                    : customDiscount
                }
                onChange={(e) => {
                  setCustomDiscount(e.target.value)
                  if (props.data.discount.customOnChange) {
                    props.data.discount.customOnChange(e.target.value)
                  }
                }}
              />
              <Konsys.P margin="0 0 0 8px">บาท</Konsys.P>
            </Konsys.Div>
          ) : (
            <TextWithIcon
              textAlign="right"
              text={`-${numeral(discountValue).format('0,0.00')} บาท`}
            />
          )}
        </Konsys.Div>
      )}
      <Divider color={theme.color.gray80} margin="8px 0" />
      <TextBetween
        title={{ text: 'ยอดชำระ', bold: true }}
        content={{
          text: props?.finalDiscount?.disabled
            ? `${numeral((props.data.total + props.data?.shipping?.value) - first(props.data?.lots).discount - discountValue).format('0,0.00')} บาท` // edit
            : `${numeral(totalSummary - (props.po ? discountValue : finalDiscount)).format('0,0.00')} บาท`, // create
          bold: true,
        }}
      />
    </Konsys.Div>
  )
}

export const RowSelect = (props) => (
  <RowSelectChildWrapper style={props.style} header={props.header}>
    {props.list.map((v, i) => {
      if (!_.isEmpty(v)) {
        return (
          <Konsys.Div
            key={i}
            moreStyle={{
              borderBottom: `1px solid ${theme.color.primaryColor10}`,
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            padding="8px 16px"
          >
            <Konsys.Div
              display="flex"
              alignItems={props.align || 'center'}
              moreStyle={{ flexGrow: 1, flexWrap: 'wrap' }}
            >
              {v.data.map((d, y) => (_.isUndefined(d.title) ? (
                <Konsys.SmallText
                  key={y}
                  margin={d.margin || y === 1 ? '0 72px 0 0' : '0 16px 0 0'}
                  {...d.content}
                >
                  {d.content.text}
                </Konsys.SmallText>
              ) : _.isUndefined(d.content) ? (
                <Konsys.Label
                  key={y}
                  margin={d.margin || y === 1 ? '0 72px 0 0' : '0 16px 0 0'}
                  {...d.title}
                >
                  {d.title.text}
                </Konsys.Label>
              ) : (
                <SmallTextWithLabel
                  key={y}
                  margin={d.margin || y === 1 ? '0 72px 0 0' : '0 16px 0 0'}
                  {...d}
                />
              )))}
            </Konsys.Div>
            <Konsys.Checkbox
              data={{ text: '', value: v.value }}
              checked={v.checked}
              disabled={v.disabled}
              activeColor={props.checkboxActiveColor}
              disabledColor={props.checkboxDisabledColor}
              onChange={(e) => {
                if (v.onChange) {
                  v.onChange(e)
                }
              }}
            />
          </Konsys.Div>
        )
      }
      return null
    })}
  </RowSelectChildWrapper>
)

export const GroupRowSelect = (props) => (
  <Konsys.Div>
    {!_.isEmpty(props.list.header) && (
      <RowSelect
        header
        style={props.header && props.header.style}
        list={props.list.header}
        checkboxDisabledColor={props.checkboxDisabledColor}
      />
    )}
    <Konsys.Div
      padding={!_.isEmpty(props.list.header) ? '0 16px 0 56px' : '0 16px'}
      bgColor={theme.color.offWhite}
    >
      <RowSelect
        align={props.align}
        disabled={props.disabled}
        list={props.list.items}
        checkboxDisabledColor={props.checkboxDisabledColor}
      />
    </Konsys.Div>
  </Konsys.Div>
)

const TableDetail = ({ v }) => (
  <Konsys.Div>
    <Konsys.SmallText
      color={theme.color.gray50}
      display="block"
      margin="6px 0 0"
    >
      {v.subText}
    </Konsys.SmallText>
    {!_.isUndefined(v.itemSource) && (
      <Konsys.SmallText
        color={theme.color.gray70}
        display="block"
        margin="6px 0 0"
      >{`สั่งจาก: ${v.itemSource}`}</Konsys.SmallText>
    )}
    {!_.isUndefined(v.label) && (
      <Konsys.Div margin="8px 0 0">
        {v.label.withDevider && (
          <Divider color={theme.color.greyBlue} margin="0 0 8px" />
        )}
        <Konsys.SmallText color={v.label.color}>
          {v.label.text}
        </Konsys.SmallText>
        {v.label.withDevider && (
          <Divider color={theme.color.greyBlue} margin="8px 0 0" />
        )}
      </Konsys.Div>
    )}
    {!_.isUndefined(v.trackingNumber) && (
      <Konsys.Div margin="8px 0 0">
        {v.trackingNumber.withDevider && (
          <Divider color={theme.color.greyBlue} margin="0 0 8px" />
        )}
        <Konsys.SmallText color={v.trackingNumber.color}>
          {v.trackingNumber.text}
        </Konsys.SmallText>
        {v.trackingNumber.withDevider && (
          <Divider color={theme.color.greyBlue} margin="8px 0 0" />
        )}
      </Konsys.Div>
    )}
    {!_.isUndefined(v.status) && (
      <Konsys.Div margin="8px 0 0">
        {v.status.icon && (
          <Konsys.Icon
            color={v.status.color}
            icon={v.status.icon}
            fontSize={theme.fonts.size.label}
            margin="0 8px 0 0"
          />
        )}
        <Konsys.SmallText color={v.status.color}>สถานะ</Konsys.SmallText>
        <Konsys.SmallText color={v.status.color} bold margin="0 0 0 8px">
          {v.status.text}
        </Konsys.SmallText>
      </Konsys.Div>
    )}
    {!_.isUndefined(v.status) && !_.isUndefined(v.status.subText) && (
      <Konsys.SmallText color={theme.color.gray50}>
        {v.status.subText}
      </Konsys.SmallText>
    )}
  </Konsys.Div>
)

/* ===================== TABLE LIST ======================= */
export const TableRowData = (props) => (
  <Konsys.Div display="flex" margin={props.margin}>
    {props.data.map((v, i) => {
      const currentcolumnStyled = props.columnStyled[i]
      if (i === 0 && !props.isHeader) {
        return (
          <Konsys.Div
            key={i}
            margin={
              _.isUndefined(currentcolumnStyled.margin)
                ? i === props.data.length - 1
                  ? '0'
                  : '0 16px 0 0'
                : currentcolumnStyled.margin
            }
            display="flex"
            style={{
              width: _.isUndefined(currentcolumnStyled.width)
                ? 'auto'
                : currentcolumnStyled.width,
              flexGrow: i === 0 ? '1' : '0',
              ...currentcolumnStyled,
            }}
          >
            <Konsys.P margin="0 8px 0 0">{props.no}.</Konsys.P>
            <Konsys.Div width="200%">
              <span>
                <Konsys.P display="inline-block">{v.text}</Konsys.P>
                {v.requireTaxInvoice && (
                  <Konsys.Icon margin="0 0 0 8px" icon="fal fa-file-invoice" />
                )}
              </span>
              {props.isCollapse ? (
                <AntCollapse
                  bordered={false}
                  style={{ width: '200%' }}
                >
                  <AntCollapse.Panel header="รายละเอียด" key="1">
                    <TableDetail v={v} />
                  </AntCollapse.Panel>
                </AntCollapse>
              ) : (
                <TableDetail v={v} />
              )}
            </Konsys.Div>
          </Konsys.Div>
        )
      }
      if (props.isHeader) {
        return (
          <Konsys.SmallText
            bold
            textAlign={_.isUndefined(v.textAlign) ? 'left' : v.textAlign}
            key={i}
            margin={
              _.isUndefined(currentcolumnStyled?.margin)
                ? i === props.data.length - 1
                  ? '0'
                  : i === 0
                    ? '0 16px 0 24px'
                    : '0 16px 0 0'
                : currentcolumnStyled?.margin
            }
            style={{
              width: _.isUndefined(currentcolumnStyled?.width)
                ? 'auto'
                : i === 0
                  ? `calc(${currentcolumnStyled?.width} - 24px)`
                  : currentcolumnStyled?.width,
              flexGrow: i === 0 ? '1' : '0',
              ..._.omit(currentcolumnStyled, ['width']),
            }}
          >
            {v.text}
          </Konsys.SmallText>
        )
      }
      return (
        <Konsys.P
          bold={props.isHeader}
          textAlign={_.isUndefined(v.textAlign) ? 'left' : v.textAlign}
          key={i}
          margin={
            _.isUndefined(currentcolumnStyled?.margin)
              ? i === props.data.length - 1
                ? '0'
                : '0 16px 0 0'
              : currentcolumnStyled?.margin
          }
          style={{
            width: _.isUndefined(currentcolumnStyled?.width)
              ? 'auto'
              : currentcolumnStyled?.width,
            flexGrow: i === 0 ? '1' : '0',
            ...currentcolumnStyled,
          }}
        >
          {v.text}
        </Konsys.P>
      )
    })}
  </Konsys.Div>
)

export const TableList = (props) => (
  <Konsys.Div>
    <TableRowData
      key="header"
      isDataCollapse={props.isDataCollapse}
      isHeader
      data={props.headerData}
      columnStyled={props.columnStyled}
    />
    <Divider margin="8px 0 16px" />
    {props.data.map((v, i) => {
      const tableRowRender = (
        <TableRowData
          isCollapse={props.isCollapse}
          margin={
            i === props.data.length - 1 || !_.isEmpty(v[0].subItems)
              ? '0'
              : '0 0 16px'
          }
          key={i}
          no={i + 1}
          data={v}
          columnStyled={props.columnStyled}
        />
      )
      if (props.isDataCollapse && !_.isEmpty(v[0].collapseData)) {
        return (
          <Konsys.Div key={i} margin="0 0 16px">
            <Konsys.Div>{tableRowRender}</Konsys.Div>
            <Konsys.Div margin="0 0 0 16px" background="red">
              {v[0].collapseData}
            </Konsys.Div>
          </Konsys.Div>
        )
      }
      return (
        <Konsys.Div key={i}>
          {tableRowRender}
          {!_.isEmpty(v[0].subItems) && (
            <Konsys.Div
              margin="8px 0 24px 20.53px"
              moreStyle={{ borderTop: `1px solid ${theme.color.gray10}` }}
            >
              {v[0].subItems?.map((subItemData, index) => (
                <Konsys.Div key={index} display="flex" margin="8px 0">
                  <Konsys.P
                    margin="0 16px 0 0"
                    style={{ width: 'auto', flexGrow: 1 }}
                  >
                    {subItemData[0].text}
                  </Konsys.P>
                  <Konsys.P
                    textAlign="right"
                    margin="0 16px 0 0"
                    style={{ ...props.columnStyled[1], flexGrow: 0 }}
                  >
                    {subItemData[1].text}
                  </Konsys.P>
                  <Konsys.P
                    textAlign="right"
                    margin="0 16px 0 0"
                    style={props.columnStyled[2]}
                  >
                    {subItemData[2].text}
                  </Konsys.P>
                  <Konsys.P
                    textAlign="right"
                    margin="0 16px 0 0"
                    style={props.columnStyled[3]}
                  >
                    {subItemData[3].text}
                  </Konsys.P>
                  <Konsys.P
                    textAlign="right"
                    margin="0 16px 0 0"
                    style={props.columnStyled[4]}
                  >
                    {subItemData[4].text}
                  </Konsys.P>
                  <Konsys.P textAlign="right" style={props.columnStyled[5]}>
                    {subItemData[5].text}
                  </Konsys.P>
                </Konsys.Div>
              ))}
            </Konsys.Div>
          )}
        </Konsys.Div>
      )
    })}
  </Konsys.Div>
)

export const MessagePage = (props) => (
  <Konsys.Div textAlign="center" margin={props.margin}>
    <Konsys.Icon {...props.icon} />
    <TextWithIcon {..._.omit(props, ['icon', 'margin'])} />
  </Konsys.Div>
)

export const ReplaceItem = (props) => (
  <Konsys.Div display="flex" alignItems="center">
    <Konsys.Div moreStyle={{ flexGrow: 1 }}>
      <SelectWithLabel
        title={{
          text: 'เลือกสินค้า',
          margin: props.small ? '0 0 8px' : '0 0 4px',
        }}
        width="100%"
        select={{
          size: 'small',
          style: {
            width: '100%',
          },
          options: props.data ? props.data.optionList : [],
          defaultValue:
            props.data && props.data.select && props.data.select.value,
          onChange: (value) => {
            if (props.data && props.data.select.onChange) {
              props.data.select.onChange(value)
            }
          },
        }}
      />
    </Konsys.Div>
    <InputWithLabel
      title={{ text: 'จำนวนชิ้น', margin: props.small ? '0 0 8px' : '0 0 4px' }}
      width="20%"
      margin="0 24px"
      onChange={(e) => {
        if (props.data && props.data.quantity.onChange) {
          props.data.quantity.onChange(e.target.value)
        }
      }}
      input={{
        state: 'default',
        rule: { type: 'float' },
        width: '100%',
        small: props.small,
        value: props.data && props.data.quantity && props.data.quantity.value,
      }}
    />
    <InputWithLabel
      title={{ text: 'ราคา/ชิ้น', margin: props.small ? '0 0 8px' : '0 0 4px' }}
      width="30%"
      onChange={(e) => {
        if (props.data && props.data.price.onChange) {
          props.data.price.onChange(e.target.value)
        }
      }}
      input={{
        state: 'default',
        rule: { type: 'float' },
        width: '100%',
        value: props.data && props.data.price && props.data.price.value,
        small: props.small,
      }}
    />
  </Konsys.Div>
)

export const HistoryDetail = (props) => (
  <Konsys.Div display="flex" alignItems="flex-start" margin={props.margin}>
    <Konsys.Div
      display="flex"
      alignItems="center"
      moreStyle={{ flexBasis: '40%' }}
    >
      <img src={USER_IMAGE} style={{ width: '30px' }} />
      <Konsys.Div margin="0 0 0 16px">
        <Konsys.P display="block" margin="0 0 2px">
          {props.data.name}
        </Konsys.P>
        <Konsys.SmallText>{props.data.createdDate}</Konsys.SmallText>
      </Konsys.Div>
    </Konsys.Div>
    <Konsys.P style={{ flexBasis: '60%', whiteSpace: 'pre-line' }}>{props.data.message}</Konsys.P>
  </Konsys.Div>
)

export const HistoryList = (props) => (
  <Konsys.Div>
    {props.loading ? (
      <Konsys.Div margin="48px 0 0" textAlign="center">
        <Loading size="small" />
      </Konsys.Div>
    ) : !_.isEmpty(props.list) ? (
      <Konsys.Div>
        <Konsys.Div display="flex" margin="0 0 24px">
          <Konsys.P
            bold
            color={theme.color.primaryColor}
            style={{ flexBasis: '40%' }}
          >
            User
          </Konsys.P>
          <Konsys.P
            bold
            color={theme.color.primaryColor}
            style={{ flexBasis: '60%' }}
          >
            Message
          </Konsys.P>
        </Konsys.Div>
        <Konsys.Div>
          {props.list.map((v, i) => (
            <HistoryDetail
              margin={i !== props.list.length - 1 ? '0 0 16px' : '0'}
              data={v}
              key={i}
            />
          ))}
        </Konsys.Div>
      </Konsys.Div>
    ) : (
      <MessagePage
        margin="48px 0 0"
        icon={{
          icon: 'fal fa-times',
          fontSize: theme.fonts.size.title,
          color: theme.color.lightBlue,
          margin: '0 0 16px',
        }}
        text={MESSAGE.empty.list}
        color={theme.color.lightBlue}
        bold
      />
    )}
  </Konsys.Div>
)

// ------------------------------- TAE ------------------------------- //
export const Centerized = (props) => (
  <Konsys.Div
    width="100%"
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection={props.direction || 'column'}
    margin={props.margin}
  >
    {props.children}
  </Konsys.Div>
)

// Props: margin, color, size
export const Loading = (props) => (
  <Spin
    style={{ margin: props.margin, ...props.style }}
    indicator={
      <LoadingOutlined
        spin
        style={{
          fontSize:
            props.size === 'extraSmall'
              ? theme.fonts.size.small
              : props.size === 'small'
                ? 20
                : props.size === 'big'
                  ? 48
                  : 28,
          color: props.color,
        }}
      />
    }
  />
)

// Props: color, size
export const LoadingPage = (props) => (
  <Centerized margin="40px 0 0">
    <Spin
      style={{ marginBottom: 24 }}
      indicator={
        <LoadingOutlined
          spin
          style={{
            fontSize:
              props.size === 'small' ? 20 : props.size === 'normal' ? 28 : 48,
            color: props.color,
          }}
        />
      }
    />
    <Konsys.H4 bold>กำลังโหลด</Konsys.H4>
  </Centerized>
)

// Props: width, message
export const Error = (props) => (
  <Alert
    message={props.message}
    type="error"
    showIcon
    style={{ width: props.width || 'fit-content', ...props.style }}
  />
)

// Props: message
export const ErrorPage = (props) => (
  <Result
    status="error"
    title="เกิดข้อผิดพลาด"
    subTitle={props.message}
    extra={[
      <Button type="primary" key="console">
        กลับสู่หน้าหลัก
      </Button>,
    ]}
    style={props.style}
  />
)

export const Uploader = (props) => (
  <Upload
    showUploadList={{
      showRemoveIcon: !props.disabled,
    }}
    disabled={props.disabled}
    customRequest={() => null}
    processData={false}
    fileList={props.data}
    accept={props.accept}
    onChange={(e) => {
      props.onChange(e.fileList.map((v) => v?.originFileObj || v))
    }}
    beforeUpload={() => false}
    multiple={props.isMultiple}
    defaultFileList={
      _.size(props.data) > 0
        && _.isArray(props.data)
        && !_.isEmpty(props.data)
        && props.data?.map((v) => ({
          name: v.filename,
          url: v.url,
          uid: v.url,
        }))
    }
    style={props.style}
  >
    <Konsys.Button
      inverse={props.inverse}
      small
      text={props.buttonText || 'อัพโหลดไฟล์'}
      icon={props.icon || 'far fa-file-upload'}
      disabled={
        props.disabled
          || (props.fileLength && props.data?.length >= props.fileLength)
      }
      style={props.buttonStyle}
    />
  </Upload>
)

export const DashedButton = (props) => (
  <Konsys.Button
    small
    style={{ borderStyle: 'dashed' }}
    onClick={(e) => props.onClick(e)}
    {...props}
  />
)

export const Pagination = (props) => (
  <Konsys.Div
    display="flex"
    justifyContent={props.justify || 'center'}
    width="100%"
  >
    <PaginationAnt
      total={props.total}
      defaultCurrent={props.defaultValue}
      current={props.current || 1}
      pageSize={props.pageSize}
      onChange={(e) => props.onChange(e)}
      style={props.style}
    />
  </Konsys.Div>
)

export const TimePicker = (props) => (
  <TimePickerAnt
    onChange={(e) => props.onChange(e)}
    defaultValue={props.defaultValue}
    style={props.style}
    {...props}
  />
)

export const RangePicker = (props) => (
  <DatePickerAnt.RangePicker
    onChange={(e) => props.onChange(e)}
    defaultValue={props.defaultValue}
    style={props.style}
    {...props}
  />
)

export const NotFound = (props) => (
  <Result
    status="warning"
    title={`ไม่พบข้อมูล${props.moduleName}`}
    extra={
      <Link to="/">
        <Button type="primary" key="console">
          กลับหน้าหลัก
        </Button>
      </Link>
    }
  />
)

export const Modal = (props) => (
  <ModalCustomized
    onCancel={() => props.onAction('back')}
    footer={[
      <Konsys.Button
        key="back"
        onClick={() => props.onAction('back')}
        color={theme.color.error}
        {...props.cancelButton}
      />,
      <Konsys.Button
        key="submit"
        onClick={() => props.onAction('submit')}
        color={theme.color.success}
        inverse
        {...props.okButton}
      />,
    ]}
    {...props}
  >
    {props.children}
    <Konsys.P>{props.question}</Konsys.P>
  </ModalCustomized>
)

export const Remark = (props) => (
  <AlertCustomized
    message={props.message || 'หมายเหตุ'}
    description={props.description}
    type="info"
    showIcon={props.showIcon}
    {...props}
  />
)

export const CoverImage = (props) => (
  <img
    src={props.src}
    alt={props.alt}
    style={{
      ...props.style,
      width: props.width,
      height: props.height,
      objectFit: 'contain',
    }}
  />
)

export const PaymentEvidence = (props) => {
  const { data } = useQuery(FINNIC_BANKS)
  const { data: bankListData } = useQuery(BANK_LIST)
  const verifyByTransaction = !_.isEmpty(props?.data?.transactionId)
  return (
    <Konsys.Div
      width="100%"
      height="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      margin="16px 0"
    >
      <Konsys.Div display="flex" flexDirection="row" margin="6px 0 0">
        <Konsys.P>{props.index}.</Konsys.P>
        <Konsys.Div
          padding="0 0 0 16px"
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Konsys.P style={{ marginBottom: 5 }}>
            วันที่ทำรายการ:{' '}
            {moment.tz(props.date, 'Asia/Bangkok').format('DD/MM/YYYY')}
          </Konsys.P>
          {(props.type === 'TRANSFER' || verifyByTransaction) && (
            <Konsys.P style={{ marginBottom: 5 }}>
              เวลาที่ทำรายการ: {props.data?.time}
            </Konsys.P>
          )}
          <Konsys.P style={{ marginBottom: 5 }}>
            จำนวนเงิน: {numeral(props.subtotal).format('0,0.00')} บาท
          </Konsys.P>
          <Konsys.P style={{ marginBottom: 5 }}>
            ช่องทาง: {props.type === 'CASH' ? 'โอนเงิน' : 'ชำระเงินปลายทาง'}
          </Konsys.P>
          {(props.type === 'TRANSFER' || props.type === 'CHEQUE') && (
            <Konsys.Div margin="16px 0 0">
              <Konsys.P display="block" style={{ marginBottom: 5 }}>
                บัญชีปลายทาง: {props.data?.finnicBankFullName}
              </Konsys.P>
              <Konsys.P style={{ marginBottom: 5 }}>
                เลขบัญชี:{' '}
                {
                  _.find(
                    data?.finnicBankList?.data,
                    (v) => v.id === props.data?.finnicBankId,
                  )?.bankAccountNumber
                }
              </Konsys.P>
            </Konsys.Div>
          )}
          {props.type === 'CHEQUE' && (
            <Konsys.Div margin="16px 0 0">
              <Konsys.P display="block" style={{ marginBottom: 5 }}>
                วันที่เช็ค:{' '}
                {moment(props.data?.chequeDate).format('DD/MM/YYYY')}
              </Konsys.P>
              <Konsys.P display="block" style={{ marginBottom: 5 }}>
                เลขเช็ค: {moment(props.data?.chequeDate).format('DD/MM/YYYY')}
              </Konsys.P>
            </Konsys.Div>
          )}
          {props.type === 'CREDIT' && (
            <Konsys.Div margin="16px 0 0">
              <Konsys.P display="block" style={{ marginBottom: 5 }}>
                เลข 4 ตัวหลัง: {props.data?.creditCard}
              </Konsys.P>
              <Konsys.P display="block" style={{ marginBottom: 5 }}>
                Reference Number: {props.data?.referenceNO}
              </Konsys.P>
            </Konsys.Div>
          )}
          {verifyByTransaction && (
            <Konsys.Div margin="16px 0 0">
              <Konsys.P display="block" style={{ marginBottom: 5 }}>
                บัญชีต้นทาง :{' '}
                {
                  _.find(
                    bankListData?.bankList?.data?.list,
                    (v) => v.bankCode === props?.data?.bankCode,
                  )?.name
                }
              </Konsys.P>
              <Konsys.P
                margin="16px 0 0"
                display="block"
                style={{ marginBottom: 5 }}
              >
                Reference : {props?.data?.transactionId}
              </Konsys.P>
            </Konsys.Div>
          )}
          <Konsys.SmallText margin="16px 0 0" style={{ marginBottom: 5 }}>
            หมายเหตุ: {props.info}
          </Konsys.SmallText>
          {props.src && (
            <CoverImage
              src={props.src}
              style={{ maxHeight: '250px', maxWidth: '100%' }}
            />
          )}
        </Konsys.Div>
      </Konsys.Div>
      {props?.data?.isEdit && !verifyByTransaction && (
        <Konsys.Div>
          <Konsys.Button
            margin="0 8px 0 0"
            small
            text="แก้ไข"
            icon="fal fa-edit"
            onClick={() => {
              if (!_.isEmpty(props.pushState)) {
                props.history.push({
                  pathname: `/sale-orders/payment/edit/${props?.soId}/${props.data?.id}`,
                  ...props.pushState,
                })
              } else {
                props.history.push(
                  `/sale-orders/payment/edit/${props?.soId}/${props.data?.id}`,
                )
              }
            }}
          />
        </Konsys.Div>
      )}
    </Konsys.Div>
  )
}

const DebtDetailInSO = (props) => (
  <Konsys.Div
    width="100%"
    height="100%"
    display="flex"
    flexDirection="row"
    margin="16px 0"
  >
    <Konsys.P>{props.index}.</Konsys.P>
    <Konsys.Div>
      <Konsys.Div display="flex" alignItems="flex-start" width="100%">
        <Konsys.Div padding="0 16px" width="50%">
          <Konsys.P display="block" style={{ marginBottom: 5 }}>
            ใบ{' '}
            <Konsys.P
              color={
                props.data.type === 'DN'
                  ? theme.color.success
                  : theme.color.error
              }
              margin="0 4px 0 0"
            >
              #{props.data.number}
            </Konsys.P>
            (#{props.parentNumber})
          </Konsys.P>
          <Konsys.P display="block" style={{ marginBottom: 5 }}>
            วันที่ทำรายการ:{' '}
            {moment
              .tz(props.data.createDate, 'Asia/Bangkok')
              .format('DD/MM/YYYY')}
          </Konsys.P>
          {props.data?.files && (
            <Konsys.Div margin="0 0 8px">
              <Konsys.P margin="0 0 2px" display="block">
                เอกสารแนบ
              </Konsys.P>
              {props.data?.files?.map((file, i) => (
                <div key={file.id}>
                  <a
                    style={{
                      display: 'block',
                      margin:
                        i !== 0 && props.data?.files?.length > 0
                          ? '0 0 4px'
                          : '0',
                    }}
                    href={file.url}
                  >
                    {file.fileName}
                  </a>
                  <img
                    style={{ width: '100%', maxWidth: '200px' }}
                    src={file.url}
                  />
                </div>
              ))}
            </Konsys.Div>
          )}
        </Konsys.Div>
        <Konsys.Div width="50%">
          <Konsys.P display="block" style={{ marginBottom: 5 }}>
            มูลค่าเดิม: {numeral(props.data.totalPriceBefore).format('0,0.00')}{' '}
            บาท
          </Konsys.P>
          <Konsys.P display="block" style={{ marginBottom: 5 }}>
            มูลค่าใหม่: {numeral(props.data.totalPriceAfter).format('0,0.00')}{' '}
            บาท
          </Konsys.P>
          <Konsys.P display="block" style={{ marginBottom: 5 }}>
            ผลต่าง:{' '}
            {numeral(
              Math.abs(props.data.totalPriceBefore - props.data.totalPriceAfter),
            ).format('0,0.00')}{' '}
            บาท
          </Konsys.P>
        </Konsys.Div>
      </Konsys.Div>
      {!_.isEmpty(props.data.itemChanges) && (
        <Konsys.Div margin="8px 0 24px 24px">
          <DebtItemChange
            type={props.data.type}
            itemChanges={props.data.itemChanges}
            deliveryCost={props.deliveryCost}
          />
        </Konsys.Div>
      )}
    </Konsys.Div>
  </Konsys.Div>
)

export const PaymentEvidenceList = (props) => {
  let count = 0
  return (
    <Konsys.Div>
      <Konsys.Div display="flex" flexDirection="column" margin="0 0 16px">
        <Konsys.P style={{ marginBottom: 5 }}>
          ยอดรวม: {numeral(props.subtotal).format('0,0.00')} บาท
        </Konsys.P>
        <Konsys.P style={{ marginBottom: 5 }}>
          ยอดที่ชำระแล้ว: {numeral(props.paid).format('0,0.00')} บาท
        </Konsys.P>
        <span style={{ marginBottom: 5 }}>
          <Konsys.P>ยอดค้างชำระ :&nbsp;</Konsys.P>
          <Konsys.P
            color={props.subtotal - props.paid > 0 && theme.color.error}
          >
            {numeral(props.subtotal - props.paid).format('0,0.00')} บาท{' '}
            {props.paid > props.subtotal && '(ลูกค้าชำระเงินเกิน)'}
          </Konsys.P>
        </span>
      </Konsys.Div>
      <Collapse
        style={{ margin: props.margin }}
        open={props.isOpen}
        text={props.title}
        onClick={() => props.setIsOpen(!props.isOpen)}
        fullHeight={props.fullHeight}
      >
        <Konsys.Div>
          {props?.paid > 0 && props?.paid < props?.depositAmount && (
            <Konsys.P bold>
              ยอดชำระรวม ยังไม่ครบยอดที่กำหนดชำระ
              <Konsys.P margin="0 0 0 4px" bold color={theme.color.error}>
                ({numeral(props?.depositAmount).format('0,0.00')} บาท)
              </Konsys.P>
            </Konsys.P>
          )}
          {props.list.map((v, i) => (
            <PaymentEvidence
              key={i}
              history={props.history}
              soId={props.soId}
              data={v}
              info={v.info}
              src={v.src}
              date={v.date}
              index={v.index}
              type={v.type}
              subtotal={v.subtotal}
              paid={v.paid}
              remaining={v.remaining}
              pushState={props.pushState}
            />
          ))}
          {!_.isEmpty(props.debtHistories)
            && props.debtHistories.map((v) => v.list.map((o, i) => {
              count += 1
              return (
                <DebtDetailInSO
                  parentNumber={v.parentNumber}
                  key={i}
                  data={o}
                  index={count}
                  deliveryCost={props.deliveryCost}
                />
              )
            }))}
        </Konsys.Div>
      </Collapse>
    </Konsys.Div>
  )
}

export const Log = ({ data, index = 1 }) => (
  <Konsys.Button
    icon="fad fa-info-circle"
    onlyIcon
    inverse
    style={{
      position: 'fixed',
      bottom: 48,
      right: index * 48 + index * 8,
      zIndex: 10,
      boxShadow: '5px 5px 15px 0px rgba(85,85,85,0.87)',
    }}
  />
)

export const PaymentMethodFormInAccount = (props) => {
  const paymentMethodList = [
    { text: 'โอนเงิน', value: 'CASH' },
    { text: 'ชำระเงินปลายทาง', value: 'COD' },
  ]

  return (
    <Konsys.Div padding={props.margin || '16px 48px 0'}>
      <Konsys.Div display="flex" alignItems="center">
        <Konsys.Div margin="0 16px 0 0">
          <Konsys.Label
            bold
            margin="0 0 4px"
            display="block"
            style={props.isInSo && { fontSize: theme.fonts.size.small }}
          >
            วันที่ชำระ
            <Konsys.SmallText color={theme.color.error} margin="0 0 0 8px">
              *
            </Konsys.SmallText>
          </Konsys.Label>
          <DatePickerAnt
            disabledDate={(current) => current && current >= moment().endOf('day')
            }
            value={
              !_.isEmpty(props?.paymentDate?.value)
              && moment(props?.paymentDate?.value)
            }
            onChange={(value) => {
              if (props.paymentDate) {
                props.paymentDate.onChange(value)
              }
            }}
            format="DD/MM/YYYY"
          />
        </Konsys.Div>
        <Konsys.Div margin="0 16px 0 0">
          <Konsys.Label
            bold
            margin="0 0 4px"
            display="block"
            style={props.isInSo && { fontSize: theme.fonts.size.small }}
          >
            อ้างอิง
          </Konsys.Label>
          <Konsys.Input
            value={props.reference && props.reference.value}
            onChange={(e) => props.reference && props.reference.onChange(e.target.value)
            }
            width="150px"
          />
        </Konsys.Div>
        <SelectWithLabel
          title={{
            text: (
              <Konsys.Label
                bold
                margin="0 0 4px"
                display="block"
                style={props.isInSo && { fontSize: theme.fonts.size.small }}
              >
                ช่องทาง
                <Konsys.SmallText color={theme.color.error} margin="0 0 0 8px">
                  *
                </Konsys.SmallText>
              </Konsys.Label>
            ),
            margin: '0 0 4px',
          }}
          select={{
            placeholder: 'เลือกช่องทาง',
            onChange: (value) => {
              if (props.paymentMethod) {
                props.paymentMethod.onChange(value)
              }
            },
            value: props.paymentMethod,
            style: { width: '150px' },
            options: paymentMethodList,
          }}
        />
        {props.isInSo && (
          <Konsys.Div margin="0 0 0 16px">
            <Konsys.Label
              bold
              margin="0 0 4px"
              display="block"
              style={props.isInSo && { fontSize: theme.fonts.size.small }}
            >
              จำนวนเงิน
              <Konsys.SmallText color={theme.color.error} margin="0 0 0 8px">
                *
              </Konsys.SmallText>
            </Konsys.Label>
            <Konsys.Input
              disabled={props.isPosType}
              state="default"
              rule={{ type: 'float' }}
              value={props?.amount?.value}
              onChange={(e) => props?.amount?.onChange(e.target.value)}
              width="150px"
            />
          </Konsys.Div>
        )}
      </Konsys.Div>
    </Konsys.Div>
  )
}

export const AddressBox = ({
  onBlurZipCode,
  defaultValue,
  onChange,
  value,
  onSelect,
  onSearch,
  title,
  pre,
  notRequire,
  disabled,
}) => {
  const [searchAddress, setSearchAddress] = useState([])
  return (
    <Konsys.Div
      width="100%"
      display="flex"
      flexDirection="column"
      padding="8px"
      margin="8px 0"
      style={{
        border: `1px solid ${theme.color.lightBlue}`,
        borderRadius: 5,
        background: theme.color.primaryColor10,
      }}
    >
      <Konsys.H4 bold>{title}</Konsys.H4>
      {pre}
      <InputWithLabel
        input={{
          disabled,
          value: value.postcode,
          onBlur: () => {
            if (_.size(searchAddress) > 0) {
              onBlurZipCode(searchAddress)
            }
          },
          onKeyUp: (e) => {
            if (e.keyCode === 13 && _.size(searchAddress) > 0) {
              onBlurZipCode(searchAddress)
            }
          },
          width: '100%',
          defaultValue: defaultValue?.postcode,
          state: !notRequire && _.isEmpty(value?.postcode) && 'error',
        }}
        onChange={(e) => {
          setSearchAddress(searchAddressByZipcode(e.target.value))
          onChange.zipcode(e.target.value)
        }}
        title={{
          text: (
            <Konsys.SmallText bold>
              รหัสไปรษณีย์{' '}
              {!notRequire && (
                <Konsys.Label color={theme.color.error}>*</Konsys.Label>
              )}
            </Konsys.SmallText>
          ),
        }}
      />
      <InputWithLabel
        input={{
          disabled,
          value: value.addressNo,
          width: '100%',
          defaultValue: defaultValue?.addressNo,
          state:
            !notRequire
            && _.isEmpty(value?.addressNo || defaultValue?.addressNo)
            && !_.isNaN(value?.addressNo || defaultValue?.addressNo)
            && 'error',
        }}
        onChange={(e) => onChange.addressNo(e.target.value)}
        title={{
          text: (
            <Konsys.SmallText bold>
              เลขที่{' '}
              {!notRequire && (
                <Konsys.Label color={theme.color.error}>*</Konsys.Label>
              )}
            </Konsys.SmallText>
          ),
        }}
      />
      <ComponentWithLabelLarge
        title={{
          text: (
            <Konsys.SmallText bold>
              แขวง/ตำบล{' '}
              {!notRequire && (
                <Konsys.Label color={theme.color.error}>*</Konsys.Label>
              )}
            </Konsys.SmallText>
          ),
        }}
      >
        <AutoComplete
          disabled={disabled}
          value={value.subDistrict}
          dataSource={_.uniq(searchAddress.map((v) => v.district))}
          style={{ width: '100%' }}
          onSelect={(e) => {
            onSelect.subDistrict(e)
          }}
          defaultValue={defaultValue?.subDistrict}
          onSearch={(e) => {
            onSearch.subDistrict(e)
          }}
          placeholder="ใส่"
          className={
            !notRequire
            && ((_.isEmpty(value.subDistrict) && !_.isNumber(value.subDistrict))
              || _.isNaN(value.subDistrict))
            && 'state-error'
          }
        />
      </ComponentWithLabelLarge>
      <ComponentWithLabelLarge
        title={{
          text: (
            <Konsys.SmallText bold>
              เขต/อำเภอ{' '}
              {!notRequire && (
                <Konsys.Label color={theme.color.error}>*</Konsys.Label>
              )}
            </Konsys.SmallText>
          ),
        }}
      >
        <AutoComplete
          disabled={disabled}
          value={value.district}
          dataSource={_.uniq(searchAddress.map((v) => v.amphoe))}
          style={{ width: '100%' }}
          onSelect={(e) => {
            onSelect.district(e)
          }}
          defaultValue={defaultValue?.district}
          onSearch={(e) => {
            onSearch.district(e)
          }}
          placeholder="ใส่"
          className={
            !notRequire
            && ((_.isEmpty(value.district) && !_.isNumber(value.district))
              || _.isNaN(value.district))
            && 'state-error'
          }
        />
      </ComponentWithLabelLarge>
      <ComponentWithLabelLarge
        title={{
          text: (
            <Konsys.SmallText bold>
              จังหวัด{' '}
              {!notRequire && (
                <Konsys.Label color={theme.color.error}>*</Konsys.Label>
              )}
            </Konsys.SmallText>
          ),
        }}
      >
        <AutoComplete
          disabled={disabled}
          value={value.province}
          dataSource={_.uniq(searchAddress.map((v) => v.province))}
          style={{ width: '100%' }}
          onSelect={(e) => {
            onSelect.province(e)
          }}
          defaultValue={defaultValue?.province}
          onSearch={(e) => {
            onSearch.province(e)
          }}
          placeholder="ใส่"
          className={
            !notRequire
            && ((_.isEmpty(value.province) && !_.isNumber(value.province))
              || _.isNaN(value.province))
            && 'state-error'
          }
        />
      </ComponentWithLabelLarge>
    </Konsys.Div>
  )
}

export const AddProductModal = (props) => {
  const [variables, setVariables] = useState({
    stockType: 'STOCK',
    type: 'VARIANT',
    sellable: true,
    purchasable: true,
    isLossable: false,
    inventoryOption: {
      initialCost: 0,
      initialStock: 0,
      isVat: true,
      vat: 7,
      pack: [{}],
    },
  })
  const { loading: supLoading, data: supData } = useQuery(SUPPLIERS)
  const [doCreate, { loading }] = useMutation(CREATE_INVENTORY)

  const brands = useQuery(GET_BRANDS_SUPPLIER, {
    variables: { supplierIdList: [variables.supplierId] },
    skip: _.isUndefined(variables.supplierId),
  })
  const groups = useQuery(GET_GROUPS_BRAND, {
    variables: {
      supplierIdList: [variables.supplierId],
      brandId: variables?.brand,
    },
    skip: _.isNil(variables.brand),
  })
  const categories = useQuery(GET_CATEGORIES_GROUP, {
    variables: {
      supplierIdList: [variables.supplierId],
      brandId: variables?.brand,
      productGroupId: variables?.productGroup,
    },
    skip: _.isNil(variables.productGroup),
  })

  return (
    <Modal
      title="เพิ่มสินค้าใหม่"
      visible={props.visible}
      okButton={{
        text: 'ยืนยัน เพิ่มสินค้าใหม่',
        color: theme.color.success,
        loading,
        disabled:
          validateData(
            true,
            [
              variables.sku,
              variables.productName,
              variables.productGroup,
              variables.category,
              variables.brand,
              variables.inventoryOption.buyPrice,
              variables.type === SALE_TYPE.PACK
                ? variables.inventoryOption.pack[0].name
                : 'noCheck',
              variables.type === SALE_TYPE.PACK
                ? variables.inventoryOption.pack[0].quantity
                : 'noCheck',
              variables.inventoryOption.pack[0].middlePrice,
            ],
            {},
          ) || loading,
      }}
      cancelButton={{
        text: 'ยกเลิก',
        disabled: loading,
      }}
      onAction={(e) => {
        if (e === 'back') {
          props.setVisible(false)
        } else {
          doCreate({
            variables: {
              ...variables,
              brandId: variables.brand,
              productCategoryId: variables.category,
              productGroupId: variables.productGroup,
              inventoryOption: {
                ...variables.inventoryOption,
                pack:
                  variables.type === SALE_TYPE.PACK
                    ? variables.inventoryOption.pack
                    : [
                      {
                        name: 'แพ๊ค 1',
                        quantity: 1,
                        middlePrice:
                            variables.inventoryOption.pack[0].middlePrice,
                      },
                    ],
              },
            },
          }).then((resp) => {
            const res = resp.data.createInventory
            if (res.success) {
              message.success(res.message)
              setVariables({
                stockType: 'STOCK',
                type: 'VARIANT',
                sellable: true,
                purchasable: true,
                isLossable: false,
                inventoryOption: {
                  initialCost: 0,
                  initialStock: 0,
                  isVat: true,
                  vat: 7,
                  pack: [{}],
                },
              })
            } else message.error(res.message)
            props.onCreated(resp.data.createInventory.data)
            props.setVisible(false)
            props.refetch()
          })
        }
      }}
    >
      <Konsys.Div display="flex" flexDirection="column">
        <SelectWithLabel
          width="100%"
          title={{
            text: 'Supplier *',
          }}
          select={{
            style: { width: '100%' },
            placeholder: 'เลือก Supplier ของสินค้า',
            onChange: (e) => setVariables({ ...variables, supplierId: e }),
            options: supLoading
              ? []
              : supData?.supplierList?.data?.list?.map((supp) => ({
                value: supp?.id,
                text: supp?.name,
              })),
          }}
        />
        <Row type="flex" gutter={16} align="middle">
          <Col xs={24} md={18}>
            <InputWithLabel
              width="100%"
              input={{
                width: '100%',
              }}
              onChange={(e) => setVariables({ ...variables, productName: e.target.value })
              }
              title={{ text: 'ชื่อสินค้า *' }}
            />
          </Col>
          <Col xs={24} md={6}>
            <Konsys.Div>
              <Konsys.SmallText bold>รหัสสินค้า *</Konsys.SmallText>
              <Konsys.Div display="flex" alignItems="center">
                <Konsys.Input
                  width="100%"
                  onChange={(e) => setVariables({ ...variables, sku: e.target.value })
                  }
                />
              </Konsys.Div>
            </Konsys.Div>
          </Col>
        </Row>
        <Row type="flex" gutter={16} align="middle">
          <Col xs={24} md={12}>
            <SelectWithLabel
              width="100%"
              title={{
                text: (
                  <Konsys.SmallText bold>
                    ยี่ห้อ{' '}
                    <Konsys.Label color={theme.color.error}>*</Konsys.Label>
                  </Konsys.SmallText>
                ),
              }}
              select={{
                loading: brands?.loading,
                style: { width: '100%' },
                value: variables.brand,
                placeholder: 'เลือกยี่ห้อ',
                onChange: (e) => setVariables({
                  ...variables,
                  brand: e,
                  productGroup: undefined,
                  category: undefined,
                }),
                options:
                  brands?.data?.getBrandsBySupplier?.data
                  && !brands?.data?.getBrandsBySupplier?.loading
                  && !_.isUndefined(variables.supplierId)
                    ? brands?.data?.getBrandsBySupplier.data.map((v) => ({
                      value: v.id,
                      text: v.name,
                    }))
                    : [],
                className:
                  ((_.isEmpty(variables.brand)
                    && !_.isNumber(variables.brand))
                    || _.isNaN(variables.brand))
                  && 'state-error',
              }}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectWithLabel
              width="100%"
              title={{
                text: (
                  <Konsys.SmallText bold>
                    กลุ่มสินค้า{' '}
                    <Konsys.Label color={theme.color.error}>*</Konsys.Label>
                  </Konsys.SmallText>
                ),
              }}
              select={{
                loading: groups?.loading,
                style: { width: '100%' },
                value: variables.productGroup,
                placeholder: 'เลือกกลุ่มสินค้า',
                onChange: (e) => setVariables({
                  ...variables,
                  productGroup: e,
                  category: undefined,
                }),
                options:
                  groups?.data?.getProductGroupBySupplierBrand?.data
                  && !groups?.data?.getProductGroupBySupplierBrand?.loading
                    ? groups?.data?.getProductGroupBySupplierBrand.data.map(
                      (v) => ({ value: v.id, text: v.name }),
                    )
                    : [],
                className:
                  ((_.isEmpty(variables.productGroup)
                    && !_.isNumber(variables.productGroup))
                    || _.isNaN(variables.productGroup))
                  && 'state-error',
              }}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectWithLabel
              width="100%"
              title={{
                text: (
                  <Konsys.SmallText bold>
                    ประเภทสินค้า{' '}
                    <Konsys.Label color={theme.color.error}>*</Konsys.Label>
                  </Konsys.SmallText>
                ),
              }}
              select={{
                loading: categories?.loading,
                style: { width: '100%' },
                value: variables.category,
                placeholder: 'เลือกประเภทสินค้า',
                onChange: (e) => setVariables({ ...variables, category: e }),
                options:
                  categories?.data
                    ?.getProductCategoriesBySupplierBrandProductGroup?.data
                  && !categories?.data
                    ?.getProductCategoriesBySupplierBrandProductGroup?.loading
                    ? categories?.data?.getProductCategoriesBySupplierBrandProductGroup.data.map(
                      (v) => ({ value: v.id, text: v.name }),
                    )
                    : [],
                className:
                  ((_.isEmpty(variables.productGroup)
                    && !_.isNumber(variables.productGroup))
                    || _.isNaN(variables.productGroup))
                  && 'state-error',
              }}
            />
          </Col>
        </Row>
        <RadioWrapper open={variables.type === SALE_TYPE.VARIANT}>
          <Konsys.Radio
            checked={variables.type === SALE_TYPE.VARIANT}
            data={{ value: 'variant', text: 'สินค้าขายเป็นรุ่น' }}
            onChange={(e) => {
              if (e.target.checked) {
                setVariables({ ...variables, type: SALE_TYPE.VARIANT })
              }
            }}
          />
          <Konsys.Div>
            <Row>
              <Col xs={24} md={12} lg={24}>
                <InputWithLabel
                  width="100%"
                  input={{
                    width: '100%',
                    placeholder: 'กรอกราคาซื้อ (ต่อหน่วย)',
                    state: 'default',
                    rule: { type: 'float' },
                  }}
                  onChange={(e) => setVariables({
                    ...variables,
                    inventoryOption: {
                      ...variables.inventoryOption,
                      buyPrice: parseFloat(e.target.value),
                    },
                  })
                  }
                  title={{ text: 'ราคาซื้อ (ต่อหน่วย) *' }}
                />
              </Col>
              <Col xs={24} md={12} lg={24}>
                <InputWithLabel
                  width="100%"
                  input={{
                    width: '100%',
                    placeholder: 'กรอกราคากลาง',
                    state: 'default',
                    rule: { type: 'float' },
                  }}
                  onChange={(e) => setVariables({
                    ...variables,
                    inventoryOption: {
                      ...variables.inventoryOption,
                      pack: [
                        {
                          ...variables.inventoryOption.pack[0],
                          middlePrice: parseFloat(e.target.value),
                        },
                      ],
                    },
                  })
                  }
                  title={{ text: 'ราคากลาง *' }}
                />
              </Col>
            </Row>
          </Konsys.Div>
        </RadioWrapper>
        <RadioWrapper open={variables.type === SALE_TYPE.PACK}>
          <Konsys.Radio
            checked={variables.type === SALE_TYPE.PACK}
            data={{ value: 'pack', text: 'สินค้าขายเป็นแพ็ค' }}
            onChange={(e) => {
              if (e.target.checked) {
                setVariables({ ...variables, type: SALE_TYPE.PACK })
              }
            }}
          />
          <Konsys.Div>
            <Konsys.Div>
              <ComponentWithLabelLarge title={{ text: 'ชื่อแพ๊ค *' }}>
                <Konsys.Input
                  margin="0 16px 0 0"
                  onChange={(e) => setVariables({
                    ...variables,
                    inventoryOption: {
                      ...variables.inventoryOption,
                      pack: [
                        {
                          ...variables.inventoryOption.pack[0],
                          name: e.target.value,
                        },
                      ],
                    },
                  })
                  }
                />
              </ComponentWithLabelLarge>
              <ComponentWithLabelLarge title={{ text: 'จำนวนต่อแพ๊ค *' }}>
                <Konsys.Input
                  state="default"
                  rule={{ type: 'float' }}
                  onChange={(e) => setVariables({
                    ...variables,
                    inventoryOption: {
                      ...variables.inventoryOption,
                      pack: [
                        {
                          ...variables.inventoryOption.pack[0],
                          quantity: parseFloat(e.target.value),
                        },
                      ],
                    },
                  })
                  }
                />
              </ComponentWithLabelLarge>
            </Konsys.Div>
            <Row>
              <Col xs={24} md={12} lg={24}>
                <InputWithLabel
                  width="100%"
                  input={{
                    // value: variables.inventoryOption.buyPrice,
                    width: '100%',
                    placeholder: 'กรอกราคาซื้อ (ต่อหน่วย)',
                    state: 'default',
                    rule: { type: 'float' },
                  }}
                  onChange={(e) => setVariables({
                    ...variables,
                    inventoryOption: {
                      ...variables.inventoryOption,
                      buyPrice: parseFloat(e.target.value),
                    },
                  })
                  }
                  title={{ text: 'ราคาซื้อ (ต่อหน่วย) *' }}
                />
              </Col>
              <Col xs={24} md={12} lg={24}>
                <InputWithLabel
                  width="100%"
                  input={{
                    width: '100%',
                    placeholder: 'กรอกราคากลาง',
                    state: 'default',
                    rule: { type: 'float' },
                  }}
                  onChange={(e) => setVariables({
                    ...variables,
                    inventoryOption: {
                      ...variables.inventoryOption,
                      pack: [
                        {
                          ...variables.inventoryOption.pack[0],
                          middlePrice: parseFloat(e.target.value),
                        },
                      ],
                    },
                  })
                  }
                  title={{ text: 'ราคากลาง *' }}
                />
              </Col>
            </Row>
          </Konsys.Div>
        </RadioWrapper>
      </Konsys.Div>
    </Modal>
  )
}

export const CashModalConfirm = (sum, func, visible, setVisible, title) => {
  const [total, setTotal] = useState()
  return (
    <Modal
      destroyOnClose={true}
      title={title}
      visible={visible}
      okButton={{
        text: 'ยืนยัน',
        color: 'blue',
      }}
      cancelButton={{
        text: 'ยกเลิก',
      }}
      onAction={(e) => {
        if (e === 'back') {
          setVisible(false)
        } else if (parseFloat(total) === sum) {
          notification.success({
            message: 'ผ่านจ้า',
            description: `${parseFloat(total)} ======== ${sum}`,
          })
        } else {
          notification.error({
            message: 'ไม่สามารถยืนยันได้',
            description: `${parseFloat(total)} ======== ${sum}`,
          })
        }
      }}
      question=""
    >
      <InputWithLabel
        width="100%"
        margin="8px 0"
        input={{
          width: '100%',
          state: 'default',
          rule: { type: 'float' },
        }}
        onChange={(e) => setTotal(e.target.value)}
        title={{ text: 'ยอดชำระมัดจำ' }}
      />
    </Modal>
  )
}

export const BillingNewAddress = ({
  lots,
  lotData,
  setLots,
  billingAddressList,
  isCreateMode,
  margin,
  isNewAddress,
}) => {
  const customerBillingAddressList = billingAddressList || []
  return (
    <Konsys.Div margin={margin}>
      <SelectWithLabel
        width="100%"
        margin="16px 16px 8px 0"
        title={{
          text: (
            <Konsys.SmallText bold margin="0 0 6px">
              ที่อยู่ออกใบกำกับภาษี{' '}
              <Konsys.Label color={theme.color.error}>*</Konsys.Label>
            </Konsys.SmallText>
          ),
        }}
        select={{
          disabled:
            lotData?.useNewBillingAddress || !_.isNil(lotData?.pickingTicket),
          value: !_.isNil(lotData?.billingAddressId)
            ? renderAddress(lotData?.billingAddressObj, 'billing')
            : undefined,
          style: { width: '100%' },
          placeholder: 'เลือกที่อยู่ออกใบกำกับภาษี',
          onSelect: (e) => {
            setLots(
              lots.map((val) => {
                if (val.id === lotData?.id) {
                  return {
                    ...val,
                    billingAddressId: e,
                    billingAddressObj: _.find(
                      customerBillingAddressList,
                      (o) => o.value === e,
                    ).addessData,
                  }
                }
                return val
              }),
            )
          },
          options: customerBillingAddressList,
          className:
            ((_.isEmpty(lotData?.billingAddressId)
              && !_.isNumber(lotData?.billingAddressId))
              || _.isNaN(lotData?.billingAddressId))
            && !lotData?.useNewBillingAddress
            && 'state-error',
        }}
      />
      <Konsys.Checkbox
        style={{ width: '100%' }}
        disabled={!_.isNil(lotData?.pickingTicket)}
        margin="8px 0 16px"
        checked={lotData?.useNewBillingAddress}
        data={{
          text: 'กรอกที่อยู่ออกใบกำกับภาษี',
          value: `taxAddress${lotData?.id}${lotData?.lotId}`,
        }}
        onChange={(e) => setLots(
          lots.map((val) => {
            if (
              isCreateMode
                ? val.id === lotData?.id
                : val.lotId === lotData?.lotId
            ) {
              return { ...val, useNewBillingAddress: e.target.checked }
            }
            return val
          }),
        )
        }
      />
      {isNewAddress && (
        <NewAddress
          type="billing"
          data={{
            ..._.find(lots, (o) => o.id === lotData?.id)?.newBillingAddress,
            zipcode: _.find(lots, (o) => o.id === lotData?.id)
              ?.newBillingAddress?.postcode,
            subdistrict: _.find(lots, (o) => o.id === lotData?.id)
              ?.newBillingAddress?.subDistrict,
          }}
          onChangePersonType={(personType) => {
            setLots(
              lots.map((val) => {
                if (val.id === lotData?.id) {
                  return {
                    ...val,
                    newBillingAddress: {
                      ...val.newBillingAddress,
                      billingType: personType,
                    },
                  }
                }
                return val
              }),
            )
          }}
          onChange={(key, value) => {
            setLots(
              lots.map((val) => {
                if (val.id === lotData?.id) {
                  return {
                    ...val,
                    newBillingAddress: {
                      ...val.newBillingAddress,
                      type: 'BILLING',
                      [key]: value,
                    },
                  }
                }
                return val
              }),
            )
          }}
          onSelect={(obj) => {
            setLots(
              lots.map((val) => {
                if (val.id === lotData?.id) {
                  return {
                    ...val,
                    newBillingAddress: {
                      ...val.newBillingAddress,
                      ...obj,
                      postcode: obj.zipcode,
                      subDistrict: obj.subdistrict,
                    },
                  }
                }
                return val
              }),
            )
          }}
        />
      )}
    </Konsys.Div>
  )
}

export const ModalTrackingNumber = (props) => {
  const [disabledModalButton, setDisabledModalButton] = useState(false)
  const {
    loading: deliveryChannelLoading,
    data: deliveryChannelData,
  } = useQuery(
    props.taxInvoice ? GET_TAX_INVOICE_DELIVERY_CHANNEL : GET_DELIVERY_CHANNEL,
  )

  const checkDisabledAddTracking = () => {
    const checkNotEmptyProvider = (v) => true
      || (_.isNil(v.deliveryProvider) && !_.isEmpty(v.newDeliveryProvider))
    return !_.isEmpty(
      _.filter(props.delivery, (v) => {
        const checkIsOneEmpty = (_.isEmpty(v.trackingNumber) && checkNotEmptyProvider(v))
          || (!_.isEmpty(v.trackingNumber) && !checkNotEmptyProvider(v))
        if (props.delivery.length === 1) {
          return (
            (_.isEmpty(v.trackingNumber) && !checkNotEmptyProvider(v))
            || checkIsOneEmpty
          )
        }
        return checkIsOneEmpty
      }),
    )
  }
  const setDeliveryData = (id, newObj) => {
    props.setDelivery(
      [...props.delivery].map((o) => {
        if (o.id === id) return { ...o, ...newObj }
        return o
      }),
    )
  }
  return (
    <Modal
      title={props?.title || 'กรอก Tracking Number เพื่อยืนยัน'}
      visible={props.showModal}
      onCancel={() => props.onCancel && props.onCancel()}
      footer={[
        <Konsys.Button
          key="back"
          small
          onClick={() => props.onCancel && props.onCancel()}
          text="ยกเลิก"
        />,
        <Konsys.Button
          key="submit"
          margin='0 0 0 16px'
          loading={props.recordDeliveryLoading}
          disabled={
            props.recordDeliveryLoading
            || disabledModalButton
            || checkDisabledAddTracking()
          }
          inverse
          small
          primary
          text="ยืนยัน"
          onClick={() => {
            if (
              !_.isEmpty(
                _.find(
                  props.delivery,
                  (o) => !_.isEmpty(o.newDeliveryProvider),
                ),
              )
            ) {
              setDisabledModalButton(true)
            } else if (props.doRecordDelivery) {
              props.doRecordDelivery(props.id)
            }
          }}
        />,
      ]}
    >
      <Konsys.Div>
        <Konsys.Div display="flex" margin="0 8px">
          <Konsys.SmallText
            style={{ width: '100%' }}
            margin="0 8px 0 0"
            display="block"
          >
            Tracking Number
          </Konsys.SmallText>
        </Konsys.Div>
        {props.delivery?.map((deliveryData, i) => (
          <Konsys.Div
            key={deliveryData.id}
            display="flex"
            width="100%"
            margin={i !== 0 ? '8px 0 0' : '0'}
          >
            <Konsys.Div margin="0 8px">
              <AutoComplete
                loading={deliveryChannelLoading}
                defaultValue={deliveryData?.name}
                dataSource={deliveryChannelData?.deliveryChannelList?.data?.map((channel) => channel.deliveryName)}
                style={{ width: 150, marginRight: 8 }}
                onChange={(e) => setDeliveryData(deliveryData.id, {
                  name: e,
                })
                }
              />
              <Konsys.Input
                style={{ width: '100%' }}
                value={deliveryData?.trackingNumber}
                onChange={(e) => setDeliveryData(deliveryData.id, {
                  trackingNumber: e.target.value,
                })
                }
              />
            </Konsys.Div>
          </Konsys.Div>
        ))}
      </Konsys.Div>
    </Modal>
  )
}

export const ItemCard = ({ item, count, removeItem, isReceipt }) => {
  const [isShown, setIsShown] = useState(false)
  return (
    <Konsys.Div display="flex" width="100%" height="97px" margin='0 0 16px 0'>
      {window.innerWidth > 768 && (
        <img
          style={{ width: 97, objectFit: 'cover', objectPosition: 'center' }}
          src={first((item.pack || item.productSet)?.inventoryOption?.files)?.url || noImg}
        />
      )}
      <Konsys.Div
        width={`calc(100% ${window.innerWidth > 768 ? '- 97px' : ''} - 95px)`}
        bgColor={theme.color.gray10}
        display="flex"
        flexDirection="column"
        padding="16px"
      >
        <Konsys.P bold margin="0 0 8px 0">
          {
            (item.pack || item.productSet)?.inventoryOption?.inventory
              ?.productName
          }
        </Konsys.P>
        <Konsys.SmallText>
        SKU: {(item.pack || item.productSet)?.inventoryOption?.inventory?.sku}
        </Konsys.SmallText>
        <Konsys.SmallText>
        Barcode:{' '}
          {(item.pack || item.productSet)?.inventoryOption?.inventory?.barcode}
        </Konsys.SmallText>
      </Konsys.Div>
      <Konsys.Div
        width="95px"
        bgColor={
          item?.quantity === count ? theme.color.success : theme.color.info
        }
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Konsys.Div display='flex' alignItems='center'>
          <Konsys.H4 bold color={theme.color.offWhite} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} style={{ display: 'flex', alignItems: 'center' }}>
            {
              isShown && count > 0
                ? <Button
                  danger
                  icon={<MinusOutlined />}
                  type="text"
                  onClick={() => {
                    removeItem(item.id)
                  }}
                  size='small'
                  style={{ borderRadius: 8, background: '#ffe0e0', marginRight: 2 }}
                />
                : count
            }
          </Konsys.H4>
          <Konsys.H4 color={theme.color.offWhite}>/{item?.quantity}</Konsys.H4>
        </Konsys.Div>
        <Konsys.SmallText color={theme.color.offWhite}>{isReceipt ? 'จำนวนที่ชำรุด' : 'จำนวนที่แพ็ค'}</Konsys.SmallText>
      </Konsys.Div>
    </Konsys.Div>
  )
}

export default TextWithIcon

// -------------------- Prop-types -------------------- //
Centerized.propTypes = {
  direction: PropTypes.string,
}

Loading.propTypes = {
  margin: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
}

LoadingPage.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

Error.propTypes = {
  width: PropTypes.string,
  message: PropTypes.string,
  style: PropTypes.object,
}

ErrorPage.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
}

Uploader.propTypes = {
  disabled: PropTypes.bool,
  data: PropTypes.object,
  accept: PropTypes.string,
  filter: PropTypes.func,
  onChange: PropTypes.func,
  buttonStyle: PropTypes.object,
}

DashedButton.propTypes = {
  disabled: PropTypes.string,
  style: PropTypes.object,
}

Pagination.propTypes = {
  justify: PropTypes.string,
  total: PropTypes.number,
  defaultValue: PropTypes.number,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.object,
}

TimePicker.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  style: PropTypes.object,
}

RangePicker.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  style: PropTypes.object,
}

Modal.propTypes = {
  onAction: PropTypes.func,
  cancelButton: PropTypes.object,
  okButton: PropTypes.object,
  visible: PropTypes.bool,
  title: PropTypes.string,
  question: PropTypes.string,
}

Remark.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
}

CoverImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

PaymentEvidence.propTypes = {
  src: PropTypes.string,
  info: PropTypes.string,
  index: PropTypes.number,
  date: PropTypes.object,
}
