import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login(
    $client_id: String!
    $client_secret: String!
    $grant_type: GrantType!
    $username: String!
    $password: String!
  ) {
    login(input: {
      client_id: $client_id
      client_secret: $client_secret
      grant_type: $grant_type
      username: $username
      password: $password
    }) {
      createdAccessToken {
        access_token
        refresh_token
      }
      success
      message
    }
  }
`

export const REQ_RESET_PASSWORD_TOKEN = gql`
  mutation forgetPassword(
    $email: String!
  ) {
    forgetPassword(input: {
      email: $email
    }) {
      createdForgetPassword {
        resetPasswordToken
      }
      success
      message
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $token: String!
    $newPassword: String!
  ) {
    resetPassword(input: {
      token: $token
      newPassword: $newPassword
    }) {
      success
      message
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $password: String!
    $email: String!
    $phoneNumber: String!
    $roles: [Int]!
    $warehouseId: Int
    $teamId: Int!
  ) {
    createUser(input: {
      firstName: $firstName
      lastName: $lastName
      password: $password
      email: $email
      phoneNumber: $phoneNumber
      roles: $roles
      warehouseId: $warehouseId
      teamId: $teamId
    }) {
      success
      message
    }
  }
`

export const CREATE_SUPPLIER = gql`
  mutation createSupplier(
    $prefix: String
    $paymentType: PaymentTypes!
    $name: String!
    $taxId: String
    $type: SupplierType!
    $isVat: Boolean!
    $contactName: String
    $contactPhoneNumber: [ContactPhoneNumberInput]
    $contactFaxNumber: String
    $contactFacebookName: String
    $contactEmail: [ContactEmailInput]
    $contactRemark: String
    $billingAddress: CreateAddressInput
    $contactAddress: [ContactAddressInput]
    $shippingAddress: [ShippingAddressInput]
    $paymentInfo: [SupplierBankInput]
    # $gender: String
    # $birthDate: Date
    # $idCardNumber: String
  ) {
    createSupplier(input: {
      paymentType: $paymentType
      prefix: $prefix
      name: $name
      taxId: $taxId
      isVat: $isVat
      type: $type
      contactName: $contactName
      contactPhoneNumber: $contactPhoneNumber
      contactFaxNumber: $contactFaxNumber
      contactEmail: $contactEmail
      contactFacebookName: $contactFacebookName
      contactRemark: $contactRemark
      billingAddress: $billingAddress
      contactAddress: $contactAddress
      shippingAddress: $shippingAddress
      paymentInfo: $paymentInfo
      # gender: $gender
      # birthDate: $birthDate
      # idCardNumber: $idCardNumber
    }) {
      success
      message
      data {
        id
        name
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $paymentType: PaymentTypes!
    $pricingTypeId: Int!
    $type: CustomerType!
    $prefix: String
    $name: String!
    $taxId: String
    $isVat: Boolean!
    $contactName: String
    $contactPhoneNumber: [ContactPhoneNumberInput]
    $contactFaxNumber: String
    $contactEmail: [ContactEmailInput]
    $contactRemark: String
    $creditLimit: Float
    $creditUsage: Float
    $creditTerm: Int
    $billingAddress: [BillingAddressInput]
    $contactAddress: [ContactAddressInput]
    $shippingAddress: [ShippingAddressInput]
    $gender: String
    $birthDate: Date
    $idCardNumber: String
  ) {
    createCustomer(input: {
      paymentType: $paymentType
      pricingTypeId: $pricingTypeId
      prefix: $prefix
      name: $name
      type: $type
      taxId: $taxId
      isVat: $isVat
      contactName: $contactName
      contactPhoneNumber: $contactPhoneNumber
      contactFaxNumber: $contactFaxNumber
      contactEmail: $contactEmail
      contactRemark: $contactRemark
      creditLimit: $creditLimit
      creditUsage: $creditUsage
      creditTerm: $creditTerm
      billingAddress: $billingAddress
      contactAddress: $contactAddress
      shippingAddress: $shippingAddress
      gender: $gender
      birthDate: $birthDate
      idCardNumber: $idCardNumber
    }) {
      success
      message
      data {
        id
        paymentType
        type
        prefix
        name
        gender
        contactAddress {
          id
          supplierId
          customerId
          type
          position
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          contactLineId
          contactFacebook
          position
          isPrimary
        }
        shippingAddress{
          id
          addressNo
          province
          district
          subDistrict
          postcode
        }
        contactName
        contactPhoneNumber {
          id
          name
          phoneNumber
          isPrimary
        }
        contactFaxNumber
        contactEmail {
          id
          email
          isPrimary
        }
        contactRemark
        creditLimit
        creditUsage
        creditTerm
        status
        createdAt
        updatedAt
      }
    }
  }
`

export const CREATE_WAREHOUSE = gql`
  mutation createWarehouse(
    $addressNo: String
    $province: String
    $district: String
    $subDistrict: String
    $postcode: String
    $contactName: String
    $contactPhoneNumber: String
    $contactFaxNumber: String
    $contactEmail: String
    $contactRemark: String
    $warehouseCode: String!
    $warehouseName: String!
  ) {
    createWarehouse(input: {
      address: {
        addressNo: $addressNo
        province: $province
        district: $district
        subDistrict: $subDistrict
        postcode: $postcode
        contactName: $contactName
        contactPhoneNumber: $contactPhoneNumber
        contactFaxNumber: $contactFaxNumber
        contactEmail: $contactEmail
        contactRemark: $contactRemark
      }
      warehouseCode: $warehouseCode
      warehouseName: $warehouseName
    }) {
      success
      message
    }
  }
`

export const UPDATE_WAREHOUSE = gql`
  mutation updateWarehouse(
    $id: Int!
    $addressNo: String
    $province: String
    $district: String
    $subDistrict: String
    $postcode: String
    $contactName: String
    $contactPhoneNumber: String
    $contactFaxNumber: String
    $contactEmail: String
    $contactRemark: String
    $warehouseCode: String
    $warehouseName: String
  ) {
    updateWarehouse(input: {
      id: $id
      address: {
        addressNo: $addressNo
        province: $province
        district: $district
        subDistrict: $subDistrict
        postcode: $postcode
        contactName: $contactName
        contactPhoneNumber: $contactPhoneNumber
        contactFaxNumber: $contactFaxNumber
        contactEmail: $contactEmail
        contactRemark: $contactRemark
      }
      warehouseCode: $warehouseCode
      warehouseName: $warehouseName
    }) {
      success
      message
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $roles: [Int]
    $status: Status
    $warehouseId: Int
    $teamId: Int
  ) {
    updateUser(
      id: $id
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        roles: $roles
        status: $status
        warehouseId: $warehouseId
        teamId: $teamId
    }) {
      success
      message
    }
  }
`

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier(
    $id: Int!
    $paymentType: PaymentTypes
    $type: SupplierType
    $prefix: String
    $name: String
    $taxId: String
    $isVat: Boolean
    $contactName: String
    $contactPhoneNumber: [ContactPhoneNumberInput]
    $contactFaxNumber: String
    $contactEmail: [ContactEmailInput]
    $contactFacebookName: String
    $contactRemark: String
    $billingAddress: CreateAddressInput
    $contactAddress: [ContactAddressInput]
    $shippingAddress: [ShippingAddressInput]
    $paymentInfo: [SupplierBankInput]
    # $gender: String
    # $birthDate: Date
    # $idCardNumber: String
  ) {
    updateSupplier(
      id: $id
      input: {
        paymentType: $paymentType
        type: $type
        prefix: $prefix
        name: $name
        taxId: $taxId
        isVat: $isVat
        contactName: $contactName
        contactPhoneNumber: $contactPhoneNumber
        contactFaxNumber: $contactFaxNumber
        contactEmail: $contactEmail
        contactFacebookName: $contactFacebookName
        contactRemark: $contactRemark
        billingAddress: $billingAddress
        contactAddress: $contactAddress
        shippingAddress: $shippingAddress
        paymentInfo: $paymentInfo
        # gender: $gender
        # birthDate: $birthDate
        # idCardNumber: $idCardNumber
    }) {
      success
      message
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $id: Int!
    $paymentType: PaymentTypes
    $type: CustomerType
    $pricingTypeId: Int
    $prefix: String
    $name: String
    $taxId: String
    $isVat: Boolean
    $contactName: String
    $contactPhoneNumber: [ContactPhoneNumberInput]
    $contactFaxNumber: String
    $contactEmail: [ContactEmailInput]
    $contactFacebookName: String
    $contactRemark: String
    $creditLimit: Float
    $creditUsage: Float
    $creditTerm: Int
    $billingAddress: [BillingAddressInput]
    $contactAddress: [ContactAddressInput]
    $shippingAddress: [ShippingAddressInput]
    $idCardNumber: String
    $gender: String
    $birthDate: Date
  ) {
    updateCustomer(
      id: $id
      input: {
        paymentType: $paymentType
        pricingTypeId: $pricingTypeId
        prefix: $prefix
        name: $name
        taxId: $taxId
        gender: $gender
        birthDate: $birthDate
        idCardNumber: $idCardNumber
        type: $type
        isVat: $isVat
        contactName: $contactName
        contactPhoneNumber: $contactPhoneNumber
        contactFaxNumber: $contactFaxNumber
        contactEmail: $contactEmail
        contactFacebookName: $contactFacebookName
        contactRemark: $contactRemark
        creditLimit: $creditLimit
        creditUsage: $creditUsage
        creditTerm: $creditTerm
        billingAddress: $billingAddress
        contactAddress: $contactAddress
        shippingAddress: $shippingAddress
    }) {
      success
      message
    }
  }
`

export const CREATE_INVENTORY = gql`
  mutation createInventory(
    $type: InventoryType!
    $supplierId: [Int]!
    $productName: String!
    $reference: String
    $barcode: String
    $sku: String!
    $brandId: Int!
    $productCategoryId: Int!
    $productGroupId: Int!
    $tag: [OldTagInput]
    $stockType: StockType!
    $sellable: Boolean
    $purchasable: Boolean
    $isLossable: Boolean!
    $serialTracking: Boolean
    $inventoryOption: InventoryOptionInput
  ) {
    createInventory(
      input: {
        type: $type
        supplierId: $supplierId
        productName: $productName
        sku: $sku
        barcode: $barcode
        reference: $reference
        brandId: $brandId
        productCategoryId: $productCategoryId
        productGroupId: $productGroupId
        tag: $tag
        stockType: $stockType
        sellable: $sellable
        purchasable: $purchasable
        isLossable: $isLossable
        serialTracking: $serialTracking
        inventoryOption: $inventoryOption
    }) {
      success
      message
      data {
        id
        packId
      }
    }
  }
`

export const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder (
    $id: Int!
    $orderItemList: [UpdateOrderItemPOInput]
    $paymentType: PaymentTypes
    $discountType: DiscountType
    $discount: Float
    $remark: String
    $files: [Upload]
    $warehouseId: Int
    $deliveryCost: Float
    $dueDate: Date
  ) {
    updatePurchaseOrder (
      id: $id
      input: {
        orderItemList: $orderItemList
        paymentType: $paymentType
        discountType: $discountType
        discount: $discount
        remark: $remark
        files: $files
        warehouseId: $warehouseId
        deliveryCost: $deliveryCost
        dueDate: $dueDate
      }
    ) {
      success
      message
      data {
        id
        status
        number
      }
    }
  }
`

export const ADJUST_DUE_DATE_PO = gql`
  mutation adjustDueDate (
    $id: Int!
    $dueDate: Date!
  ) {
    adjustDueDate (
      input: {
        id: $id
        dueDate: $dueDate
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_PURCHASE_ORDER = gql`
  mutation createPurchaseOrder (
    $supplierId: Int!
    $orderItemList: [CreateOrderItemPOInput]!
    $paymentType: PaymentTypes!
    $discountType: DiscountType
    $discount: Float
    $remark: String
    $files: [Upload]
    $dueDate: Date!
    $warehouseId: Int!
    $deliveryCost: Float
  ) {
    createPurchaseOrder (
      input: {
        supplierId: $supplierId
        orderItemList: $orderItemList
        paymentType: $paymentType
        discountType: $discountType
        discount: $discount
        remark: $remark
        files: $files
        dueDate: $dueDate
        warehouseId: $warehouseId
        deliveryCost: $deliveryCost
      }
    ) {
      success
      message
      data {
        id
        status
        number
      }
    }
  }
`

export const ADJUST_STOCK = gql`
  mutation adjustStock(
    $adjustStockList: [AdjustStockListInput]
  ) {
    adjustStock (input: {
      adjustStockList: $adjustStockList
    }) {
      success
      message
    }
  }
`

export const TRANSFER_STOCK = gql`
  mutation transferStock(
    $sourceId: Int!
    $destinationId: Int!
    $itemId: Int!
    $quantityChange: Float!
    $reason: String
  ) {
    transferStock (input: {
      sourceId: $sourceId
      destinationId: $destinationId
      itemId: $itemId
      quantityChange: $quantityChange
      reason: $reason
    }) {
      success
      message
    }
  }
`

export const UPDATE_INVENTORY = gql`
  mutation updateInventory(
    $tag: [OldTagInput]
    $supplierId: [Int]
    $productName: String
    $productGroupId: Int!
    $reference: String
    $barcode: String
    $sku: String
    $brandId: Int!
    $productCategoryId: Int!
    $sellable: Boolean
    $purchasable: Boolean
    # $taxable: Boolean
    $serialTracking: Boolean
    $inventoryOption: UpdateInventoryOptionInput
    $id: Int!
    $isLossable: Boolean
  ) {
    updateInventory (id: $id, input: {
      tag: $tag
      supplierId: $supplierId
      productName: $productName
      productGroupId: $productGroupId
      reference: $reference
      barcode: $barcode
      sku: $sku
      brandId: $brandId
      productCategoryId: $productCategoryId
      sellable: $sellable
      purchasable: $purchasable
      # taxable: $taxable
      serialTracking: $serialTracking
      inventoryOption: $inventoryOption
      isLossable: $isLossable
    }) {
      success
      message
    }
  }
`

export const CREATE_GOOD_RECEIVE = gql`
  mutation createGoodsReceive (
    $supplierId: Int!
    $warehouseId: Int
    $orderItems: [OrderItemInput]
    $items: [ItemInput]
    $remarks: String
    $files: [Upload]
    $discount: Float
    $discountPercent: Float
    $withholdingTax: Float
    $isDraft: Boolean!
    $refId: String
  ) {
    createGoodsReceive (
      input: {
        supplierId: $supplierId
        warehouseId: $warehouseId
        orderItems: $orderItems
        items: $items
        remarks: $remarks
        files: $files
        discount: $discount
        discountPercent: $discountPercent
        withholdingTax: $withholdingTax
        isDraft: $isDraft
        refId: $refId
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_GOOD_RECEIVE = gql`
  mutation updateGoodsReceive (
    $id: Int
    $refId: String
    $files: [Upload]
    $remarks: String
    $paymentDueDate: Date
    $orderItemGR: [UpdateOrderItemInput]
  ) {
    updateGoodsReceive (
      id: $id
      input: {
        refId: $refId
        files: $files
        remarks: $remarks
        paymentDueDate: $paymentDueDate
        orderItemGR: $orderItemGR
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_SO = gql`
  mutation createSalesOrder(
    $customerId: Int!
    $sellerId: Int!
    $type: SalesOrderType!
    $discountPercent: Float
    $chatProviderId: Int!
    $deliveryChannel: DeliveryChannelEnum!
    $discount: Float
    $remarks: String
    $files: [Upload]
    $remarkSlip: String
    $slips: [Upload]
    $contactAddressId: Int
    $lots: [CreateLotSaleOrderInput]!
    $quotationId: Int
    $isPOS: Boolean!
    $isCOD: Boolean
    $claimType: ClaimType
    $claimUserId: Int
    $claimOrderAmount: Float
  ) {
    createSalesOrder ( input: {
      chatProviderId: $chatProviderId
      customerId: $customerId
      sellerId: $sellerId
      contactAddressId: $contactAddressId
      type: $type
      discountPercent: $discountPercent
      discount: $discount
      remarks: $remarks
      files: $files
      remarkSlip: $remarkSlip
      slips: $slips
      lots: $lots
      quotationId: $quotationId
      isPOS: $isPOS
      isCOD: $isCOD
      claimType: $claimType
      claimUserId: $claimUserId
      claimOrderAmount: $claimOrderAmount
      deliveryChannel: $deliveryChannel
    }) {
      success
      message
      data {
        id
        status
        soNumber
      }
    }
  }
`

export const CREATE_DEBT = gql`
  mutation createDebt (
    $thisModule: DebtModule!
    $id: Int!
    $remarks: String!
    $itemList: [ItemChangeDNInput]
    $deliveryAdd: Float
    $createDate: Date
    $files: [Upload]
  ) {
    createDebt (
      thisModule: $thisModule
      id: $id
      input: {
        itemList: $itemList
        deliveryAdd: $deliveryAdd
        remarks: $remarks
        createDate: $createDate
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const SUPPLIER_CONFIRM = gql`
  mutation supplierConfirm (
    $purchaseOrderId: Int!
    $extRef: String!
  ) {
    supplierConfirm (
      input: {
        purchaseOrderId: $purchaseOrderId
        extRef: $extRef
      }
    ) {
      success
      message
    }
  }
`

export const CONFIRM_CREDIT = gql`
  mutation confirmCredit ($soId: Int!) {
    confirmCredit (soId: $soId){
      success
      message
    }
  }
`

export const GENERATE_KERRY_TRACKING_NUMBER = gql`
  mutation generateKerryTrackingNumber (
    $soId: Int!
    $deliveryChannel: DeliveryChannelEnum!
  ) {
    generateKerryTrackingNumber (
      soId: $soId
      deliveryChannel: $deliveryChannel
    ){
      success
      message
    }
  }
`

export const CREATE_CREDIT_NOTE = gql`
  mutation createCreditNote (
    $thisModule: DebtModule!
    $id: Int!
    $itemList: [ItemChangeCNInput]
    $discount: Float
    $remarks: String!
    $createDate: Date
    $files: [UploadFile]
    $deliveryDiscount: Float
  ) {
    createCreditNote (
      thisModule: $thisModule
      id: $id
      input: {
        itemList: $itemList
        discount: $discount
        remarks: $remarks
        createDate: $createDate
        files: $files
        deliveryDiscount: $deliveryDiscount
      }
    ) {
      success
      message
    }
  }
`

export const CASH_PAYMENT_RECORD = gql`
  mutation createPayment (
    $soId: Int!
    $type: ReceiptPaymentMethod!
    $date: Date!
    $subtotal: Float!
    $attachment: Upload
    $time: Time
    $remarks: String
    $chequeDate: Date
    $chequeNumber: String
    $creditCard: String
    $referenceNO: String
    $finnicBankId: Int
    $reference: String
    $fee: Float
  ) {
    createPayment (
      soId: $soId
      input: {
        type: $type
        date: $date
        subtotal: $subtotal
        attachment: $attachment
        time: $time
        remarks: $remarks
        chequeDate: $chequeDate
        chequeNumber: $chequeNumber
        creditCard: $creditCard
        referenceNO: $referenceNO
        finnicBankId: $finnicBankId
        reference: $reference
        fee: $fee
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_PICKING_TICKET = gql`
  mutation createPickingTicket (
    $lot: CreateLotInPTInput!
    $warehouseId: Int
    $remark: String
    $trackingNumber: String
    $files: [Upload]
    # $taxInvoiceIds: [Int]
    $box: [BoxInput]!
  ) {
    createPickingTicket (
      input: {
        lot: $lot
        warehouseId: $warehouseId
        remark: $remark
        trackingNumber: $trackingNumber
        files: $files
        # taxInvoiceIds: $taxInvoiceIds
        box: $box
      }
    ) {
      success
      message
    }
  }
`

export const RECORD_DELIVERY = gql`
  mutation recordDelivery (
    $pickingId: Int!
    $deliveryDetails: [DeliveryDetailInput]
  ) {
    recordDelivery (
      input: {
        pickingId: $pickingId
        deliveryDetails: $deliveryDetails
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_INVOICE = gql`
  mutation createInvoice (
    $lotId: Int!
    $createDate: Date!
    $paymentDueDate: Date!
    $remark: String
    $files: [Upload]
  ) {
    createInvoice (
      input: {
        lotId: $lotId
        createDate: $createDate
        paymentDueDate: $paymentDueDate
        remark: $remark
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_INVOICE = gql`
  mutation updateInvoice (
    $id: Int!
    $createDate: Date
    $paymentDueDate: Date
    $remark: String
    $files: [Upload]
  ) {
    updateInvoice (
      id: $id
      input: {
        createDate: $createDate
        paymentDueDate: $paymentDueDate
        remark: $remark
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_BILLING = gql`
  mutation createBillingNote (
    $list: [CreateBillingNoteList]
    $createDate: Date!
    $paymentDueDate: Date!
    $remark: String
    $files: [Upload]
  ) {
    createBillingNote (
      input: {
        list: $list
        createDate: $createDate
        paymentDueDate: $paymentDueDate
        remark: $remark
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_BILLING = gql`
  mutation updateBillingNote (
    $id: Int!
    $createDate: Date
    $paymentDueDate: Date
    $remark: String
    $files: [Upload]
  ) {
    updateBillingNote (
      id: $id
      input: {
        createDate: $createDate
        paymentDueDate: $paymentDueDate
        remark: $remark
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_RECEIPT = gql`
  mutation updateReceipt (
    $id: Int!
    $paymentMethod: ReceiptPaymentMethod
    $remark: String
    $reference: String
    $fee: Float
    $paidDate: Date
    $paidTime: Time
    $chequeDate: Date
    $chequeNumber: String
    $creditCard: String
    $referenceNO: String
    $finnicBankId: Int
    $files: [Upload]
  ) {
    updateReceipt (
      id: $id
      input: {
        paymentMethod: $paymentMethod
        remark: $remark
        reference: $reference
        fee: $fee
        paidDate: $paidDate
        paidTime: $paidTime
        chequeDate: $chequeDate
        chequeNumber: $chequeNumber
        creditCard: $creditCard
        referenceNO: $referenceNO
        finnicBankId: $finnicBankId
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_PAYMENT_NOTE = gql`
  mutation createPaymentNote (
    $goodsReceives: [GoodsReceiveInput]
    $createDate: Date!
    $paymentDueDate: Date!
    $remark: String
    $files: [Upload]
  ) {
    createPaymentNote (
      input: {
        goodsReceives: $goodsReceives
        createDate: $createDate
        paymentDueDate: $paymentDueDate
        remark: $remark
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_PAYMENT_NOTE = gql`
  mutation updatePaymentNote (
    $id: Int!
    $createDate: Date
    $paymentDueDate: Date
    $remark: String
    $files: [Upload]
    $reference: String
    $paidDate: Date
    $paidTime: Time
    $paymentType: PaymentNoteTypes
    $bankFee: Float
    $supplierBankId: Int
    $fourDigit: String
    $creditReference: String
    $checkNumber: String
    $checkDate: Date
  ) {
    updatePaymentNote (
      id: $id
      input: {
        createDate: $createDate
        paymentDueDate: $paymentDueDate
        remark: $remark
        files: $files
        reference: $reference
        paidDate: $paidDate
        paidTime: $paidTime
        paymentType: $paymentType
        bankFee: $bankFee
        supplierBankId: $supplierBankId
        fourDigit: $fourDigit
        creditReference: $creditReference
        checkNumber: $checkNumber
        checkDate: $checkDate
      }
    ) {
      success
      message
    }
  }
`

export const CREATE_RECEIPT = gql`
  mutation createReceipt (
    $paymentMethod: ReceiptPaymentMethod!
    $paymentType: PaymentTypes!
    $list: [CreateReceiptList]
    $lotList: [LotIdListInput]
    $remark: String
    $reference: String
    $fee: Float
    $paidDate: Date!
    $paidTime: Time
    $chequeDate: Date
    $chequeNumber: String
    $creditCard: String
    $referenceNO: String
    $finnicBankId: Int
    $customerId: Int!
    $files: [Upload]
  ) {
    createReceipt (
      input: {
        paymentMethod: $paymentMethod
        paymentType: $paymentType
        list: $list
        lotList: $lotList
        remark: $remark
        reference: $reference
        fee: $fee
        paidDate: $paidDate
        paidTime: $paidTime
        chequeDate: $chequeDate
        chequeNumber: $chequeNumber
        creditCard: $creditCard
        referenceNO: $referenceNO
        finnicBankId: $finnicBankId
        customerId: $customerId
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_PRICING_TYPE = gql`
  mutation updatePricingType (
    $pricingTypes: [PricingTypeInput]!
  ) {
    updatePricingType (
      input: {
        pricingTypes: $pricingTypes
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_FINNIC_BANK = gql`
  mutation updateFinnicBank (
    $finnicBanks: [FinnicBankInput]
  ) {
    updateFinnicBank (
      input: {
        finnicBanks: $finnicBanks
      }
    ) {
      success
      message
    }
  }
`

export const CONVERT_SKU = gql`
  mutation convertSku (
    $oldId: Int!
    $oldQuantity: Float!
    # $newId: Int!
    $newQuantity: Float!
    $warehouseId: Int!
  ) {
    convertSku (
      input: {
        oldId: $oldId
        oldQuantity: $oldQuantity
        # newId: $newId
        newQuantity: $newQuantity
        warehouseId: $warehouseId
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_SALE_ORDER = gql`
  mutation updateSalesOrder (
    $soId: Int!
    $sellerId: Int!
    $files: [Upload]
    $remarks: String
    $contactAddressId: Int
    $discount: Float
    $claimUserId: Int
    $lots: [UpdateLotSaleOrderInput]
  ) {
    updateSalesOrder (
      soId: $soId
      input: {
        remarks: $remarks
        sellerId: $sellerId
        discount: $discount
        files: $files
        claimUserId: $claimUserId
        contactAddressId: $contactAddressId
        lots: $lots
      }
    ) {
      success
      message
      data {
        id
        status
        soNumber
      }
    }
  }
`

export const PAIR_ITEMS = gql`
  mutation addItemPair (
    $thisItemId: Int!
    $pairItemId: Int!
  ) {
    addItemPair (
      input: {
        thisItemId: $thisItemId
        pairItemId: $pairItemId
      }
    ) {
      success
      message
    }
  }
`

export const GOOD_RECEIVE_CONF = gql`
  mutation confirmGoodsReceive (
    $id: Int
  ) {
    confirmGoodsReceive (
      id: $id
    ) {
      success
      message
    }
  }
`

export const APPROVE_SALE_ORDER = gql`
  mutation confirmCashSO (
    $soId: Int!
  ) {
    confirmCashSO (
      soId: $soId
    ) {
      success
      message
    }
  }
`

export const CREATE_PRODUCT_SET = gql`
  mutation CreateProductSet (
    $name: String!
    $sku: String!
    $reference: String!
    $availableStart: Date
    $availableEnd: Date
    $useDate: Boolean
    $remark: String
    $ProductSetPricingTypes: [ItemPricingTypesInput]
    $ProductSetItems: [InputProductSetItem]!
    $files: [Upload]
    $productUrl: String
  ) {
    CreateProductSet (
      input: {
        name: $name
        sku: $sku
        reference: $reference
        availableStart: $availableStart
        availableEnd: $availableEnd
        useDate: $useDate
        remark: $remark
        ProductSetPricingTypes: $ProductSetPricingTypes
        ProductSetItems: $ProductSetItems
        files: $files
        productUrl: $productUrl
      }
    ) {
      data {
        id
        name
        sku
      }
      success
      message
    }
  }
`

export const CREATE_TIER = gql`
  mutation createSupplierBrandPricingTypes (
    # $code: String!
    $name: String!
    $supplierBrandPricingTypes: [SupplierBrandPricingTypesInput]!
  ) {
    createSupplierBrandPricingTypes (
      input: {
        newPricingType: {
          # code: $code
          name: $name
        }
        supplierBrandPricingTypes: $supplierBrandPricingTypes
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_PRODUCT_SET = gql`
  mutation UpdateProductSet (
    $id: Int!
    $name: String
    $sku: String
    $reference: String
    $availableStart: Date
    $availableEnd: Date
    $useDate: Boolean
    $remark: String
    $ProductSetPricingTypes: [ItemPricingTypesInput]
    $ProductSetItems: [InputUpdateProductSetItem]
    $files: [Upload]
  ) {
    UpdateProductSet (
      input: {
        id: $id
        name: $name
        sku: $sku
        reference: $reference
        availableStart: $availableStart
        availableEnd: $availableEnd
        useDate: $useDate
        remark: $remark
        ProductSetPricingTypes: $ProductSetPricingTypes
        ProductSetItems: $ProductSetItems
        files: $files
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_INVENTORY_STRUCTURE = gql`
  mutation updateInventoryManagement (
    $suppliers: [SupplierManagement]
  ) {
    updateInventoryManagement (
      input: { suppliers: $suppliers }
    ) {
      success
      message
    }
  }
`

export const UPDATE_TIER = gql`
  mutation updateSupplierBrandPricingTypes (
    $supplierBrands: [SupplierBrandUpdateInput]!
  ) {
    updateSupplierBrandPricingTypes (
      input: {
        supplierBrands: $supplierBrands
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_BRANDS = gql`
  mutation updateBrandList (
    $brandList: [BrandInput]
  ) {
    updateBrandList (
      input: { brandList: $brandList }
    ) {
      success
      message
    }
  }
`

export const ACTIVE_GOOD_RECEIVE = gql`
  mutation activeGoodsReceive (
    $id: Int
  ) {
    activeGoodsReceive (
      id: $id
    ) {
      success
      message
    }
  }
`

export const UPDATE_PRODUCT_GROUP = gql`
  mutation updateProductGroupList (
    $productGroupList: [ProductGroupInput]
  ) {
    updateProductGroupList (
      input: { productGroupList: $productGroupList }
    ) {
      success
      message
    }
  }
`

export const UPDATE_CATEGORIES = gql`
  mutation updateProductCategoryList (
    $productCategoryList: [ProductCategoryInput]
  ) {
    updateProductCategoryList (
      input: { productCategoryList: $productCategoryList }
    ) {
      success
      message
    }
  }
`

export const CREATE_ROLE = gql`
  mutation createRole (
    $description: String!
  ) {
    createRole (
      input: {
        description: $description
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation updateRole (
    $id: Int!
    $description: String
  ) {
    updateRole (
      id: $id
      input: {
        description: $description
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_ROLE_PERMISSION = gql`
  mutation updateRolePermission (
    $roleId: Int!
    $permissionId: Int!
  ) {
    updateRolePermission (
      input: {
        roleId: $roleId
        permissionId: $permissionId
      }
    ) {
      success
      message
    }
  }
`

export const IMPORT_INVENTORY = gql`
  mutation importExcelInventory (
    $file: Upload!
    $isConfirm: Boolean
  ) {
    importExcelInventory (
      input: {
        file: $file
        isConfirm: $isConfirm
      }
    ) {
      success
      message
      data {
        variant {
          create
          update
        }
        pack {
          create
          update
        }
      }
    }
  }
`

export const CANCEL_PO = gql`
  mutation cancelPurchaseOrder (
    $id: Int!
  ) {
    cancelPurchaseOrder (
      id: $id
    ) {
      success
      message
    }
  }
`

export const GENERATE_QR = gql`
  mutation generateQR (
    $amount: Float!
    $salesOrderId: Int!
  ) {
    generateQR (
      input: {
        amount: $amount
        salesOrderId: $salesOrderId
      }
    ) {
      success
      message
      data {
        url
      }
    }
  }
`

export const VERIFY_SLIP = gql`
  mutation verifySlip (
    $salesOrderId: Int!
    $transactionId: String
    $bankCode: String!
    $attachment: Upload
  ) {
    verifySlip (
      input: {
        salesOrderId: $salesOrderId
        transactionId: $transactionId
        bankCode: $bankCode
        attachment: $attachment
      }
    ) {
      success
      message
      data {
        totalPayment
        totalAmount
        currentAmount
        depositAmount
      }
    }
  }
`

export const EDIT_PAYMENT = gql`
  mutation editPayment (
    $paymentHistoryId: Int!
    $salesOrderId: Int!
    $type: ReceiptPaymentMethod!
    $date: Date!
    $subtotal: Float!
    $attachment: Upload
    $time: Time
    $remarks: String
    $chequeDate: Date
    $chequeNumber: String
    $creditCard: String
    $referenceNO: String
    $finnicBankId: Int
    $reference: String
    $fee: Float
  ) {
    editPayment (
      input: {
        paymentHistoryId: $paymentHistoryId,
        salesOrderId: $salesOrderId,
        type: $type,
        date: $date,
        subtotal: $subtotal,
        attachment: $attachment,
        time: $time,
        remarks: $remarks,
        chequeDate: $chequeDate,
        chequeNumber: $chequeNumber,
        creditCard: $creditCard
        referenceNO: $referenceNO,
        finnicBankId: $finnicBankId,
        reference: $reference,
        fee: $fee,
      }
    ) {
      success
      message
    }
  }
`

export const ADD_SERIAL = gql`
  mutation addSerialNumber (
    $pickingTicketId: Int!
    $orderItems: [CreateOrderSerialInput]!
  ) {
    addSerialNumber (
      input: {
        pickingTicketId: $pickingTicketId
        orderItems: $orderItems
      }
    ) {
      success
      message
    }
  }
`

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment (
    $soId: Int!
  ) {
    confirmPayment (
      soId: $soId
    ) {
      success
      message
    }
  }
`

export const UPDATE_IV = gql`
  mutation updateIVNumber (
    $id: Int!
    $number: String!
  ) {
    updateIVNumber (
      id: $id
      input: {
        number: $number
      }
    ) {
      success
      message
    }
  }
`

export const REJECT_INVOICE = gql`
  mutation rejectTaxInvoice (
    $id: Int!
    $reason: String!
  ) {
    rejectTaxInvoice (
      id: $id
      input: {
        reason: $reason
      }
    ) {
      success
      message
    }
  }
`
export const TAX_UPDATE_TRACKING_NUMBER = gql`
  mutation updateTrackingNumber (
    $id: Int!
    $providerName: String
    $trackingNumber: String
  ) {
    updateTrackingNumber (
      id: $id
      input: {
        providerName: $providerName
        trackingNumber: $trackingNumber
      }
    ) {
      success
      message
    }
  }
`

export const CONFIRM_RECEIVE = gql`
  mutation confirmReceive (
    $taxInvoiceIds: [Int]
  ) {
    confirmReceive (
      input: {
        taxInvoiceIds: $taxInvoiceIds
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_EDIT_IV_NUMBER = gql`
  mutation updateNewIVNumber (
    $id: Int!
    $number: String!
  ) {
    updateNewIVNumber (
      id: $id
      input: {
        number: $number
      }
    ) {
      success
      message
    }
  }
`

export const CONFIRM_IV_NUMBER = gql`
  mutation confirmIVNumber (
    $id: Int!
    $ivNumber: String
  ) {
    confirmIVNumber (
      id: $id
      input: {
        ivNumber: $ivNumber
      }
    ) {
      success
      message
    }
  }
`

export const CANCEL_SO = gql`
  mutation cancelSalesOrder (
    $soId: Int!
  ) {
    cancelSalesOrder (
      soId: $soId
    ) {
      success
      message
    }
  }
`

export const CREATE_CN_TO_DR = gql`
  mutation createCreditNoteToDepositReceipt(
    $drId: Int!
    $input: CreateCNToDRInput!
  ) {
    createCreditNoteToDepositReceipt (
      drId: $drId
      input: $input
    ) {
      success
      message
    }
  }
`

export const MARK_AS_COMPLETE = gql`
  mutation markAsCompleted (
    $orderItemId: Int!
  ) {
    markAsCompleted (
      input: {
        orderItemId: $orderItemId
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_COMMISSION = gql`
  mutation updateCommission(
    $commissions: [CommissionsInput]!
  ) {
    updateCommission(input: {
      commissions: $commissions
    }) {
      success
      message
    }
  }
`

export const UPDATE_ROLE_COMMISSION = gql`
  mutation updateRoleCommission(
    $commissions: [RoleCommissionsInput]!
  ) {
    updateRoleCommission(input: {
      commissions: $commissions
    }) {
      success
      message
    }
  }
`

export const IMPORT_KERRY_TRACKING_NUMBERS = gql`
  mutation importKerryTrackingNumbers(
    $input: [ImportKerryTrackingNumbersInput]!
  ) {
    importKerryTrackingNumbers(input: $input) {
      success
      message
    }
  }
`

export const IMPORT_KERRY_COD = gql`
  mutation importKerryCOD(
    $input: [ImportKerryCODInput]!
  ) {
    importKerryCOD(input: $input) {
      success
      message
    }
  }
`

export const IMPORT_EMS_COD = gql`
  mutation importEMSCOD(
    $input: [ImportEMSCODInput]!
  ) {
    importEMSCOD(input: $input) {
      success
      message
    }
  }
`

export const MANAGE_BOXES = gql`
  mutation manageBoxes(
    $boxes: [ManageBoxInput]!
  ) {
    manageBoxes(input: {
      boxes: $boxes
    }) {
      success
      message
    }
  }
`

export const UPDATE_PRINTED_ADDRESS_LABELS = gql`
  mutation updatePrintedAddressLabels(
    $soIds: [Int]!
  ) {
    updatePrintedAddressLabels(soIds: $soIds) {
      success
      message
    }
  }
`

export const CREATE_TAG = gql`
  mutation createTag(
    $name: String
  ) {
    createTag(input: {
      name: $name
    }) {
      success
      message
    }
  }
`

export const REMOVE_TAG = gql`
  mutation removeTag(
    $id: Int!
  ) {
    removeTag(input: {
      id: $id
    }) {
      success
      message
    }
  }
`

export const CANCEL_UPSELL = gql`
  mutation cancelUpsell(
    $id: Int!
  ) {
    cancelUpsell(id: $id) {
      success
      message
    }
  }
`

export const CREATE_RETURN_SALES_ORDER = gql`
  mutation createReturnSalesOrder($receiptId: Int!, $input: ReturnSalesOrderInput!) {
    createReturnSalesOrder(receiptId: $receiptId, input: $input) {
      success
      message
    }
  }
`

export const UPDATE_USER_PROVIDER = gql`
  mutation UpdateUserProvider($input: UpdateUserProviderInput!) {
    updateUserProvider(input: $input) {
      success
      message
    }
  }
`

export const UPDATE_TEAM_LIST = gql`
  mutation updateTeamList($input: UpdateTeamListInput!) {
    updateTeamList(input: $input) {
      success
      message
    }
  }
`

export const ASSIGN_UPSELL = gql`
  mutation assignUpsell($input: AssignUpsellInput!) {
    assignUpsell(input: $input) {
      success
      message
    }
  }
`

export const EDIT_ORDER_BONUS = gql`
  mutation editOrderBonus($input: EditOrderBonusInput!) {
    editOrderBonus(input: $input) {
      success
      message
    }
  }
`

export const SET_DEFAULT_DELIVERY_CHANNEL = gql`
  mutation setDefaultDeliveryChannel($input: SetDefaultDeliveryChannelInput!) {
    setDefaultDeliveryChannel(input: $input) {
      success
      message
    }
  }
`

export const CREATE_CHAT_PROVIDER = gql`
  mutation createChatProvider($input: CreateChatProviderInput!) {
    createChatProvider(input: $input) {
      data {
        id
        name
        code
      }
      message
      success
    }
  }
`

export const MATCH_ORDER = gql`
  mutation matchOrder($input: MatchOrderInput!) {
    matchOrder(input: $input) {
      message
      success
    }
  }
`
