import { useLazyQuery } from '@apollo/client'
import {
  Button,
  Div,
  Icon,
  P, SmallText,
} from '@konsys-ui-custom'
import { DatePicker, message } from 'antd'
import axios from 'axios'
import FileDownload from 'js-file-download'
import _, { compact, filter, includes, isEmpty, isNil } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import { CHAT_PROVIDERS, TEAM_LIST, USERS } from '../../api/query'
import {
  RangePicker, Select, Tab,
} from '../../components'
import URL from '../../constants/environment'
import { theme } from '../../styles/_variables'
import { getAccessToken } from '../../utils/local-data-service'
import { Card } from './styled'

const DownloadCard = (props) => {
  const [variables, setVariables] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [getUsers, { data, loading, variables: usersVariables }] = useLazyQuery(USERS)
  const [getTeams, { data: teamsData, loading: teamsLoading }] = useLazyQuery(TEAM_LIST)
  const [getChatProviders, { loading: chatProvidersLoading, data: chatProvidersData, variables: chatProvidersVariables }] = useLazyQuery(CHAT_PROVIDERS)

  useEffect(() => {
    getUsers({ variables: { offset: 0, limit: 10 } })
    getTeams()
    getChatProviders({ variables: { offset: 0, limit: 10 } })
  }, [])

  const getVar = () => {
    const {
      sellerId, chatProviderId, date, teamId, role,
    } = variables
    switch (props?.id) {
      case 1:
        return { startDate: moment(date[0]).format('YYYY-MM-DD'), endDate: moment(date[1]).format('YYYY-MM-DD'), ...role ? { role } : null }
      case 2:
      case 3:
      case 4:
      case 7:
        return { startDate: moment(date[0]).format('YYYY-MM-DD'), endDate: moment(date[1]).format('YYYY-MM-DD'), ...sellerId ? { sellerId } : null, ...chatProviderId ? { chatProviderId } : null, ...teamId ? { teamId } : null }
      case 5:
        return { date: moment(date).format('YYYY-MM-DD'), ...chatProviderId ? { chatProviderId } : null, ...teamId ? { teamId } : null }
      case 6:
        return { startDate: moment(date[0]).format('YYYY-MM-DD'), endDate: moment(date[1]).format('YYYY-MM-DD'), ...teamId ? { teamId } : null }
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 14:
      case 15:
        return { date: moment(date).format('YYYY-MM-DD'), ...chatProviderId ? { chatProviderId } : null }
      case 13:
      case 16:
        return { startDate: moment(date[0]).format('YYYY-MM-DD'), endDate: moment(date[1]).format('YYYY-MM-DD') }
      default: return {}
    }
  }

  const download = (id, url, name, value = {}) => {
    const vars = _.keys(value).reduce((acc, cur) => (`${acc}&${cur}=${value[cur]}`), '').slice(1)
    if (!isLoading) {
      setIsLoading(true)
      axios({
        method: 'GET',
        responseType: 'blob',
        timeout: 180000, // 3 mins
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        url: `${URL.API_HOST}${url}?${vars}`,
      }).then((resp) => {
        if (resp.status !== 200) {
          message.error('กรอกข้อมูลนำเข้าไม่ครบ')
        } else {
          FileDownload(
            resp.data,
            `${
              name
            }${
              value.startDate
                ? ` ณ วันที่ ${value.startDate} ถึงวันที่ ${value.endDate}`
                : value.date
                  ? id === 9
                    ? ` ณ เดือน ${dayjs(value.date).format('MM')} - ปี ${dayjs(value.date).format('YYYY')}`
                    : ` ณ วันที่ ${value.date}`
                  : ''
            }${
              value.type
                ? ` ประเภท ${value.type === 'CASH' ? 'โอนเงิน' : 'ชำระเงินปลายทาง'}`
                : ''
            }${
              value.brandId
                ? ` ยี่ห้อ ${value.brandName}`
                : ''
            }${
              value.customerId
                ? ` ลูกค้า ${value.customerName}`
                : ''
            }.xlsx`,
          )
        }
        setIsLoading(false)
      })
    }
  }
  console.info(filter(data?.userList?.data?.list, (o) => dayjs(variables.date?.[1]).diff(variables.date?.[0], 'day') > 0 || o.id !== 238), dayjs(variables.date?.[1]).diff(variables.date?.[0], 'day') > 0)

  return (
    <Card iconBgColor={props?.color}>
      <Div>
        <span>
          <Icon icon={props?.icon} color={theme.color.offWhite} fontSize={theme.fonts.size.subTitle} />
        </span>
        <P bold style={{ fontSize: '18px' }}>{props?.name || '-'}</P>
        <Div margin='16px 0 0' padding='16px 0 0' moreStyle={{ borderTop: `1px solid ${theme.color.primaryColor10}` }}>
          {
            includes([1, 2, 3, 4, 6, 7, 13, 16], props?.id)
            && <Div>
              <SmallText display='block' margin='0 0 4px'>เลือกช่วงวันที่</SmallText>
              <RangePicker
                style={{ width: '100%' }}
                onChange={(e) => setVariables({ ...variables, date: e })}
                onCalendarChange={(val) => {
                  if (includes([2, 3, 4, 13], props?.id)) {
                    setVariables({ ...variables, date: val })
                  }
                }}
                disabledDate={(current) => {
                  if (includes([2, 3, 4], props?.id)) {
                    if (variables.sellerId === '0' || variables.sellerId === 238) {
                      const tooLate = variables.date?.[0] && current.diff(variables.date?.[0], 'Day') > 0
                      const tooEarly = variables.date?.[1] && variables.date?.[1].diff(current, 'Day') > 0
                      return !!tooEarly || !!tooLate
                    }
                    const tooLate = variables.date?.[0] && current.diff(variables.date?.[0], 'Day') > 31
                    const tooEarly = variables.date?.[1] && variables.date?.[1].diff(current, 'Day') > 31
                    return !!tooEarly || !!tooLate
                  }
                  if (!variables.date || props?.id !== 13) {
                    return false
                  }
                  const tooLate = variables.date?.[0] && current.diff(variables.date?.[0], 'M') > 6
                  const tooEarly = variables.date?.[1] && variables.date?.[1].diff(current, 'M') > 6
                  return !!tooEarly || !!tooLate
                }}
              />
            </Div>
          }
          {
            includes([5, 8, 10, 11, 12, 14, 15], props?.id)
            && <Div>
              <SmallText display='block' margin='0 0 4px'>เลือกวันที่</SmallText>
              <DatePicker
                style={{ width: '100%' }}
                onChange={(e) => setVariables({ ...variables, date: e })}
                disabledDate={(current) => props.id === 8 && current && current.endOf('D') >= moment().endOf('D')}
              />
            </Div>
          }
          {
            includes([2, 3, 4, 5, 7, 12], props?.id)
              && <Div margin='8px 0 0'>
                <SmallText display='block' margin='0 0 4px'>เลือกช่องทาง</SmallText>
                <Select
                  placeholder='เลือกประเภท'
                  showSearch
                  onChange={(e, other) => setVariables({ ...variables, chatProviderId: other.id })}
                  loading={chatProvidersLoading}
                  options={
                    chatProvidersData
                      ? (isEmpty(chatProvidersVariables.name) || isNil(chatProvidersVariables.name)) && props.id !== 12
                        ? [{ id: undefined, value: undefined, text: 'ทั้งหมด' }, ...chatProvidersData?.chatProviders?.data?.map((v) => ({ id: v.id, value: v.name, text: v.name }))]
                        : chatProvidersData?.chatProviders?.data?.map((v) => ({ id: v.id, value: v.name, text: v.name }))
                      : []
                  }
                  style={{ width: '100%' }}
                />
              </Div>
          }
          {
            includes([5, 6, 7], props?.id)
              && <Div margin='8px 0 0'>
                <SmallText display='block' margin='0 0 4px'>เลือกทีม</SmallText>
                <Select
                  placeholder='เลือกทีม'
                  showSearch
                  onChange={(e, other) => {
                    setVariables({ ...variables, teamId: other.id })
                    getUsers({ variables: { offset: 0, limit: 10, teamId: other.id } })
                  }}
                  loading={teamsLoading}
                  options={
                    teamsData
                      ? [{ value: undefined, text: 'ทั้งหมด' }, ...teamsData?.teamList?.data?.list?.map((v) => ({ id: v.id, value: v.teamName, text: v.teamName }))]
                      : []
                  }
                  style={{ width: '100%' }}
                />
              </Div>
          }
          {
            includes([2, 3, 4, 7], props?.id)
              && <Div margin='8px 0 0'>
                <SmallText display='block' margin='0 0 4px'>เลือกแอดมิน</SmallText>
                <Select
                  placeholder='เลือกแอดมิน'
                  showSearch
                  notFoundContent={null}
                  showArrow={false}
                  filterOption={false}
                  onSearch={(e) => getUsers({ variables: { name: e, offset: 0, limit: 10, teamId: variables.teamId } })}
                  onChange={(e) => setVariables({ ...variables, sellerId: e })}
                  loading={loading}
                  options={
                    data
                      ? isEmpty(usersVariables.name) || isNil(usersVariables.name)
                        ? compact(
                          [
                            includes([2, 3, 4], props?.id) && dayjs(variables.date?.[1]).diff(variables.date?.[0], 'day') > 0
                              ? null
                              : { value: undefined, text: 'ทั้งหมด' },
                            ...filter(data?.userList?.data?.list, (o) => dayjs(variables.date?.[1]).diff(variables.date?.[0], 'day') === 0 || o.id !== 238)?.map((v) => ({ value: v.id, text: `${v.firstName} ${v.lastName}` })),
                          ],
                        )
                        : includes([2, 3, 4], props?.id)
                          ? filter(data?.userList?.data?.list, (o) => dayjs(variables.date?.[1]).diff(variables.date?.[0], 'day') === 0 || o.id !== 238)?.map((v) => ({ value: v.id, text: `${v.firstName} ${v.lastName}` }))
                          : data?.userList?.data?.list.map((v) => ({ value: v.id, text: `${v.firstName} ${v.lastName}` }))
                      : []
                  }
                  style={{ width: '100%' }}
                />
              </Div>
          }
          {
            includes([1], props?.id)
              && <Div margin='8px 0 0'>
                <SmallText display='block' margin='0 0 4px'>เลือก Role</SmallText>
                <Select
                  placeholder='เลือก Role'
                  notFoundContent={null}
                  showArrow={false}
                  filterOption={false}
                  onChange={(e) => setVariables({ ...variables, role: e })}
                  options={
                    [
                      { value: 'all', text: 'ทั้งหมด' },
                      { value: 'admin', text: 'Admin' },
                      { value: 'upsell', text: 'Upsell' },
                      { value: 'crm', text: 'CRM' },
                      { value: 'others', text: 'อื่นๆ' },
                    ]
                  }
                  style={{ width: '100%' }}
                />
              </Div>
          }
          {
            includes([9], props?.id)
            && <Div>
              <SmallText display='block' margin='0 0 4px'>เลือกช่วงเวลา</SmallText>
              <DatePicker
                style={{ width: '100%' }}
                onChange={(e) => setVariables({ ...variables, date: e })}
                picker='month'
                disabledDate={(current) => current && current.endOf('M') >= moment().endOf('M')}
              />
            </Div>
          }
        </Div>
      </Div>
      <Div margin='16px 0 0' padding='16px 0 0' textAlign='right' moreStyle={{ borderTop: `1px solid ${theme.color.primaryColor10}` }}>
        <Button
          small
          inverse
          loading={isLoading}
          disabled={
            isLoading
            || (
              includes([2, 3, 4], props?.id)
              && (
                isNil(variables.date?.[0])
                || isNil(variables.date?.[1])
                || isNil(variables.sellerId)
              )
            )
          }
          icon='fal fa-arrow-to-bottom' text='ดาวน์โหลด' color={theme.color.primaryColor} onClick={() => {
            download(props?.id, props?.url, props?.name, getVar())
          }} />
      </Div>
    </Card>
  )
}

const Reports = () => {
  const reportsList = [
    {
      color: theme.color.blue, id: 1, name: 'ต้นทุน-กำไร', url: '/report/revenue', icon: 'fas fa-chart-line',
    },
    {
      color: '#eef900', id: 2, name: 'ADMIN Report', url: '/report/admin', icon: 'fas fa-user',
    },
    {
      color: '#d0d0d0', id: 3, name: 'Upsell Report', url: '/report/upsell', icon: 'fas fa-user-plus',
    },
    {
      color: '#ff7875', id: 4, name: 'CRM Report', url: '/report/crm', icon: 'fas fa-desktop',
    },
    {
      color: '#4649ff', id: 5, name: 'Order Summary Report', url: '/report/order', icon: 'fas fa-box',
    },
    {
      color: '#562B08', id: 6, name: 'Idle Admin Report', url: '/report/idle-admin', icon: 'fas fa-sign-out',
    },
    {
      color: '#3b5998', id: 7, name: 'Admin Lead Report', url: '/report/admin-lead', icon: 'fab fa-facebook-messenger',
    },
    {
      color: '#25316D', id: 8, name: 'Daily Inventory Report', url: '/report/daily-inventory', icon: 'fas fa-calendar-day',
    },
    {
      color: '#256D85', id: 9, name: 'Monthly Inventory Report', url: '/report/monthly-inventory', icon: 'fas fa-calendar-days',
    },
    {
      color: '#3F0071', id: 10, name: 'COD Report', url: '/report/cod', icon: 'fas fa-money-bill-wave',
    },
    {
      color: '#9A1663', id: 11, name: 'Order Claim Report', url: '/report/order-claim', icon: 'fas fa-circle-exclamation',
    },
    {
      color: '#5C2E7E', id: 12, name: 'Picking Ticket Report', url: '/report/picking-ticket', icon: 'fas fa-cart-flatbed-boxes',
    },
    {
      color: '#3F4E4F', id: 13, name: 'รายงานลูกค้าที่สั่งซื้อบ่อย', url: '/report/top-spending-customers', icon: 'fas fa-users-medical',
    },
    {
      color: '#84142D', id: 14, name: 'รายงานยอดขายเพจ', url: '/report/sales-pages', icon: 'fas fa-messages-dollar',
    },
    {
      color: '#272121', id: 15, name: 'รายงานยอดส่งสินค้า', url: '/report/delivery', icon: 'fas fa-truck-container',
    },
    {
      color: '#325938', id: 16, name: 'รายงานตีกลับ', url: '/report/return-order', icon: 'fas fa-undo',
    },
  ]
  return (
    <Div display='flex' margin='-8px -8px 0' moreStyle={{ flexWrap: 'wrap' }}>
      {
        reportsList?.map((report) => (
          <DownloadCard
            key={report?.id}
            {...report}
          />
        ))
      }
    </Div>
  )
}

export default () => (
  <Div>
    <Helmet>
      <meta name="viewport" content="width=1280" />
    </Helmet>
    <Tab
      fullHeight
      title={[
        {
          text: 'รายงาน',
          icon: 'fad fa-file-spreadsheet',
        },
      ]}
    >
      <Reports />
    </Tab>
  </Div>
)
